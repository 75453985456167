import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { UserProfile } from '~/features/User';
import { SceneComponent } from '~/navigation/Navigation.types';
import { BreadCrumbs } from '../layout/Content';

import { Scene } from '../layout/Scene';

export const UserProfileScene: SceneComponent<{}> = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const returnTo = params.get('returnTo');

  const onUpdateProfile = useCallback(() => {
    if (returnTo) navigate(returnTo);
  }, [navigate, returnTo]);

  return (
    <Scene width="large">
      <BreadCrumbs crumbs={[{ label: 'Profile', active: true }]} />
      <UserProfile onComplete={onUpdateProfile} />
    </Scene>
  );
};
