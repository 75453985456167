import { BoxProps, Layer } from 'grommet';
import { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { logout } from '~/module/firebase';
import { Box, Button, Text } from '~/components/common';
import { absoluteUrls } from '~/navigation/Navigation.paths';
import { UserHandle } from '~/components/UserHandle';

const RoundLetter = ({
  letter,
  size,
  ...boxProps
}: { letter: string; size: number } & BoxProps) => {
  const letterSize = size > 50 ? 'xxlarge' : 'xlarge';
  return (
    <Box
      align="center"
      justify="center"
      style={{ borderRadius: '50%', width: size, height: size }}
      border={{ color: 'brand', size: 'small' }}
      focusIndicator={false}
      {...boxProps}
    >
      <Text size={letterSize}>{letter}</Text>
    </Box>
  );
};

const Divider = () => {
  return (
    <Box>
      <Box
        style={{ height: 1, width: '95%' }}
        background="neutral-1"
        margin={{ vertical: 'medium' }}
      />
    </Box>
  );
};

export const HeaderProfile: FC<{
  displayName: string;
  userName: string;
  email?: string;
  onClick: () => void;
}> = ({ displayName, userName, email, onClick }) => {
  const navigate = useNavigate();
  const firstLetter = (displayName || userName).substring(0, 1);
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const onNavToUserProfile = useCallback(() => {
    setIsMenuVisible(false);
    navigate(absoluteUrls.userProfile);
  }, [navigate]);

  const onNavToPower = useCallback(() => {
    setIsMenuVisible(false);
    navigate(absoluteUrls.userPower);
  }, [navigate]);

  const onNavToHistory = useCallback(() => {
    setIsMenuVisible(false);
    navigate(absoluteUrls.userRaceHistory);
  }, [navigate]);

  const onNavToStravaSettings = useCallback(() => {
    setIsMenuVisible(false);
    navigate(absoluteUrls.userStravaSettings);
  }, [navigate]);

  return (
    <>
      <RoundLetter
        letter={firstLetter}
        size={52}
        hoverIndicator={{
          background: 'brand',
        }}
        // onClick={onClick}
        onClick={() => setIsMenuVisible(true)}
      />
      {isMenuVisible && (
        <Layer
          position="top-right"
          onClickOutside={() => setIsMenuVisible(false)}
          style={{ marginTop: 86 }}
        >
          <Box
            width="medium"
            background="background-constrast"
            border={{ color: 'border', size: 'xsmall' }}
            round={{ size: 'small', corner: 'bottom-left' }}
            style={{ borderTop: 'none', borderRight: 'none' }}
            pad="medium"
          >
            <Box>
              <Box direction="row" align="center">
                <RoundLetter letter={firstLetter} size={40} margin={{ right: 'small' }} />
                <Box>
                  <UserHandle displayName={displayName} userName={userName} />
                </Box>
              </Box>
            </Box>

            <Divider />

            <Box
              onClick={onNavToUserProfile}
              pad={{ vertical: 'xsmall', horizontal: 'small' }}
              margin={{ vertical: 'xxsmall' }}
              hoverIndicator={{ background: 'neutral-1' }}
            >
              <Text size="small">User Profile</Text>
            </Box>

            <Box
              onClick={onNavToPower}
              pad={{ vertical: 'xsmall', horizontal: 'small' }}
              margin={{ vertical: 'xxsmall' }}
              hoverIndicator={{ background: 'neutral-1' }}
            >
              <Text size="small">Power Records</Text>
            </Box>

            <Box
              onClick={onNavToHistory}
              pad={{ vertical: 'xsmall', horizontal: 'small' }}
              margin={{ vertical: 'xxsmall' }}
              hoverIndicator={{ background: 'neutral-1' }}
            >
              <Text size="small">Race History</Text>
            </Box>

            <Divider />

            <Box>
              <Box
                onClick={onNavToStravaSettings}
                pad={{ vertical: 'xsmall', horizontal: 'small' }}
                margin={{ vertical: 'xxsmall' }}
                hoverIndicator={{ background: 'neutral-1' }}
              >
                <Text size="small">Strava Settings</Text>
              </Box>
            </Box>

            <Divider />

            {!!email && (
              <Box>
                <Box margin={{ bottom: 'small' }} align="center">
                  <Text size="xsmall">Logged in as {email}</Text>
                </Box>
                <Button secondary label="Logout" onClick={logout} />
              </Box>
            )}
          </Box>
        </Layer>
      )}
    </>
  );
};
