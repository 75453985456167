import { FC } from 'react';
import { Box } from '~/components/common';
import {
  ParticipantVideo,
  ParticipantVideoProps,
} from '~/features/AudioVideo/components/ParticipantVideo';

export const HudParticipantVideo: FC<
  { assignedColour?: string; hasAv: boolean } & ParticipantVideoProps
> = ({ assignedColour, hasAv, ...avProps }) => {
  return (
    <Box
      width="150px"
      height="150px"
      pad="small"
      round
      background={{ color: assignedColour ? `#${assignedColour}` : 'accent-1' }}
      justify="center"
      align="center"
    >
      {hasAv && <ParticipantVideo {...avProps} round width="100%" height="100%" />}
    </Box>
  );
};
