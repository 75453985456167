import React, { FC } from 'react';

import { AnchorLink, Box, Text } from '~/components/common';
import { formatDate } from '~/module/dates';
import { getOrdinal, msToTime } from '~/module/utils';
import { getPathToRaceHistoryItem } from '~/navigation/Navigation.paths';
import { useRaceHistory } from '~/store/hooks';
import { RaceHistoryItem } from '~/store/slices';

type UserRaceHistorySceneProps = {
  onNavToUserRaceHistoryItem: (raceId: string) => void;
};

type UserRaceHistoryProps = UserRaceHistorySceneProps & {
  raceHistory: RaceHistoryItem[];
};

export const UserRaceHistory: React.FC<UserRaceHistoryProps> = ({
  raceHistory,
  onNavToUserRaceHistoryItem,
}) => {
  return (
    <Box gap="medium">
      {!raceHistory.length && (
        <Box align="center" margin={{ vertical: 'medium' }}>
          <Box background="contrast" round>
            <Text size="large" margin={{ bottom: 'small' }} textAlign="center">
              No race data to display
            </Text>
            <Text textAlign="center">Build up some data by racing on Critz!</Text>
          </Box>
        </Box>
      )}
      <Box>
        {raceHistory.map((race) => {
          const time = msToTime(race.duration + race.riderSummary.timeDelta);
          // const hasTcxFile = !!race.tcx?.tcxFileLocation;
          return (
            <Box key={race.id} border={{ side: 'bottom' }} pad={{ vertical: 'small' }}>
              <AnchorLink to={getPathToRaceHistoryItem({ raceId: race.id })}>
                <Text size="large" weight="bold" color="brand">
                  {race.name}
                </Text>
              </AnchorLink>
              <Box direction="row" justify="between" align="center">
                <Box>
                  <Text>{formatDate(race.raceStartTime, 'h:mmaaa - EEEE do MMMM yyyy')}</Text>
                  <Text size="small">
                    {time.minutes}min {time.seconds}s{' '}
                    {!race.riderSummary.raceComplete && (
                      <Text size="small">
                        ({race.riderSummary.distanceDelta.toFixed(1)}m from finish)
                      </Text>
                    )}
                  </Text>
                </Box>
                <Box direction="row" align="center" gap="small">
                  <Box align="center">
                    <Text size="xlarge">
                      {race.riderSummary.rank}
                      {getOrdinal(race.riderSummary.rank)}
                    </Text>
                    <Text>{race.numParticipants} riders</Text>
                  </Box>
                  {/* <Box
                    elevation="xsmall"
                    round
                    width="xxsmall"
                    height="xxsmall"
                    justify="center"
                    align="center"
                    hoverIndicator={hasTcxFile ? { background: 'accent-1' } : undefined}
                    focusIndicator={false}
                    onClick={hasTcxFile ? () => {} : undefined}
                  >
                    <Icon
                      iconName="download"
                      color={hasTcxFile ? 'icon' : 'placeholder'}
                      size="medium"
                    />
                    <Text
                      size="xsmall"
                      textAlign="center"
                      color={hasTcxFile ? 'text-primary' : 'text-xweak'}
                    >
                      TCX
                    </Text>
                  </Box> */}
                </Box>
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

function useUserRaceHistory(props: UserRaceHistorySceneProps): UserRaceHistoryProps {
  const { userRaceHistory } = useRaceHistory();
  return {
    ...props,
    raceHistory: userRaceHistory,
  };
}

const UserRaceHistoryConnected: FC<UserRaceHistorySceneProps> = (sceneProps) => {
  const props = useUserRaceHistory(sceneProps);
  return <UserRaceHistory {...props} />;
};

export default UserRaceHistoryConnected;
