import { w3cwebsocket as WebSocketClient, IMessageEvent, ICloseEvent } from 'websocket';
import { reportError } from '~/module/logging';

type MessageListener = (data: string) => void;
type StatusListener = (isOpen: boolean) => void;
type DeRegisterFn = () => void;

export class Socket {
  private connectionUrl: string = '';
  private connectionProtocols?: string[];
  private wsClient: WebSocketClient;

  private statusListeners: StatusListener[] = [];
  private messageListeners: MessageListener[] = [];
  private earlyMessages: string[] = [];
  private isOpen: boolean = false;
  private isConnecting: boolean = false;
  private isReconnecting: boolean = false;

  private reconnectionIntervalId: Maybe<NodeJS.Timeout> = null;

  constructor(connectionUrl: string, protocols?: string[]) {
    this.connectionUrl = connectionUrl;
    this.connectionProtocols = protocols;
    this.wsClient = this.init();
  }

  private init(): WebSocketClient {
    this.isConnecting = true;
    const client = new WebSocketClient(
      this.connectionUrl,
      this.connectionProtocols,
      window?.location.origin,
    );

    client.onopen = () => {
      this.isOpen = true;
      this.isConnecting = false;
      this.isReconnecting = false;
      this.notifyStatusListeners();
    };

    client.onmessage = (event: IMessageEvent) => {
      if (this.messageListeners.length) {
        this.messageListeners.forEach((fn) => {
          if (typeof event.data === 'string') {
            fn(event.data);
          }
        });
      } else {
        // until we start getting message listeners, keep a track of any messages received,
        // so they can be processed by the message listeners who wish to know about them
        if (typeof event.data === 'string') {
          this.earlyMessages.push(event.data);
        }
      }
    };

    // https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent
    client.onclose = (event: ICloseEvent) => {
      this.isOpen = false;
      this.isConnecting = false;
      this.notifyStatusListeners();
    };

    client.onerror = (error: any) => {
      reportError('Websocket Error', error);
    };

    return client;
  }

  public teardown() {
    this.wsClient.close(4000, 'client-initiated teardown');
  }

  public reconnect() {
    this.isReconnecting = true;
    this.reconnectionIntervalId = setInterval(() => {
      if (this.isReconnecting) {
        if (!this.isConnecting && !this.isOpen && navigator.onLine) {
          this.wsClient = this.init();
        }
      } else {
        if (this.reconnectionIntervalId) {
          clearInterval(this.reconnectionIntervalId);
          this.reconnectionIntervalId = null;
        }
      }
    }, 500);
  }

  public send(msg = '') {
    if (this.isOpen) {
      this.wsClient.send(msg);
    } else {
      // Todo - add more control here to prevent an unending loop
      setTimeout(() => {
        this.send(msg);
      }, 500);
    }
  }

  public sendJSON(msg: Record<string, any>) {
    this.send(JSON.stringify(msg));
  }

  private notifyStatusListeners() {
    this.statusListeners.forEach((listener) => this.notifyStatusListener(listener));
  }

  private notifyStatusListener(listener: StatusListener) {
    listener(this.isOpen);
  }

  public registerStatusListener(listener: StatusListener): DeRegisterFn {
    this.statusListeners.push(listener);
    this.notifyStatusListener(listener);
    return () => this.deregisterStatusListener(listener);
  }

  public deregisterStatusListener(listener: StatusListener) {
    this.statusListeners = this.statusListeners.filter((l) => l !== listener);
  }

  public registerMessageListener(
    listener: MessageListener,
    processEarlyMessages: boolean = false,
  ): DeRegisterFn {
    if (processEarlyMessages) {
      this.earlyMessages.forEach((m) => {
        listener(m);
      });
    }
    this.messageListeners.push(listener);
    return () => this.deregisterMessageListener(listener);
  }

  public deregisterMessageListener(listener: MessageListener) {
    this.messageListeners = this.messageListeners.filter((l) => l !== listener);
  }
}
