import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { SceneComponent } from '~/navigation/Navigation.types';
import { Box } from '~/components/common';
import { TournamentList } from '~/features/Tournaments/components/TournamentList';

import { getPathToTournament } from '../Navigation.paths';

export const TournamentsScene: SceneComponent<{}> = () => {
  const navigate = useNavigate();

  const onNavToTournament = useCallback(
    (tournamentId: string) => {
      navigate(getPathToTournament({ tournamentId }));
    },
    [navigate],
  );

  return (
    <Box>
      <TournamentList onNavToTournament={onNavToTournament} />
    </Box>
  );
};
