import React, { FC, PropsWithChildren } from 'react';
import { Grommet } from 'grommet';

import { useDisplayMode } from '~/store/hooks/useDisplayMode';

import { theme } from './theme';
import { UIDisplayMode } from './theme.types';

export const ThemeProviderBase: FC<
  PropsWithChildren<{ themeMode: UIDisplayMode; full?: boolean }>
> = ({ children, themeMode, full = true }) => {
  return (
    <Grommet theme={theme} themeMode={themeMode} full={full}>
      {children}
    </Grommet>
  );
};

export const ThemeProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { displayMode } = useDisplayMode();
  return (
    <ThemeProviderBase themeMode={displayMode} full>
      {children}
    </ThemeProviderBase>
  );
};
