import { FC, PropsWithChildren } from 'react';
import Helmet from 'react-helmet';
import { BoxProps } from 'grommet';

import { Box } from '~/components/common';

export const Scene: FC<PropsWithChildren<{ title?: string } & BoxProps>> = ({
  children,
  title,
  ...boxProps
}) => {
  return (
    <>
      {!!title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      <Box pad="medium" flexNum={1} {...boxProps}>
        {children}
      </Box>
    </>
  );
};
