import { Box, Spinner, Text } from '~/components/common';

export const TournamentPendingUpdate = ({ message = 'Processing...' }: { message?: string }) => {
  return (
    <Box style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box
        style={{ flex: 1, justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}
        gap="medium"
      >
        <Spinner />
        <Text textAlign="center" size="large">
          {message}
        </Text>
      </Box>
    </Box>
  );
};
