import styled, { CSSProperties } from "styled-components"
import { Box as GrommetBox, BoxExtendedProps } from "grommet"

const StyledBox = styled(GrommetBox)<IBox>`
  ${(props) => {
    return {
      flex: props.flexNum,
      ...props.style,
    }
  }}
`

interface IBox extends BoxExtendedProps {
  flexNum?: number
  style?: CSSProperties
}

export function Box({ style = {}, ...props }: IBox) {
  return <StyledBox {...props} style={style} />
}
