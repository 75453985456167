import React, { FC, useMemo } from 'react';
import { Table, TableBody, TableCell, TableHeader, TableRow } from 'grommet';
import _orderBy from 'lodash/orderBy';

import { Box, Text } from '~/components/common';
import { formatDate } from '~/module/dates';
import { formatPowerDisplay } from '~/module/physics';
import { usePowerStats } from '~/store/hooks';
import { PowerRecords } from '~/store/slices';
import { StyledTableRow } from './UserPower.styles';

import { LineChart } from './LineChart';

type UserPowerSceneProps = {
  onNavToRaceDetail: (raceId: string) => void;
};

type UserPowerProps = {
  powerRecords?: PowerRecords;
  wattHours: number;
};

function formatPowerInterval(powerInterval: number): string {
  if (powerInterval <= 90) {
    return `${powerInterval}s`;
  }
  if (powerInterval % 60 === 0) {
    return `${powerInterval / 60}min`;
  }
  return `${powerInterval}s`;
}

export const UserPower: FC<UserPowerProps & UserPowerSceneProps> = ({
  powerRecords,
  wattHours,
  onNavToRaceDetail,
}) => {
  const lineChartData = useMemo(() => {
    if (powerRecords) {
      return _orderBy(
        Object.entries(powerRecords).map(([powerInterval, powerRecord]) => {
          const powerIntervalSeconds = Number(powerInterval);
          return {
            y: powerRecord.value,
            x: powerIntervalSeconds,
            label: `${formatPowerInterval(powerIntervalSeconds)} power: ${formatPowerDisplay(
              powerRecord.value,
            )}W`,
          };
        }),
        ['x'],
      );
    }
    return [];
  }, [powerRecords]);

  return (
    <Box gap="medium">
      {!Object.keys(powerRecords || {}).length && (
        <Box align="center" margin={{ vertical: 'medium' }}>
          <Box background="contrast" round>
            <Text size="large" margin={{ bottom: 'small' }} textAlign="center">
              No power data to display
            </Text>
            <Text textAlign="center">Build up some data by racing on Critz!</Text>
          </Box>
        </Box>
      )}
      <Box>
        <LineChart
          data={lineChartData}
          scaleX="log"
          tickValuesX={Object.keys(powerRecords || {}).map((r) => Number(r))}
          formatTickX={(datum) => `${formatPowerInterval(datum)}`}
          formatTickY={(datum) => `${datum}W`}
        />
      </Box>
      <Table>
        <TableHeader>
          <TableRow>
            <TableCell>
              <Text weight="bold">Interval</Text>
            </TableCell>
            <TableCell>
              <Text weight="bold">Power</Text>
            </TableCell>
            <TableCell>
              <Text weight="bold">Recorded</Text>
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.entries(powerRecords || {}).map(([powerInterval, powerRecord]) => {
            const raceName = powerRecord.raceID.split('-').slice(1, 3).join('-');
            return (
              <StyledTableRow
                key={powerInterval}
                onClick={() => onNavToRaceDetail(powerRecord.raceID)}
              >
                <TableCell>
                  <Text>{formatPowerInterval(Number(powerInterval))}</Text>
                </TableCell>
                <TableCell>
                  <Text>{formatPowerDisplay(powerRecord.value)}W</Text>
                </TableCell>
                <TableCell>
                  <Text>{formatDate(powerRecord.startTime, 'dd MMM yyyy HH:mm')}</Text>
                  &nbsp;
                  <Text weight="bold">({raceName})</Text>
                </TableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

const useUserPower = (): UserPowerProps => {
  const { powerRecords, cumulativePower } = usePowerStats();
  return {
    powerRecords,
    wattHours: cumulativePower,
  };
};

const UserPowerConnected = (sceneProps: UserPowerSceneProps) => {
  const props = useUserPower();
  return <UserPower {...props} {...sceneProps} />;
};

export default UserPowerConnected;
