import { useEffect, useState } from 'react';
import { WSService } from '../services';

export const useWsConnectedStatus = (ws: Maybe<WSService>) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (ws) {
      const deregister = ws.registerStatusListener((status) => {
        if (status) {
          setConnected(true);
        } else {
          setConnected(false);
        }
      });
      return () => deregister();
    }
  }, [ws, setConnected]);

  return connected;
};
