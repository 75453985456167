import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import { differenceInMinutes, startOfDay } from 'date-fns';

import { Tournament, tournamentsSelectors } from '~/store/slices';
import { useFirestoreTournamentsListener } from '~/module/firebase/firestore/useFirestoreTournaments';

import { getSimplifiedState } from '../../tournaments.utils';

export const useTournamentList = () => {
  useFirestoreTournamentsListener(); // TODO - split into separate listeners with start time filters

  const tournaments = useSelector(tournamentsSelectors.getTournamentsList);

  const { upcoming, today, complete } = useMemo(() => {
    const upcomingItems: Tournament[] = [];
    const todayItems: Tournament[] = []; // today
    const completeItems: Tournament[] = [];

    const now = new Date();

    _orderBy(tournaments, ['startTime', 'desc']).forEach((t) => {
      const state = getSimplifiedState(t.state);
      const startDateHoursInFuture = differenceInMinutes(t.details.startTime, now) / 60;
      const isToday =
        startOfDay(t.details.startTime).toISOString() === startOfDay(now).toISOString();

      if (isToday) {
        todayItems.push(t);
      } else if (state === 'complete') {
        completeItems.push(t);
      } else if (state === 'pre-event' && startDateHoursInFuture > -12) {
        upcomingItems.push(t);
      }
    });

    return {
      upcoming: upcomingItems,
      today: todayItems,
      complete: completeItems,
    };
  }, [tournaments]);

  return {
    upcoming,
    today,
    complete,
  };
};
