import styled, { CSSProperties } from 'styled-components';
import React, { useMemo, PropsWithChildren } from 'react';
import { Text as GrommetText, TextProps } from 'grommet';

interface IText extends TextProps {
  style?: CSSProperties;
  uppercase?: boolean;
}

const StyledText = styled(GrommetText)<TextProps>`
  ${(props) => {
    return {
      ...props.style,
    };
  }}
`;

export const Text: React.FC<PropsWithChildren<IText>> = ({
  children,
  style = {},
  uppercase,
  ...otherProps
}) => {
  const textStyle = useMemo(() => {
    const s = {
      ...style,
    };
    if (uppercase) {
      s.textTransform = 'uppercase';
    }
    return s;
  }, [style, uppercase]);
  return (
    <StyledText {...otherProps} style={textStyle}>
      {children}
    </StyledText>
  );
};
