import { useCallback } from 'react';
import { IS_DEV_MODE, STRAVA_CLIENT_ID } from '~/module/config';
import { absoluteUrls } from '~/navigation/Navigation.paths';

export const usePromptStravaAuth = () => {
  const onPromptAuth = useCallback(() => {
    const origin = window.location.origin;

    let redirectUri = `https://critz.cc${absoluteUrls.stravaAuth}`;
    if (IS_DEV_MODE) {
      redirectUri = `${origin}${absoluteUrls.stravaAuth}`;
    } else if (origin.indexOf('dev.critz.cc') > -1) {
      redirectUri += '?redirectToDev=1';
    }

    const url = `https://www.strava.com/oauth/authorize?client_id=${encodeURIComponent(
      STRAVA_CLIENT_ID,
    )}&scope=read,activity:write&response_type=code&redirect_uri=${encodeURIComponent(
      redirectUri,
    )}`;
    window.open(url, '_blank');
  }, []);
  return {
    onPromptAuth,
  };
};
