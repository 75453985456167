import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { collection, onSnapshot, query, where } from 'firebase/firestore';
import _orderBy from 'lodash/orderBy';

import { raceHistoryActions, RaceHistoryItem } from '~/store/slices';
import { useUser } from '~/store/hooks';
import { transformFirestoreUserRace } from '~/subscriptions/transforms';

import { firestore } from '../InitFirebase';
import { Race } from './firestore.types';
import { reportError } from '~/module/logging';

function userRaceHistoryListener(userId: string, callback: (d: Race[]) => void): UnsubcribeFn {
  const racesRef = collection(firestore, `races`);
  const q = query(racesRef, where('participantList', 'array-contains', userId));

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const races = snapshot.docs.map((doc) => {
      return doc.data() as Race;
    });
    if (races) {
      callback(races);
    }
  });
  return unsubscribe;
}

export const useFirestoreUserRaceHistoryListener = () => {
  const dispatch = useDispatch();

  const { userId } = useUser();

  const onDataUpdated = useCallback(
    (races: Race[]) => {
      const racesArr: RaceHistoryItem[] = [];
      races.forEach((race) => {
        if (race.riderSummary && race.riderSummary.length) {
          try {
            racesArr.push(transformFirestoreUserRace(race, userId));
          } catch (err) {
            reportError(`Error transforming race ${race.raceID}`, err);
          }
        }
      });
      dispatch(
        raceHistoryActions.onSetUserRaceHistory({
          races: _orderBy(racesArr, ['raceStartTime'], ['desc']),
        }),
      );
    },
    [dispatch, userId],
  );

  useEffect(() => {
    if (userId) {
      const unsubscribe = userRaceHistoryListener(userId, onDataUpdated);
      return () => unsubscribe();
    }
  }, [userId, onDataUpdated]);
};
