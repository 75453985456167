import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { doc, onSnapshot } from 'firebase/firestore';

import { useUser } from '~/store/hooks';

import { firestore } from '../InitFirebase';
import { UserRaceMeasuresDocument, UserRacePerformanceDocument } from './firestore.types';
import { raceHistoryActions } from '~/store/slices';

function userRaceHistoryItemMeasuresListener(
  userId: string,
  raceId: string,
  callback: (d: UserRaceMeasuresDocument) => void,
): UnsubcribeFn {
  const measuresDocRef = doc(firestore, `users/${userId}/measures/${raceId}`);

  const unsubscribe = onSnapshot(measuresDocRef, (snapshot) => {
    const measures = snapshot.data() as UserRaceMeasuresDocument;
    if (measures) {
      callback(measures);
    }
  });

  return unsubscribe;
}

function userRaceHistoryItemPerformanceListener(
  userId: string,
  raceId: string,
  callback: (doc: UserRacePerformanceDocument) => void,
): UnsubcribeFn {
  const performanceDocRef = doc(firestore, `users/${userId}/performance/${raceId}`);

  const unsubscribe = onSnapshot(performanceDocRef, (snapshot) => {
    const performance = snapshot.data() as UserRacePerformanceDocument;
    if (performance) {
      callback(performance);
    }
  });

  return unsubscribe;
}

export const useFirestoreUserRaceHistoryItemListener = (raceId: string) => {
  const dispatch = useDispatch();

  const { userId } = useUser();

  const onMeasuresUpdated = useCallback(
    (data: any) => {
      console.log('measures', data);
      dispatch(
        raceHistoryActions.onSetUserRaceHistoryDetail({
          raceId,
          data: {
            measures: data,
          },
        }),
      );
    },
    [dispatch, raceId],
  );

  const onPerformanceUpdated = useCallback(
    (data: any) => {
      console.log('performance', data);
      dispatch(
        raceHistoryActions.onSetUserRaceHistoryDetail({
          raceId,
          data: {
            performance: data,
          },
        }),
      );
    },
    [dispatch, raceId],
  );

  useEffect(() => {
    if (userId && raceId) {
      const unsubscribeMeasures = userRaceHistoryItemMeasuresListener(
        userId,
        raceId,
        onMeasuresUpdated,
      );
      const unsubscribePerformance = userRaceHistoryItemPerformanceListener(
        userId,
        raceId,
        onPerformanceUpdated,
      );
      return () => {
        unsubscribeMeasures();
        unsubscribePerformance();
      };
    }
  }, [userId, raceId, onMeasuresUpdated, onPerformanceUpdated]);
};
