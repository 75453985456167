import React from 'react';
import { Provider } from 'react-redux';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, persistStore } from 'redux-persist';

import rootReducer from './rootReducer';
import { PersistGate } from 'redux-persist/integration/react';

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      // https://github.com/rt2zz/redux-persist/issues/988#issuecomment-552242978
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

const persistor = persistStore(store);

function AppLoading() {
  // TODO: replace with loading indicator unless Splashscreen implementation dictates this will never be seen
  return null;
}

export function StoreProvider({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={store}>
      <PersistGate loading={<AppLoading />} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}
