import { memo, useEffect, useRef } from 'react';
import { useMediaTrack } from '@daily-co/daily-react-hooks';
import { CSSProperties } from 'styled-components';

export const VideoTile = memo(({ id, style }: { id: string; style?: CSSProperties }) => {
  const videoTrack = useMediaTrack(id, 'video');

  const videoElement = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const video = videoElement.current;
    if (!video || !videoTrack?.persistentTrack) return;
    /*  The track is ready to be played. We can show video of the participant in the UI. */
    video.srcObject = new MediaStream([videoTrack?.persistentTrack]);
  }, [videoTrack?.persistentTrack]);

  if (!videoElement) return null;
  return (
    <video
      autoPlay
      muted
      playsInline
      ref={videoElement}
      style={{
        height: '100%',
        width: '100%',
        ...style,
      }}
    />
  );
});
