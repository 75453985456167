export const getDistanceIntervals = ({
  markerInterval,
  totalDistance,
}: {
  markerInterval: number;
  totalDistance: number;
}): number[] => {
  const numMarkers = Math.floor(totalDistance / markerInterval);
  let markers: number[] = [];
  for (let i = 0; i < numMarkers; i += 1) {
    const distance = (i + 1) * markerInterval;
    if (distance !== totalDistance) {
      markers.push((i + 1) * markerInterval);
    }
  }
  return markers;
};

type CountdownInterval = {
  distance: number;
  fromFinish: number;
};

export const getCountdownIntervals = ({
  markerInterval,
  totalDistance,
}: {
  markerInterval: number;
  totalDistance: number;
}): CountdownInterval[] => {
  let toGo = 0;
  const markers: CountdownInterval[] = [];
  while (toGo < totalDistance) {
    toGo += markerInterval;
    const distance = totalDistance - toGo;
    if (distance >= 150) {
      markers.push({
        distance,
        fromFinish: toGo,
      });
    }
  }
  return markers;
};
