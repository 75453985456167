import { FC, MutableRefObject, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
import { EffectComposer, SelectiveBloom, ChromaticAberration } from '@react-three/postprocessing';
import { Canvas, useFrame, extend, useThree, ReactThreeFiber } from '@react-three/fiber';

import { RaceRider, RaceRiders } from '../../Race.types';

import { RaceTrack } from './ThreeRaceTrack';
import { TrackLighting } from './ThreeTrackLighting';
import { Riders } from './ThreeRiders';
import { PerfMeasure } from './Perf';
import { useRaceRefs } from './useRaceRefs';
import { useUser } from '~/store/hooks';

extend({ OrbitControls });

declare global {
  namespace JSX {
    interface IntrinsicElements {
      orbitControls: ReactThreeFiber.Object3DNode<OrbitControls, typeof OrbitControls>;
      textGeometry: ReactThreeFiber.BufferGeometryNode<TextGeometry, typeof TextGeometry>;
    }
  }
}

const cameraStartZ = 0.4;
const cameraMain: any = {
  position: [0, 0.15, cameraStartZ],
  near: 0.01,
  far: 20,
  zoom: 1,
  fov: 75,
};

const CameraControls = () => {
  // Get a reference to the Three.js Camera, and the canvas html element.
  // We need these to setup the OrbitControls component.
  // https://threejs.org/docs/#examples/en/controls/OrbitControls
  const {
    camera,
    gl: { domElement },
  } = useThree();
  // Ref to the controls, so that we can update them on every frame using useFrame
  const controls = useRef(null);
  // @ts-ignore
  useFrame((state) => controls.current.update());
  return <orbitControls ref={controls} args={[camera, domElement]} />;
};

type RaceSceneProps = {
  raceHasStarted: boolean;
  trackUnitDistance: number;
  observer: RaceRider | null;
  otherRiders: RaceRiders;
  totalDistance: number;
  distanceMarkerIntervals: number;
  clientPowerRef: MutableRefObject<number>;
  clientTimeOffsetRef: MutableRefObject<number>;
};

const RaceScene: FC<RaceSceneProps> = ({
  raceHasStarted,
  trackUnitDistance,
  observer,
  otherRiders,
  totalDistance,
  distanceMarkerIntervals,
  clientPowerRef,
  clientTimeOffsetRef,
}) => {
  const { camera } = useThree();

  const CAMERATRACKINGDISTANCE = 0.4;
  const DISTANCE_SCALE = 1 / 4;

  useEffect(() => {
    camera.position.x = 0;
    camera.position.y = 0.15;
    camera.position.z = CAMERATRACKINGDISTANCE;
    camera.lookAt(0, 0, 0);
  }, [camera]);

  useFrame((state, delta) => {
    const riderErf = observer?.powerErf || 0;

    const target = DISTANCE_SCALE * riderErf + CAMERATRACKINGDISTANCE;
    const currentPosition = camera.position.z;
    const frameTarget = THREE.MathUtils.lerp(target, currentPosition, 0.99);

    camera.position.z = frameTarget;
  });

  return (
    <scene>
      <CameraControls />
      <TrackLighting />
      <RaceTrack
        trackUnitDistance={trackUnitDistance}
        distanceCovered={observer?.scaledDistanceCovered ?? 0}
        distanceMarkerIntervals={distanceMarkerIntervals}
        speed={observer?.scaledVelocity || 0}
        totalDistance={totalDistance}
      />
      <Riders
        raceHasStarted={raceHasStarted}
        observer={observer}
        otherRiders={otherRiders}
        trackUnitDistance={trackUnitDistance}
        clientPowerRef={clientPowerRef}
        clientTimeOffsetRef={clientTimeOffsetRef}
      />
    </scene>
  );
};

export const ThreeRaceScene: FC<{
  raceHasStarted: boolean;
  totalDistance: number;
  distanceMarkerIntervals: number;
  observer: RaceRider | null;
  otherRiders: RaceRiders;
  enableThreePostProcessing?: boolean;
  enableThreePerf?: boolean;
}> = ({
  raceHasStarted,
  totalDistance,
  distanceMarkerIntervals,
  observer,
  otherRiders,
  enableThreePerf,
  enableThreePostProcessing,
}) => {
  const mountRef = useRef(null);

  const { userId } = useUser();
  const clientIsMe = userId === observer?.id;

  const { clientPowerRef: powerRef, clientTimeOffsetRef } = useRaceRefs();
  const emptyRef = useRef(0);

  const clientPowerRef = clientIsMe ? powerRef : emptyRef;
  const observerErf = observer?.powerErf || 0;

  const CHROMATIC_SCALE = 500;
  let chromaticValue = 0;
  if (observerErf > 0) {
    chromaticValue = observerErf / CHROMATIC_SCALE;
  }

  return (
    <Canvas ref={mountRef} className="webgl" camera={cameraMain}>
      {enableThreePerf && <PerfMeasure />}
      <fog attach="fog" color="black" near={1} far={7} />
      <RaceScene
        raceHasStarted={raceHasStarted}
        trackUnitDistance={25}
        observer={observer}
        otherRiders={otherRiders}
        totalDistance={totalDistance}
        distanceMarkerIntervals={distanceMarkerIntervals}
        clientPowerRef={clientPowerRef}
        clientTimeOffsetRef={clientTimeOffsetRef}
      />
      {enableThreePostProcessing && (
        <EffectComposer multisampling={0}>
          <SelectiveBloom
            kernelSize={4}
            luminanceThreshold={0.1}
            intensity={3}
            luminanceSmoothing={0}
          />
          <ChromaticAberration offset={new THREE.Vector2(chromaticValue, 0.0)} />
        </EffectComposer>
      )}
    </Canvas>
  );
};
