import { FC, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Anchor } from 'grommet';
import { CaretNext } from 'grommet-icons';

import { Box, Text } from '~/components/common';
import { PrimaryColor } from '~/theme/theme';

type Crumb = {
  label: string;
  onClick?: () => void;
  active: boolean;
};

export const BreadCrumbs: FC<{ crumbs: Crumb[] }> = ({ crumbs }) => {
  const navigate = useNavigate();
  const onNavToHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  const allCrumbs = useMemo(() => {
    return [
      {
        label: 'Home',
        active: false,
        onClick: onNavToHome,
      },
      ...crumbs,
    ];
  }, [crumbs, onNavToHome]);

  if (!crumbs.length) {
    return null;
  }

  return (
    <Box align="center" direction="row">
      {allCrumbs.map(({ label, onClick, active }, index) => {
        const hasMore = index < allCrumbs.length - 1;
        return (
          <Box key={index} direction="row" align="center" height="40px">
            {onClick ? (
              <Anchor onClick={onClick} label={label} weight="bold" color={PrimaryColor} />
            ) : (
              <Text color={PrimaryColor}>{label}</Text>
            )}
            {hasMore && (
              <Box margin={{ left: 'small', right: 'small' }}>
                <CaretNext color={PrimaryColor as any} size="small" />
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};
