import { FC, Fragment } from 'react';
import { TextProps } from 'grommet';

import { msToTime, timeToClockString } from '~/module/utils';
import { Text } from '~/components/common';

export const ClockDisplay: FC<
  { ms: number; showHours?: boolean; showMinutes?: boolean; showSeconds?: boolean } & TextProps
> = ({ ms, showHours = false, showMinutes = true, showSeconds = true, ...textProps }) => {
  const time = msToTime(ms);
  const timeStr = timeToClockString(time, {
    hours: showHours,
    minutes: showMinutes,
    seconds: showSeconds,
  });
  const timeParts = timeStr.split(':');

  return (
    <Text textAlign="center" size="xxlarge" {...textProps}>
      {timeParts.map((p, index) => {
        return (
          <Fragment key={index}>
            {p}
            {index < timeParts.length - 1 ? ':' : ''}
          </Fragment>
        );
      })}
    </Text>
  );
};
