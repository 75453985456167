import { FC } from 'react';

import { Box, Text } from '~/components/common';

type UserHandleProps = {
  displayName: string;
  userName: string;
  accent?: boolean;
};

export const UserHandle: FC<UserHandleProps> = ({ displayName, userName, accent = false }) => {
  return (
    <Box direction="row" gap="xsmall" align="center">
      <Text weight="bold" color={accent ? 'accent-1' : undefined}>
        {displayName}
      </Text>
      <Text color={accent ? 'accent-1' : 'text-xweak'} size="small">
        @{userName}
      </Text>
    </Box>
  );
};
