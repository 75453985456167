import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  appActions,
  appSelectors,
} from '~/store/slices';
import { CacheKey, getCacheItem, setCacheItem } from '~/module/cache';

export function useDisplayMode() {
  const dispatch = useDispatch();
  const displayMode = useSelector(appSelectors.getDisplayMode);

  const onToggleDisplayMode = useCallback(() => {
    const nextDisplayMode = displayMode === 'light' ? 'dark' : 'light';
    setCacheItem(CacheKey.UI_DISPLAY_MODE, nextDisplayMode);
    dispatch(
      appActions.onSetUiDisplayMode(nextDisplayMode),
    );
  }, [dispatch, displayMode]);

  useEffect(() => {
    const cachedDisplayMode = getCacheItem(CacheKey.UI_DISPLAY_MODE);
    if (cachedDisplayMode === 'light' || cachedDisplayMode === 'dark') {
      dispatch(
        appActions.onSetUiDisplayMode(cachedDisplayMode),
      );
    }
  }, [dispatch]);

  return {
    displayMode,
    onToggleDisplayMode,
  };
}
