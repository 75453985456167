import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserPower } from '~/features/History';
import { SceneComponent } from '~/navigation/Navigation.types';

import { BreadCrumbs } from '../layout/Content';
import { Scene } from '../layout/Scene';
import { getPathToRaceHistoryItem } from '../Navigation.paths';

export const UserPowerScene: SceneComponent<{}> = () => {
  const navigate = useNavigate();

  const onNavToRaceDetail = useCallback(
    (raceId: string) => {
      navigate(getPathToRaceHistoryItem({ raceId }));
    },
    [navigate],
  );

  return (
    <Scene width="xlarge">
      <BreadCrumbs crumbs={[{ label: 'Power Records', active: true }]} />
      <UserPower onNavToRaceDetail={onNavToRaceDetail} />
    </Scene>
  );
};
