import { BoxProps } from 'grommet';
import { FC } from 'react';
import { Box } from './common';

export const Logo: FC<{ width?: number } & Omit<BoxProps, 'width'>> = ({
  width = 200,
  ...boxProps
}) => {
  return (
    <Box align="center" {...boxProps}>
      <img src="/img/logo/dark logo and wordmark transparent.png" width={width} alt="Critz.cc" />
    </Box>
  );
};
