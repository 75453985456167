import { useNavigate } from 'react-router-dom';

import { SceneComponent } from '~/navigation/Navigation.types';
import { SignUp } from '~/features/Auth';

import { absoluteUrls } from '../Navigation.paths';
import { Scene } from '../layout/Scene';

export const SignUpScene: SceneComponent<{}> = () => {
  const navigate = useNavigate();
  return (
    <Scene title="Sign Up">
      <SignUp
        onSignInSuccess={() => navigate(absoluteUrls.home)}
        onNavToSignIn={() => navigate(absoluteUrls.signIn)}
      />
    </Scene>
  );
};
