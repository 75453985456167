import { useContext } from 'react';
import { ThemeContext } from 'grommet';

import { Theme } from './theme';

export const useTheme = (): Theme => {
  const theme = useContext(ThemeContext) as Theme;
  return {
    ...theme,
  };
};
