import { Link } from "react-router-dom";

import { SceneComponent } from '~/navigation/Navigation.types';
import { Scene } from "../layout/Scene";

export const NotFoundScene: SceneComponent<{}> = () => {
  return (
    <Scene>
      <h1>Not Found</h1>
      <p>It looks like this is a broken link, sorry!</p>
      <div>
        <Link to="/">Back to Homepage</Link>
      </div>
    </Scene>
  );
};
