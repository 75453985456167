import { useNavigate } from 'react-router-dom';

import { SceneComponent } from '~/navigation/Navigation.types';
import { SignIn } from '~/features/Auth';

import { absoluteUrls } from '../Navigation.paths';
import { Scene } from '../layout/Scene';

export const SignInScene: SceneComponent<{}> = () => {
  const navigate = useNavigate();
  return (
    <Scene title="Login">
      <SignIn
        onNavToResetPassword={() => navigate(absoluteUrls.resetPassword)}
        onSignInSuccess={() => navigate(absoluteUrls.home)}
        onNavToCreateAccount={() => navigate(absoluteUrls.signUp)}
      />
    </Scene>
  );
};
