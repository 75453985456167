import { useParams } from 'react-router-dom';

import { DynamicURLTypes } from '~/navigation/Navigation.paths';
import { SceneComponent } from '~/navigation/Navigation.types';
import { Box } from '~/components/common';
import { ObserveSpace } from '~/features/Spaces/components/ObserveSpace';
import { Helmet } from 'react-helmet';

export const ObserveSpaceScene: SceneComponent<{}> = () => {
  const { spaceId: spaceIdUrl } = useParams<DynamicURLTypes['observeSpace']>();
  const spaceId = spaceIdUrl?.toLowerCase();

  if (!spaceId) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{spaceId}</title>
      </Helmet>
      <Box style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <ObserveSpace id={spaceId} />
      </Box>
    </>
  );
};
