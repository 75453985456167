import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { integrationActions, integrationSelectors } from '../slices';

export const useStravaIntegration = () => {
  const dispatch = useDispatch();
  const isConnected = useSelector(integrationSelectors.getIsStravaConnected);
  const hasAuthToken = useSelector(integrationSelectors.getHasStravaAuthToken);
  const shouldAutoUploadRaces = useSelector(integrationSelectors.getShouldAutoUploadRacesToStrava);

  const onStravaConnectionState = useCallback(
    ({
      isConnected,
      hasAuthToken,
      shouldAutoUploadRaces,
    }: {
      isConnected: boolean;
      hasAuthToken: boolean;
      shouldAutoUploadRaces: boolean;
    }) => {
      dispatch(
        integrationActions.onStravaConnectionState({
          isConnected,
          hasAuthToken,
          shouldAutoUploadRaces,
        }),
      );
    },
    [dispatch],
  );

  return {
    isConnected,
    hasAuthToken,
    shouldAutoUploadRaces,
    onStravaConnectionState,
  };
};
