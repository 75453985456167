import { BoxProps } from 'grommet';
import { FC, PropsWithChildren } from 'react';

import { Box } from '~/components/common';

export const Card: FC<PropsWithChildren<BoxProps>> = ({ children, ...props }) => {
  return (
    <Box
      pad="medium"
      round="small"
      border={{ color: 'accent-1', size: 'small' }}
      elevation="small"
      {...props}
    >
      {children}
    </Box>
  );
};
