import { FC } from 'react';
import { Meter } from 'grommet';

import { Box, Text } from '~/components/common';

import { getCountdownIntervals } from '../race.utils';

import './HudProgressMeter.styles.css';

export const HudProgressMeter: FC<{
  hudColor?: string;
  totalDistance: number;
  percentageComplete: number;
  distanceMarkerIntervals: number;
}> = ({ hudColor = 'neutral-1', totalDistance, percentageComplete, distanceMarkerIntervals }) => {
  const markerIntervals = getCountdownIntervals({
    totalDistance,
    markerInterval: distanceMarkerIntervals,
  });
  const distanceRemaining = totalDistance * (1 - percentageComplete);
  const roundedDistanceRemaining =
    distanceRemaining < 1000
      ? Math.ceil(distanceRemaining / 10) * 10
      : Math.ceil(distanceRemaining / 100) * 100;

  return (
    <Box className="HudProgressMeter" style={{ position: 'relative' }}>
      <Meter
        value={percentageComplete * 100}
        max={100}
        size="full"
        thickness="small"
        color={percentageComplete >= 1 ? 'brand' : hudColor}
      />
      {markerIntervals.map(({ distance }) => {
        const percentage = (distance / totalDistance) * 100;
        return (
          <Box
            key={distance}
            background="brand"
            style={{
              position: 'absolute',
              left: `${percentage}%`,
              top: 0,
              bottom: 0,
              width: 3,
            }}
          />
        );
      })}
      {percentageComplete > 0 && (
        <Box
          className="DistanceRemainingTag"
          background="brand"
          round
          pad="small"
          style={{
            zIndex: 10,
            position: 'absolute',
            left: `${Math.min(percentageComplete, 0.97) * 100}%`,
            top: 16,
            transform: 'translateX(-16px)',
          }}
        >
          <Text size="small" weight="bold">
            {roundedDistanceRemaining}m
          </Text>
        </Box>
      )}
    </Box>
  );
};
