/* *** Schema DTOs *** */

export type RaceStatus = 'awaiting' | 'countdown' | 'inplay' | 'finished';

export type SpaceDetails = {
  distance: number;
  targetDuration: number;
  allowManualStart: boolean;
  fieldSize: number;
  npcCount: number;
  useDistanceScale: boolean;
  lowerDistanceScaleBound?: number;
  upperDistanceScaleBound?: number;
  raceStart?: number;
  status: RaceStatus;
};

export type Space = {
  spaceUUID: string;
  currentParticipantCount: number;
  details: SpaceDetails;
};

export type TournamentState = 'pre-event' | 'complete' | string;
// pendingRound1, pendingRound2
// racingRound1, racingRound2
// completeRound1, completeRound2

export type TournamentRiderStatus = 'confirmed' | 'pending-confirmation';

export type TournamentDetails = {
  breaksDurationsBetweenRoundsSeconds: number;
  confirmPresence: boolean;
  finalRoundDuration: number;
  mustBeOnlineBy: number;
  registrationCloses: number;
  registrationOpen: boolean;
  startTime: number;
  state: TournamentState;
  structure: number[];
  tournamentFinished: boolean;
  useDistanceScale: boolean;
  tournamentName: string;
  tournamentID: string;
  confirmPresenceStart: number;
  confirmationWindowOpen: boolean;
};

export type Tournament = {
  tournamentID: string;
  signups: number;
  details: TournamentDetails;
};

export type TournamentEntry = {
  tournamentID: string;
  riderStatus: TournamentRiderStatus;
};

export type MeasureBuffer = any;

export type UserRaceState = {
  acceleration: number;
  completedTime: number;
  courseDistance: number;
  currentDistance: number;
  lastUpdate_ms: number;
  percentageComplete: number;
  raceComplete: boolean;
  raceStart_ms: number;
  riderColour: string;
  spaceUUID: string;
  speed: number;
  status: RaceStatus;
  lockedDistanceScale: number;
  lockedWeight: number;
};

export type UserState = {
  clockOffset: {
    meanOffset: number;
    measures: MeasureBuffer;
  };
  displayName: string;
  distanceScale: number;
  latency: {
    meanLatency: number;
    measures: MeasureBuffer;
  };
  state: {
    raceState?: UserRaceState;
    sensorData: {
      heartrate: {
        value: number;
        lastUpdate: any;
      };
      wattage: {
        value: number;
        lastUpdate: any;
      };
    };
  };
  userName: string;
  userUUID: string;
  watchingConnections: string[];
  weight: number;
};

export type SpaceParticipantState = {
  velocity: number;
  acceleration: number;
  percentageComplete: number;
  lastUpdate_ms: number;
  draftInfo: {
    efficiency: number; // 0-1 percentage efficiency
    savingWatts: number; // >= 0
  };
};

export type RiderPowerAndHRState = {
  heartrate: number;
  wattage: number;
};

/* *** Messages *** */

export type WsMethod =
  | 'ping'
  | 'pang'
  | 'whoAmI'
  | 'updateUser'
  | 'joinSpace'
  | 'leaveSpace'
  | 'createSpace'
  | 'observeSpace'
  | 'stopObservingSpace'
  | 'startRace'
  | 'postData'
  | 'checkUniqueUsername'
  | 'registerForTournament'
  | 'unregisterForTournament'
  | 'getMyTournamentEntries'
  | 'confirmTournamentEntry'
  | 'auth';

export type WsAsyncMethod =
  | 'publicSpaces'
  | 'publicTournaments'
  | 'listAllUsersInSpace'
  | 'newUserJoinedSpace'
  | 'userLeftSpace'
  | 'stateUpdate'
  | 'measureUpdate'
  | 'riderPowerAndHRUpdate'
  | 'raceCountDown'
  | 'raceStart'
  | 'raceEnd'
  | 'riderFinished';

export type WsMessageType = WsMethod | WsAsyncMethod;

export const WSMessageTypes: { [key in WsMessageType]: string } = {
  ping: 'ping',
  pang: 'pang',
  whoAmI: 'whoAmI',
  updateUser: 'updateUser',
  publicSpaces: 'publicSpaces',
  publicTournaments: 'publicTournaments',
  listAllUsersInSpace: 'listAllUsersInSpace',
  newUserJoinedSpace: 'newUserJoinedSpace',
  userLeftSpace: 'userLeftSpace',
  stateUpdate: 'stateUpdate',
  measureUpdate: 'measureUpdate',
  riderPowerAndHRUpdate: 'riderPowerAndHRUpdate',
  raceCountDown: 'raceCountDown',
  raceStart: 'raceStart',
  raceEnd: 'raceEnd',
  riderFinished: 'riderFinished',
  joinSpace: 'joinSpace',
  leaveSpace: 'leaveSpace',
  observeSpace: 'observeSpace',
  stopObservingSpace: 'stopObservingSpace',
  createSpace: 'createSpace',
  startRace: 'startRace',
  postData: 'postData',
  checkUniqueUsername: 'checkUniqueUsername',
  registerForTournament: 'registerForTournament',
  unregisterForTournament: 'unregisterForTournament',
  getMyTournamentEntries: 'getMyTournamentEntries',
  confirmTournamentEntry: 'confirmTournamentEntry',
  auth: 'auth',
};

export type ApiMessageFormat<Data extends any> = {
  action: string;
  message: string;
  message_uuid: string;
  topic: string;
  data: Data;
};

type PublicSpacesMessage = ApiMessageFormat<{
  openSpaceCount: number;
  spaceDetails: Space[];
}>;

type PublicTournamentsMessage = ApiMessageFormat<{
  tournamentDetails: Tournament[];
}>;

type SpaceParticipantsMessage = ApiMessageFormat<{
  users: UserState[];
}>;

type NewUserJoinedSpaceMessage = ApiMessageFormat<{
  user: {
    userUUID: string;
    displayName: string;
    userDetails: UserState;
  };
}>;

type UserLeftSpaceMessage = ApiMessageFormat<{
  user: {
    userUUID: string;
    displayName: string;
  };
}>;

type StateUpdateMessage = ApiMessageFormat<{
  [key: string]: SpaceParticipantState;
}>;

type MeasureUpdateMessage = ApiMessageFormat<{
  measurements: Array<{ field: RaceDataMeasure; value: number }>;
}>;

type RiderPowerAndHRUpdateMessage = ApiMessageFormat<{
  [key: string]: RiderPowerAndHRState;
}>;

type RaceCountDownMessage = ApiMessageFormat<{
  raceStartTime: number;
  assignedColours: Record<string, string>;
}>;
type RaceStartMessage = ApiMessageFormat<{
  raceStartTime: number;
  assignedColours: Record<string, string>;
}>;
type RaceEndMessage = ApiMessageFormat<{
  competitors: number;
  duration: number;
  riderSummary: Array<{
    riderUUID: string;
    rank: number;
    distanceDelta: number; // distance from winner in unit distance (i.e. unscaled to my perspective)
    timeDelta: number;
    raceComplete: boolean;
  }>;
}>;
type RiderFinishedMessage = ApiMessageFormat<{
  riderUUID: string;
  finishTime: number;
}>;

export type WSApiMessages = {
  publicSpaces: PublicSpacesMessage;
  publicTournaments: PublicTournamentsMessage;
  listAllUsersInSpace: SpaceParticipantsMessage;
  newUserJoinedSpace: NewUserJoinedSpaceMessage;
  userLeftSpace: UserLeftSpaceMessage;
  stateUpdate: StateUpdateMessage;
  measureUpdate: MeasureUpdateMessage;
  riderPowerAndHRUpdate: RiderPowerAndHRUpdateMessage;
  raceCountDown: RaceCountDownMessage;
  raceStart: RaceStartMessage;
  raceEnd: RaceEndMessage;
  riderFinished: RiderFinishedMessage;
};

/* *** Request / Response ***/

export interface ApiRequest<RequestDataType> {
  method: WsMethod;
  request_id: string;
  data?: RequestDataType;
}

export interface ApiResponse<ResponseDataType> {
  method: WsMethod;
  request_id: string;
  status: 'OK' | 'ERROR';
  message: string;
  data?: ResponseDataType;
  exception?: {
    code: number;
    message: string;
  };
}

export type PingRequestData = {
  clientTime: string;
};

export type PingResponseData = {
  pongUUID: string;
  serverTime: number;
  valid: boolean;
};

export type PangRequestData = {
  clientTime: string;
  pongUUID: string;
};

export type PangResponseData = {
  pongUUID: string;
  valid: boolean;
  clockOffset: number;
  latency: number;
};

export type WhoAmIRequestData = null;

export type WhoAmIResponseData = {
  valid: boolean;
  User: UserState;
};

export type UpdateUserRequestData = Partial<UserState>;

export type UpdateUserResponseData = {
  valid: boolean;
  updatedFields: Partial<UserState>;
};

export type JoinSpaceRequestData = {
  spaceUUID: string;
};

export type JoinSpaceResponseData = {
  valid: boolean;
  spaceUUID: string;
  details: SpaceDetails;
  invalid_reason?: string;
};

export type LeaveSpaceRequestData = {
  spaceUUID: string;
};

export type LeaveSpaceResponseData = {
  valid: boolean;
  spaceUUID: string;
};

export type ObserveSpaceRequestData = {
  spaceUUID: string;
};

export type ObserveSpaceResponseData = {
  valid: boolean;
  spaceUUID: string;
  details: SpaceDetails;
  invalid_reason?: string;
};

export type StopObservingSpaceRequestData = {
  spaceUUID: string;
};

export type StopObservingSpaceResponseData = {
  valid: boolean;
  spaceUUID: string;
};

export type CreateSpaceRequestData = {
  duration: number;
  allowManualStart: boolean;
  fieldSize: number;
  useDistanceScale: boolean;
  countdownSeconds: number;
  isPrivate?: boolean;
  lowerDistanceScaleBound?: number;
  upperDistanceScaleBound?: number;
};

export type CreateSpaceResponseData = {
  spaceUUID: string;
};

export type StartRaceRequestData = {
  spaceUUID: string;
};

export type StartRaceResponseData = {
  raceStartTime: number;
};

export type RaceDataMeasure = 'heartrate' | 'wattage' | 'cadence' | 'level' | 'erg_enabled';

export type PostRaceDataRequestData = {
  data: {
    measurements: Array<{
      field: RaceDataMeasure;
      value: number;
    }>;
  };
};

export type PostRaceDataResponseData = {};

export type CheckUniqueUsernameRequestData = { userName: string };
export type CheckUniqueUsernameResponseData = {
  valid: boolean;
};

export type AuthRequestData = {
  auth_token: string;
};

export type AuthResponseData = {};

export type RegisterForTournamentRequestData = {
  tournamentID: string;
};
export type RegisterForTournamentResponseData = {
  valid: boolean;
  tournamentID: string;
  riderStatus: 'confirmed' | 'pending-confirmation';
};

export type UnRegisterForTournamentRequestData = {
  tournamentID: string;
};
export type UnRegisterForTournamentResponseData = {
  valid: boolean;
  tournamentID: string;
};

export type GetMyTournamentEntriesRequestData = {};
export type GetMyTournamentEntriesResponseData = {
  // TODO - hook up tournament entry format
  tournaments: string[];
};

export type ConfirmTournamentEntryRequestData = {
  tournamentID: string;
};
export type ConfirmTournamentEntryResponseData = {
  valid: boolean;
  tournamentID: string;
};

export type WSRequestMessages = {
  ping: ApiRequest<PingRequestData>;
  pang: ApiRequest<PangRequestData>;
  whoAmI: ApiRequest<WhoAmIRequestData>;
  updateUser: ApiResponse<UpdateUserRequestData>;
  joinSpace: ApiRequest<JoinSpaceRequestData>;
  leaveSpace: ApiRequest<LeaveSpaceRequestData>;
  createSpace: ApiRequest<CreateSpaceRequestData>;
  startRace: ApiRequest<StartRaceRequestData>;
  postData: ApiRequest<PostRaceDataRequestData>;
  checkUniqueUsername: ApiRequest<CheckUniqueUsernameRequestData>;
  registerForTournament: ApiRequest<RegisterForTournamentRequestData>;
  unregisterForTournament: ApiRequest<UnRegisterForTournamentRequestData>;
  getMyTournamentEntries: ApiRequest<GetMyTournamentEntriesRequestData>;
  confirmTournamentEntry: ApiRequest<ConfirmTournamentEntryRequestData>;
  auth: ApiRequest<AuthRequestData>;
};

export type WSResponseMessages = {
  ping: ApiResponse<PingResponseData>;
  pang: ApiResponse<PangResponseData>;
  whoAmI: ApiResponse<WhoAmIResponseData>;
  updateUser: ApiResponse<UpdateUserResponseData>;
  joinSpace: ApiResponse<JoinSpaceResponseData>;
  leaveSpace: ApiResponse<LeaveSpaceResponseData>;
  createSpace: ApiResponse<CreateSpaceResponseData>;
  startRace: ApiRequest<StartRaceResponseData>;
  postData: ApiRequest<PostRaceDataResponseData>;
  checkUniqueUsername: ApiRequest<CheckUniqueUsernameResponseData>;
  registerForTournament: ApiResponse<RegisterForTournamentResponseData>;
  unregisterForTournament: ApiResponse<UnRegisterForTournamentResponseData>;
  getMyTournamentEntries: ApiResponse<GetMyTournamentEntriesResponseData>;
  confirmTournamentEntry: ApiResponse<ConfirmTournamentEntryResponseData>;
  auth: ApiRequest<AuthResponseData>;
};

export type WsMessages = WSResponseMessages & WSApiMessages;
