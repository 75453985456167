import { useEffect } from 'react';
import { Box, Spinner, Text } from '~/components/common';
import { useWsApi, useWsAuthedStatus } from '~/module/api';
import { useActiveSpace, useAuth } from '~/store/hooks';
import { useRaceTime } from '../hooks';
import { ActiveRace } from './Race';
import { DailyProvider } from '@daily-co/daily-react-hooks';

type ObserveSpaceProps = {
  id: string;
};

export const ObserveSpace = ({ id }: ObserveSpaceProps) => {
  const { authenticated, authStateKnown } = useAuth();
  const wsAuthed = useWsAuthedStatus(useWsApi());
  const { activeSpace, joining, error, onObserveSpace, onStopObservingSpace } = useActiveSpace();

  const isSpaceStateReady = !!activeSpace && activeSpace.id === id;
  const canJoinSpace = authStateKnown && authenticated && wsAuthed;
  const { raceHasStarted, raceStartingSoon, raceHasEnded } = useRaceTime();

  useEffect(() => {
    if (canJoinSpace) {
      onObserveSpace(id);
      return () => {
        onStopObservingSpace(id);
      };
    }
  }, [onObserveSpace, onStopObservingSpace, id, canJoinSpace]);

  if (joining) {
    return (
      <Box align="center" justify="center" flexNum={1}>
        <Spinner />
      </Box>
    );
  }

  if (error) {
    return (
      <Box align="center" justify="center" flexNum={1}>
        <Text color="status-error">{error.message}</Text>
      </Box>
    );
  }

  if (!isSpaceStateReady) {
    return (
      <Box align="center" justify="center" flexNum={1}>
        <Spinner />
      </Box>
    );
  }

  // todo - show back to race list or home CTA
  if (raceHasEnded) {
    return (
      <Box align="center" justify="center" flexNum={1}>
        <Text>Race Over</Text>
      </Box>
    );
  }

  if (raceHasStarted || raceStartingSoon) {
    return (
      <DailyProvider>
        <ActiveRace hasAv={false} isObserving />
      </DailyProvider>
    );
  }

  // todo - show race lobby style UI without actions
  return (
    <Box align="center" justify="center" flexNum={1}>
      <Text>Waiting for race to start.</Text>
    </Box>
  );
};
