import { FC } from 'react';
import { Anchor } from 'grommet';

import { Box, Button, Tag, Text } from '~/components/common';
import { MetricDisplay } from '~/components/MetricDisplay';
import { formatDate } from '~/module/dates';

import { canSignUp } from '../../../tournaments.utils';
import { TournamentItemState } from '../../../Tournaments.types';
import { DateCountdown } from '~/components/DateCountdown';

export const TournamentPreStart: FC<{
  tournament: TournamentItemState;
  canJoin: boolean;
  isJoining: boolean;
  hasJoined: boolean;
  canLeave: boolean;
  isLeaving: boolean;
  estimatedNumberOfRounds: number;
  onJoinTournament: () => void;
  onLeaveTournament: () => void;
}> = ({
  canJoin,
  tournament,
  isJoining,
  hasJoined,
  canLeave,
  isLeaving,
  estimatedNumberOfRounds,
  onJoinTournament,
  onLeaveTournament,
}) => {
  return (
    <>
      {hasJoined && (
        <Box background="status-ok" pad="small" align="center">
          <Text weight="bold">You are signed up.</Text>
        </Box>
      )}

      <Box pad="medium" gap="medium">
        <Box border="bottom" pad={{ bottom: 'medium' }} gap="medium">
          <Box direction="row" justify="between">
            <Box gap="small">
              <Text size="4xl" weight="lighter">
                {tournament.name}
              </Text>
              <Text size="2xl">
                {formatDate(tournament.details.startTime, 'HH:mm, dd MMM yyyy')}
              </Text>
              <DateCountdown date={tournament.details.startTimeWithOffset} />
            </Box>

            <Box direction="column" justify="start" align="center" gap="small">
              <Box>
                <MetricDisplay
                  label={tournament.signups === 1 ? 'Participant' : 'Participants'}
                  value={tournament.signups}
                />
              </Box>
              {canSignUp(tournament) ? (
                <Box>
                  {!hasJoined ? (
                    <Button
                      label="Join tournament"
                      size="small"
                      disabled={isJoining || !canJoin || !tournament.details.registrationOpen}
                      onClick={onJoinTournament}
                    />
                  ) : (
                    <Anchor
                      label="Leave Tournament"
                      disabled={isLeaving || !canLeave}
                      onClick={onLeaveTournament}
                    />
                  )}
                </Box>
              ) : (
                <Box>
                  <Text color="text-weak" size="small" uppercase>
                    Registration Closed
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        <Box>
          <Box direction="row" align="start" gap="small" margin={{ bottom: 'small' }}>
            <Box>
              {tournament.details.useDistanceScale ? (
                <Tag value="Handicaps Enabled" />
              ) : (
                <Tag value="No Handicaps" />
              )}
            </Box>
            <Box>
              <Tag value={`${Math.max(estimatedNumberOfRounds, 1)} rounds (est.)`} />
            </Box>
            <Box>
              <Tag value={`Final duration: ${tournament.details.finalRoundDuration}min`} />
            </Box>
            <Box>
              <Tag
                value={`${
                  tournament.details.breaksDurationsBetweenRoundsSeconds / 60
                }min rest between rounds`}
              />
            </Box>
          </Box>
          <Box
            margin={{ top: 'small' }}
            width="large"
            background="background-contrast"
            pad="medium"
            round="small"
            gap="small"
          >
            <Box>
              <Text weight="bold" uppercase margin={{ bottom: 'small' }}>
                Rules
              </Text>
              <Box tag="ul" gap="xsmall">
                <li>
                  <Text size="small">
                    Knockout style tournament with a single winner. Race a series of rounds until
                    its down to 4 riders in the final.
                  </Text>
                </li>
                <li>
                  <Text size="small">
                    Round structure is finalised when registration closes, depending on how many
                    have signed up.
                  </Text>
                </li>
                <li>
                  <Text size="small">
                    The winners of each round will automatically qualify for the next round. Some
                    runners up may go through to the next round, in order of fastest finish times.
                  </Text>
                </li>
                <li>
                  <Text size="small">
                    You must be online when the tournament is due to start, to be included in the
                    first round.
                  </Text>
                </li>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
