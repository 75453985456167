export const CRITZ_SERVER_URL = process.env.REACT_APP_CRITZ_SERVER_URL as string;

export const ENVIRONMENT: string = process.env.REACT_APP_ENVIRONMENT as string;

export const STRAVA_CLIENT_ID: string = process.env.REACT_APP_STRAVA_CLIENT_ID as string;

export const SENTRY_DSN: string = process.env.REACT_APP_SENTRY_DSN as string;

export const APP_CACHE_KEY_PREFIX: string = 'critz_v0';

export const IS_DEV_MODE: boolean = window?.location.href.indexOf('localhost') > -1;

export const IS_DEV_SITE: boolean = window?.location.href.indexOf('dev.critz.cc') > -1;

export const IS_PROD: boolean = !IS_DEV_MODE && ENVIRONMENT === 'prod';

export const FeatureSwitches = {
  enableAv: process.env.REACT_APP_ENABLE_AV === '1',
};
