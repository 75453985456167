import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';
import { User } from './userSlice.types';

export type UserState = {
  user: User | null;
  userDistanceScale: number;
  userId?: string;
  email?: string;
  authStateKnown: boolean;
  isAnonymous?: boolean;
  idToken?: string;
  currentActiveRace: string | null;
};

const initialUserState: UserState = {
  authStateKnown: false,
  user: null,
  userDistanceScale: 1,
  currentActiveRace: null,
};

export function isLogoutAction(action: any) {
  return (
    action?.type === userSlice.actions.onSetAuthState.type && action?.payload?.userId === undefined
  );
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    onSetUser(state, action: PayloadAction<{ user: User }>) {
      state.user = action.payload.user;
    },
    onSetUserDistanceScale(state, action: PayloadAction<{ userDistanceScale: number }>) {
      state.userDistanceScale = action.payload.userDistanceScale;
    },
    onSetCurrentActiveRace(state, action: PayloadAction<{ id: string }>) {
      state.currentActiveRace = action.payload.id;
    },
    onSetAuthState(
      state,
      action: PayloadAction<{
        userId?: string;
        isAnonymous?: boolean;
        idToken?: string;
        email?: string;
      }>,
    ) {
      state.authStateKnown = true;
      state.userId = action.payload.userId;
      state.isAnonymous = action.payload.isAnonymous;
      state.idToken = action.payload.idToken;
      state.email = action.payload.email;
    },
  },
});

export const userReducer = userSlice.reducer;
export const userActions = userSlice.actions;
export const userSelectors = {
  getUser: (state: RootState) => state.user.user,
  getUserDistanceScale: (state: RootState) => state.user.userDistanceScale,
  getCurrentActiveRace: (state: RootState) => state.user.currentActiveRace,
  getUserId: (state: RootState) => state.user.userId || '',
  getUserIdToken: (state: RootState) => state.user.idToken,
};
