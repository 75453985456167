import { FC, useMemo } from 'react';
import _orderBy from 'lodash/orderBy';

import { Box, Icon, Text } from '~/components/common';
import { getVelocityKph } from '~/module/physics';
import { ClockDisplay } from '~/components/ClockDisplay';

import { RaceRider } from '../../Race.types';
import { HudRiderSummary } from './components';
import { HudProgressMeter } from './components/HudProgressMeter';

type RiderSummary = Pick<
  RaceRider,
  | 'id'
  | 'displayName'
  | 'scaledDistanceCovered'
  | 'scaledDistance'
  | 'hasFinished'
  | 'finishTimeMs'
  | 'riderColour'
>;

export const RaceHUD: FC<{
  observerId?: string;
  hudColor?: string;
  numRiders: number;
  raceHasStarted: boolean;
  raceClockMs: number;
  power: number;
  heartRate: number;
  cadence?: number;
  totalDistance: number;
  percentageComplete: number;
  estTimeRemainingMs?: number;
  scaledVelocity: number;
  positionNumber: number;
  ridersSummary: RiderSummary[];
  distanceMarkerIntervals: number;
  onObserveRider: (riderId: string) => void;
}> = ({
  observerId,
  hudColor = 'neutral-1',
  numRiders,
  raceClockMs,
  raceHasStarted,
  power,
  heartRate,
  cadence,
  totalDistance,
  percentageComplete,
  estTimeRemainingMs,
  scaledVelocity,
  positionNumber,
  ridersSummary,
  distanceMarkerIntervals,
  onObserveRider,
}) => {
  const riderSummaryInOrder = useMemo(() => {
    return ridersSummary ? _orderBy(ridersSummary, ['scaledDistanceCovered'], ['desc']) : [];
  }, [ridersSummary]);
  const showRidersSummary = riderSummaryInOrder.length > 1;

  return (
    <Box>
      {/* HUD - Top */}
      <Box style={{ position: 'fixed', top: 0, right: 0, left: 0 }}>
        <Box direction="row" justify="between" align="center" pad="small">
          <Box align="center">
            <ClockDisplay ms={raceHasStarted ? raceClockMs : 0} />
            <Text size="xsmall">Elapsed</Text>
          </Box>
          <Box flexNum={1} margin={{ horizontal: 'small' }}>
            <HudProgressMeter
              percentageComplete={percentageComplete}
              hudColor={hudColor}
              distanceMarkerIntervals={distanceMarkerIntervals}
              totalDistance={totalDistance}
            />
          </Box>
          <Box align="center">
            <ClockDisplay ms={raceHasStarted ? estTimeRemainingMs || 0 : 0} />
            <Text size="xsmall">Est. remaining</Text>
          </Box>
        </Box>
        {showRidersSummary && (
          <Box>
            <HudRiderSummary
              observerId={observerId}
              ridersSummary={ridersSummary}
              onObserveRider={onObserveRider}
            />
          </Box>
        )}
      </Box>

      {/* HUD - Bottom */}
      <Box
        style={{ position: 'fixed', bottom: 0, right: 0, left: 0 }}
        direction="row"
        justify="between"
        align="center"
      >
        <Box
          margin="small"
          pad={{ horizontal: 'medium', vertical: 'medium' }}
          align="center"
          round
          background={{
            // opacity: true,
            color: hudColor,
          }}
          style={{ minWidth: 220 }}
        >
          <Box
            margin={{ bottom: 'medium' }}
            direction="row"
            align="center"
            justify="between"
            width="100%"
          >
            <Box direction="row" align="center">
              <Box margin={{ right: 'xsmall' }}>
                <Icon iconName="heart" />
              </Box>
              <Text style={{ fontSize: '1.5em', lineHeight: '1em' }}>{heartRate}</Text>
            </Box>
            {typeof cadence === 'number' && (
              <Text style={{ fontSize: '1.5em', lineHeight: '1em' }}>
                {cadence}
                <Text size="medium">rpm</Text>
              </Text>
            )}
          </Box>
          <Box margin={{ bottom: 'medium' }}>
            <Text style={{ fontSize: '3em', lineHeight: '1em' }}>
              {power}
              <Text size="large">w</Text>
            </Text>
          </Box>
          <Text style={{ fontSize: '2em', lineHeight: '1em' }}>
            {getVelocityKph(scaledVelocity).toFixed(1)}
            <Text size="medium">kph</Text>
          </Text>
        </Box>
        {raceHasStarted && (
          <>
            <Box
              margin="small"
              pad={{ horizontal: 'medium', vertical: 'large' }}
              round
              background={{
                // opacity: true,
                color: hudColor,
              }}
            >
              <Box direction="row" align="flex-end">
                <Text style={{ fontSize: '4em' }}>{positionNumber || numRiders}</Text>
                <Text style={{ fontSize: '3em' }}>&nbsp;/&nbsp;{numRiders}</Text>
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
