import { FC, useMemo, useState } from 'react';
import _sortBy from 'lodash/sortBy';

import { Box, Button, Icon, Modal, Tag, Text } from '~/components/common';
import { Card } from '~/components/Card';
import { useWsRaceActions } from '~/module/api';
import { SpaceParticipant, SpaceParticipants } from '~/store/slices';
import { useUser } from '~/store/hooks';
import { NotConnectedWarning } from '~/features/Devices';
import { useConnectedDevices } from '~/module/connectedDevices';
import { IS_DEV_MODE } from '~/module/config';
import { ParticipantVideo } from '~/features/AudioVideo/components/ParticipantVideo';
import { SelectAvDevices } from '~/features/AudioVideo/components/SelectAvDevices';
import { AvControlsLocal } from '~/features/AudioVideo/components/AvControls';
import { useCallData } from '~/features/AudioVideo/hooks';
import { findCallParticipant } from '~/features/AudioVideo/AudioVideo.utils';
import { getScaledDistance } from '~/module/physics';

type RaceLobbyProps = {
  id: string;
  participants: SpaceParticipants;
  distance: number;
  duration: number;
  maxRiders: number;
  hasAv: boolean;
  useDistanceScale: boolean;
  leaveSpace: () => void;
};

const MIN_PARTICIPANTS = 2;

export const RaceLobby: FC<RaceLobbyProps> = ({
  id,
  participants,
  distance,
  duration,
  maxRiders,
  useDistanceScale,
  hasAv,
  leaveSpace,
}) => {
  const { startRace, startRaceError, isStartingRace } = useWsRaceActions();
  const { userId } = useUser();
  const { controllableConnected, onConnectControllable } = useConnectedDevices();
  const [showDevicesConfig, setShowDevicesConfig] = useState(false);

  const { callParticipants, localParticipant } = useCallData();

  const participantsList = useMemo(() => Object.values(participants), [participants]);

  const raceRiderList = useMemo(() => {
    const riders: Array<SpaceParticipant | null> = [..._sortBy(participantsList, ['displayName'])];
    for (let i = riders.length; i < maxRiders; i += 1) {
      riders.push(null);
    }
    return riders;
  }, [participantsList, maxRiders]);

  const hasEnoughParticipants = IS_DEV_MODE || participantsList.length >= MIN_PARTICIPANTS;

  return (
    <Box style={{ position: 'relative', paddingTop: controllableConnected ? 0 : 80 }}>
      {!controllableConnected && (
        <Box style={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
          <NotConnectedWarning
            deviceIcon="bike"
            deviceLabel="Indoor Trainer"
            onConnect={onConnectControllable}
          />
        </Box>
      )}
      <Box pad="medium" align="center">
        <Text tag="h1" size="xxlarge" color="accent-1" uppercase margin="small">
          {id}
        </Text>
        <Text>
          <Tag value={`${duration}min`} />
          {!useDistanceScale && <Tag value={`${distance}m`} />}
        </Text>
        <Text tag="h3" size="medium" margin="small">
          Start the race when all riders are ready
        </Text>

        <Box pad={{ vertical: 'medium' }} direction="row" justify="center" wrap>
          {raceRiderList.map((p, index) => {
            if (!p) {
              return (
                <Card
                  key={`no-rider-${index}`}
                  border={{ color: 'dark-1', size: 'small' }}
                  margin="small"
                  width="small"
                  height="small"
                />
              );
            }

            const isMe = p.id === userId;
            const color = isMe ? 'accent-1' : 'dark-1';
            const callParticipant = findCallParticipant(callParticipants, p.id);
            const scaledDistance = getScaledDistance(distance, p.distanceScale);
            // const isActiveSpeaker =
            //   !!activeSpeaker && !!callParticipant
            //     ? activeSpeaker.session_id === callParticipant.session_id
            //     : false;

            return (
              <Card
                key={p.id}
                border={{ color }}
                // elevation={isActiveSpeaker ? 'medium' : 'small'}
                margin="small"
              >
                <Box style={{ minWidth: 200 }} gap="small">
                  <Box gap="small" direction="row" align="center">
                    <Box>
                      <Text size="small" weight="bold">
                        @{p.userName}
                      </Text>
                      <Text size="xlarge" weight="bold">
                        {p.displayName}
                      </Text>
                    </Box>
                  </Box>
                  {useDistanceScale && (
                    <Box align="start">
                      <Tag size="small" value={`${Math.round(scaledDistance)}m`} />
                    </Box>
                  )}
                  {hasAv && (
                    <Box>
                      {/* <Box direction="row" justify="between" align="center">
                    <Text size="large">Weight</Text>
                    <Text size="xlarge">{p.weight}kg</Text>
                  </Box> */}
                      <Box align="center">
                        {isMe ? (
                          <>
                            {localParticipant && (
                              <>
                                <ParticipantVideo
                                  id={localParticipant.session_id}
                                  audioEnabled={false}
                                  mirrorVideo
                                  videoEnabled
                                  width="small"
                                  height="small"
                                  round
                                />
                                <Box margin={{ vertical: 'small' }}>
                                  <AvControlsLocal />
                                </Box>
                                <Button
                                  label="Settings"
                                  secondary
                                  onClick={() => setShowDevicesConfig(true)}
                                  size="small"
                                />
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {callParticipant && (
                              <ParticipantVideo
                                id={callParticipant.session_id}
                                audioEnabled
                                videoEnabled
                                width="small"
                                height="small"
                                round
                              />
                            )}
                          </>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Card>
            );
          })}
        </Box>

        <Box align="center" gap="medium">
          <Box width="large">
            <Button
              primary
              label="Start Race"
              disabled={!hasEnoughParticipants || isStartingRace}
              onClick={() =>
                startRace({
                  spaceUUID: id,
                })
              }
              fill="horizontal"
              size="large"
            />
          </Box>
          <Box width="large">
            <Button label="Leave" onClick={leaveSpace} fill="horizontal" size="large" />
          </Box>
        </Box>

        {!!startRaceError && (
          <Box align="center" margin={{ top: 'medium' }}>
            <Text color="status-error">{startRaceError.message}</Text>
          </Box>
        )}

        <Modal
          isVisible={showDevicesConfig}
          onRequestClose={() => setShowDevicesConfig(false)}
          width="large"
        >
          <Box pad="medium">
            <SelectAvDevices />
            <Box align="center" margin={{ top: 'medium' }}>
              <Button onClick={() => setShowDevicesConfig(false)} label="Close" />
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};
