import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';
import _unique from 'lodash/uniq';

import { getTournamentRoundNumber } from '~/features/Tournaments/tournaments.utils';
import {
  Space as SpaceApi,
  UserState as UserStateApi,
  SpaceParticipantState as SpaceParticipantStateApi,
  SpaceDetails as SpaceDetailsApi,
  Tournament as TournamentApi,
  TournamentState,
} from '~/module/api/api.types';
import { Race, UserData, Tournament as TournamentDB } from '~/module/firebase';
import {
  Space,
  SpaceParticipant,
  User,
  SpaceParticipantState,
  SpaceDetails,
  RaceHistoryItem,
  RaceData,
  TournamentRaceRiderRank,
} from '~/store/slices';
import {
  RoundState,
  Tournament,
  TournamentDetail,
  TournamentRoundRiderResult,
  TournamentRoundSpace,
} from '~/store/slices/tournamentsSlice.types';

export const transformSpaceDetails = (details: SpaceDetailsApi): SpaceDetails => {
  return {
    state: details.status,
    distance: details.distance,
    duration: details.targetDuration,
    fieldSize: details.fieldSize,
    raceStart: details.raceStart,
    useDistanceScale: details.useDistanceScale,
  };
};

export const transformSpace = (space: SpaceApi): Space => {
  return {
    id: space.spaceUUID,
    currentParticipantCount: space.currentParticipantCount,
    details: transformSpaceDetails(space.details),
  };
};

export const transformSpaceParticipant = (participant: UserStateApi): SpaceParticipant => {
  return {
    id: participant.userUUID,
    userName: participant.userName,
    displayName: participant.displayName,
    weight: participant.state.raceState?.lockedWeight ?? participant.weight,
    distanceScale: participant.state.raceState?.lockedDistanceScale ?? participant.distanceScale,
    riderColour: participant.state.raceState?.riderColour ?? undefined,
  };
};

export const transformTournament = (tournament: TournamentApi): Tournament => {
  return {
    id: tournament.tournamentID,
    signups: tournament.signups,
    name: tournament.details.tournamentName,
    state: tournament.details.state,
    details: {
      hasConfirmationWindow: tournament.details.confirmPresence,
      confirmationWindowOpens: tournament.details.confirmPresenceStart,
      registrationCloseTime: tournament.details.registrationCloses,
      ...tournament.details,
    },
  };
};

export const transformFirestoreTournament = (tournament: TournamentDB): Tournament => {
  return {
    id: tournament.tournamentID,
    signups: tournament.signups?.length ?? 0,
    name: tournament.tournamentName,
    state: tournament.state,
    details: {
      startTime: tournament.startTime,
      registrationCloseTime: tournament.registrationCloses,
      hasConfirmationWindow: tournament.confirmPresence,
      confirmationWindowOpen: tournament.confirmationWindowOpen ?? false,
      confirmationWindowOpens: tournament.confirmPresenceStart ?? 0,
      registrationOpen: tournament.registrationOpen,
      useDistanceScale: tournament.useDistanceScale,
      finalRoundDuration: tournament.finalRoundDuration,
      breaksDurationsBetweenRoundsSeconds: tournament.breaksDurationBetweenRoundsSeconds,
    },
  };
};

function getRoundStateFromTournamentState(
  state: TournamentState,
  roundNumber: number,
  numRounds: number,
): RoundState {
  const stateStripped = state.replace(`Round${roundNumber}`, '');
  const roundNumberFromState = getTournamentRoundNumber({ tournamentState: state, numRounds });

  switch (stateStripped) {
    case 'complete':
    case 'pending':
    case 'racing':
      return stateStripped;
    default:
      if (roundNumberFromState > roundNumber) {
        return 'complete';
      }
      return 'pending';
  }
}

export const transformFirestoreTournamentDetail = (tournament: TournamentDB): TournamentDetail => {
  return {
    riderRanks: _orderBy(
      tournament.riderRanks
        ? Object.entries(tournament.riderRanks).map(([userUUID, rank]): TournamentRaceRiderRank => {
            return { id: userUUID, ...rank };
          })
        : [],
      'rank',
      'asc',
    ),
    rounds: _orderBy(
      tournament.rounds
        ? Object.values(tournament.rounds).map((roundInfo) => {
            const roundState = getRoundStateFromTournamentState(
              tournament.state,
              roundInfo.roundNumber,
              Object.values(tournament.rounds ?? {}).length,
            );

            const spaces: Record<string, TournamentRoundSpace> = {};
            const usersFinished = [
              ...(roundInfo.fastestFinishersList ?? []),
              ...(roundInfo.qualifiersList ?? []),
            ];
            const racesComplete = _unique(usersFinished.map((u) => u.spaceUUID));
            const roundQualifiers = _orderBy(roundInfo.qualifiersList, ['duration'], ['asc']);
            const roundFastestFinishers = _orderBy(
              roundInfo.fastestFinishersList,
              ['duration'],
              ['asc'],
            );
            const roundResults: TournamentRoundRiderResult[] = [];
            roundQualifiers.forEach((result) => {
              roundResults.push({ ...result, qualifier: true, fastestFinisher: false });
            });
            roundFastestFinishers.forEach((result, index) => {
              roundResults.push({
                ...result,
                qualifier: false,
                fastestFinisher: index < roundInfo.fastestFinishers,
              });
            });

            Object.entries(roundInfo.spaceAllocation ?? {}).forEach(([userUUID, spaceUUID]) => {
              if (!spaces[spaceUUID]) {
                spaces[spaceUUID] = {
                  spaceUUID,
                  state: racesComplete.includes(spaceUUID) ? 'complete' : 'incomplete',
                  users: [],
                };
              }
              const spaceState = spaces[spaceUUID];

              const userFinished = usersFinished.find((u) => u.userUUID === userUUID);
              // TODO - make sure riderRanks is present when tournament kicks off
              const userRiderRank = tournament.riderRanks?.[userUUID];
              const displayName = userRiderRank?.displayName ?? userUUID;
              const userName = userRiderRank?.userName ?? userUUID;

              if (roundState === 'pending' || spaceState.state === 'incomplete' || !userFinished) {
                spaceState.users.push({ id: userUUID, displayName, userName });
              } else {
                const qualifier = (roundInfo.qualifiersList ?? []).find(
                  (u) => u.userUUID === userUUID,
                );
                const fastestFinisher = roundFastestFinishers.find(
                  (u, index) => u.userUUID === userUUID && index < roundInfo.fastestFinishers,
                );

                spaceState.users.push({
                  id: userUUID,
                  displayName,
                  userName,
                  rank: userFinished.rank,
                  qualifier: !!qualifier,
                  fastestFinisher: !!fastestFinisher,
                });
              }
            });

            Object.keys(spaces).forEach((spaceUUID) => {
              spaces[spaceUUID].users = _orderBy(spaces[spaceUUID].users, ['rank'], ['asc']);
            });

            return {
              ...roundInfo,
              qualifiersList: roundQualifiers,
              fastestFinishersList: roundFastestFinishers,
              state: roundState,
              roundResults,
              spaces,
            };
          })
        : [],
      ['roundNumber', 'desc'],
    ),
  };
};

export const transformUser = (user: UserStateApi): User => {
  const participant = transformSpaceParticipant(user);
  return {
    userId: participant.id,
    // @ts-ignore
    cumulativePower: participant.cumulativePower || 0,
    ...participant,
  };
};

export const transformFirestoreUser = (user: UserData): User => {
  return {
    userId: user.UserUUID,
    userName: user.userName,
    weight: user.weight,
    displayName: user.displayName,
    powerRecords: user.powerRecords,
    cumulativePower: user.cumulativePower || 0,
  };
};

export const transformSpaceParticipantState = (
  participantState: SpaceParticipantStateApi,
): SpaceParticipantState => {
  return {
    ...participantState,
    lastUpdated: participantState.lastUpdate_ms,
  };
};

export const transformUpdateUserToApi = (user: Partial<User>): Partial<UserStateApi> => {
  const update: Partial<UserStateApi> = {};
  if (user.userName) {
    update.userName = user.userName;
  }
  if (user.displayName) {
    update.displayName = user.displayName;
  }
  if (user.weight) {
    const weight = Number(user.weight);
    if (!isNaN(weight)) {
      update.weight = weight;
    }
  }
  return update;
};

export const transformFirestoreRace = (race: Race): RaceData => {
  return {
    id: race.raceID,
    name: race.spaceName,
    raceStartTime: race.raceStart,
    numParticipants: race.numParticipants,
    duration: race.winningDuration,
    distance: race.distance,
    riderSummary: _orderBy(race.riderSummary, ['rank']),
  };
};

export const transformFirestoreUserRace = (race: Race, userId: string): RaceHistoryItem => {
  const summary = _find(race.riderSummary, ['riderUUID', userId])!;
  const raceData = transformFirestoreRace(race);
  return {
    ...raceData,
    riderSummary: {
      rank: summary.rank,
      raceComplete: summary.raceComplete,
      timeDelta: summary.timeDelta,
      distanceDelta: summary.distanceDelta,
    },
  };
};
