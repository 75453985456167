import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { doc, onSnapshot } from 'firebase/firestore';

import { raceHistoryActions } from '~/store/slices';
import { transformFirestoreRace } from '~/subscriptions/transforms';

import { firestore } from '../InitFirebase';
import { Race } from './firestore.types';

function raceDataListener(raceId: string, callback: (arg: Race) => void): UnsubcribeFn {
  const docRef = doc(firestore, `races/${raceId}`);
  const unsubscribe = onSnapshot(docRef, (snapshot) => {
    const data = snapshot.data() as Race;
    if (data) {
      callback(data);
    }
  });
  return unsubscribe;
}

export const useFirestoreRaceDataListener = (raceId: string) => {
  const dispatch = useDispatch();

  const onDataUpdated = useCallback(
    (data: Race) => {
      const race = transformFirestoreRace(data);
      dispatch(raceHistoryActions.onSetRaceData({ raceId, data: race }));
    },
    [dispatch, raceId],
  );

  useEffect(() => {
    if (raceId) {
      const unsubscribe = raceDataListener(raceId, onDataUpdated);
      return () => unsubscribe();
    }
  }, [raceId, onDataUpdated]);
};
