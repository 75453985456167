import { useEffect } from 'react';
import { CRITZ_SERVER_URL, IS_DEV_MODE } from '~/module/config';

import { WSService } from '../services';

let wsService: WSService;

// TODO - only send PING or whoami when we have an auth token

export const useWsApi = () => {
  if (!wsService) {
    wsService = new WSService(CRITZ_SERVER_URL);
  }

  useEffect(() => {
    if (IS_DEV_MODE) {
      // @ts-ignore
      window.__wsService = wsService;
    }
  }, []);

  return wsService
};

