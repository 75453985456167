import { useCallback } from 'react';

import { StartRaceRequestData, StartRaceResponseData } from '../api.types';

import { useWsApi } from './useWsApi';
import { useWsSyncRequest } from './useWsSyncRequest';

export const useWsRaceActions = () => {
  const ws = useWsApi();

  const postWattage = useCallback(
    (wattage: number) => {
      if (ws.isAuthenticated) {
        ws.sendPostRaceDataMessage('wattage', wattage);
      }
    },
    [ws],
  );

  const postCadence = useCallback(
    (cadence: number) => {
      if (ws.isAuthenticated) {
        ws.sendPostRaceDataMessage('cadence', cadence);
      }
    },
    [ws],
  );

  const postHeartRate = useCallback(
    (heartRate: number) => {
      if (ws.isAuthenticated) {
        ws.sendPostRaceDataMessage('heartrate', heartRate);
      }
    },
    [ws],
  );

  const {
    isLoading: isStartingRace,
    error: startRaceError,
    send: startRace,
  } = useWsSyncRequest<StartRaceRequestData, StartRaceResponseData>({ method: 'startRace' });

  return {
    postWattage,
    postCadence,
    postHeartRate,
    isStartingRace,
    startRaceError,
    startRace,
  };
};
