import { isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  RiderFinishedSummary,
  SpaceParticipantsState,
  spacesActions,
  spacesSelectors,
} from '../slices';
import { useClockOffset } from './useClockOffset';

export function useRaceState() {
  const dispatch = useDispatch();

  const clockOffset = useClockOffset();
  const activeSpace = useSelector(spacesSelectors.getActiveSpace);
  const raceStartTime = useSelector(spacesSelectors.getRaceStartTime);

  const spaceParticipants = useSelector(spacesSelectors.getRaceParticipants);
  const raceParticipantsState = useSelector(spacesSelectors.getRaceParticipantsState);
  const raceParticipantsMeasures = useSelector(spacesSelectors.getRaceParticipantsMeasures);

  const raceStartTimeWithOffset = !isNil(raceStartTime) ? raceStartTime - clockOffset : 0;

  const raceParticipants = useMemo(() => {
    if (activeSpace && spaceParticipants[activeSpace.id]) {
      return spaceParticipants[activeSpace.id].participants;
    }
    return {};
  }, [spaceParticipants, activeSpace]);

  const participantsState = useMemo((): SpaceParticipantsState => {
    const newParticipantsState: SpaceParticipantsState = {};
    const existingParticipantState = raceParticipantsState || {};
    Object.keys(existingParticipantState).forEach((userId) => {
      const state = existingParticipantState[userId];
      newParticipantsState[userId] = {
        ...state,
        lastUpdated: state.lastUpdated - clockOffset,
      };
    });
    return newParticipantsState;
  }, [raceParticipantsState, clockOffset]);

  const activeSpaceRidersFinishedSummary = activeSpace?.ridersFinishedSummary;

  const ridersFinishedSummary = useMemo(() => {
    const riderSummary: Record<string, RiderFinishedSummary> = {};
    if (activeSpaceRidersFinishedSummary) {
      for (const riderId in activeSpaceRidersFinishedSummary) {
        const thisRiderSummary = activeSpaceRidersFinishedSummary[riderId];
        riderSummary[riderId] = {
          ...thisRiderSummary,
          finishTime: thisRiderSummary.finishTime - clockOffset,
        };
      }
    }
    return riderSummary;
  }, [activeSpaceRidersFinishedSummary, clockOffset]);

  const onObserveRider = useCallback(
    (observeUserId: string) => {
      if (activeSpace?.id) {
        dispatch(spacesActions.onSetObservingUserId({ spaceId: activeSpace.id, observeUserId }));
      }
    },
    [dispatch, activeSpace?.id],
  );

  return {
    id: activeSpace?.id,
    observingUserId: activeSpace?.observingUserId,
    details: activeSpace?.details,
    raceEnded: activeSpace?.details.state === 'finished',
    raceEndRiderSummary: activeSpace?.raceEndRiderSummary,
    ridersFinishedSummary,
    raceDuration: activeSpace?.raceDuration,
    raceStartTime: raceStartTimeWithOffset,
    participants: raceParticipants,
    participantsMeasures: raceParticipantsMeasures,
    participantsState,
    onObserveRider,
  };
}
