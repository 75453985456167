import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';

export type Device = {
  id: string;
  name: string;
  lastPaired: number;
};

export type DevicesState = {
  pairedControllable: Device | null;
  pairedHRM: Device | null;
};

const initialState: DevicesState = {
  pairedControllable: null,
  pairedHRM: null,
};

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    onControllablePaired(state, action: PayloadAction<{ device: Device }>) {
      state.pairedControllable = action.payload.device;
    },
    onHRMPaired(state, action: PayloadAction<{ device: Device }>) {
      state.pairedHRM = action.payload.device;
    },
  },
});

export const devicesReducer = devicesSlice.reducer;
export const devicesActions = devicesSlice.actions;
export const devicesSelectors = {
  getPairedControllable: (state: RootState) => state.devices.pairedControllable,
  getPairedHRM: (state: RootState) => state.devices.pairedHRM,
};
