import { useDaily, useLocalParticipant } from '@daily-co/daily-react-hooks';
import { FC } from 'react';
import { Box, Icon } from '~/components/common';

export const AvControls: FC<{
  audioEnabled: boolean;
  videoEnabled: boolean;
  onToggleAudio?: (enabled: boolean) => void;
  onToggleVideo?: (enabled: boolean) => void;
}> = ({ audioEnabled, videoEnabled, onToggleAudio, onToggleVideo }) => {
  return (
    <Box direction="row" gap="xsmall">
      <Box
        onClick={onToggleAudio ? () => onToggleAudio(!audioEnabled) : undefined}
        round
        pad="small"
        focusIndicator={false}
        hoverIndicator={{ elevation: 'small' }}
      >
        <Icon iconName="microphone" color={audioEnabled ? 'accent-1' : 'text-weak'} />
      </Box>
      <Box
        onClick={onToggleVideo ? () => onToggleVideo(!videoEnabled) : undefined}
        round
        pad="small"
        focusIndicator={false}
        hoverIndicator={{ elevation: 'small' }}
      >
        <Icon iconName="camera" color={videoEnabled ? 'accent-1' : 'text-weak'} />
      </Box>
    </Box>
  );
};

export const AvControlsLocal = () => {
  const callObject = useDaily();
  const localParticipant = useLocalParticipant();
  if (!localParticipant || !callObject) return null;
  return (
    <AvControls
      audioEnabled={localParticipant.audio}
      videoEnabled={localParticipant.video}
      onToggleAudio={(enabled: boolean) =>
        callObject.updateParticipant('local', {
          setAudio: enabled,
        })
      }
      onToggleVideo={(enabled: boolean) =>
        callObject.updateParticipant('local', {
          setVideo: enabled,
        })
      }
    />
  );
};
