import React, { FC } from 'react';

import { AnchorLink, Box, Text } from '~/components/common';
import { MetricDisplay } from '~/components/MetricDisplay';
import { formatPowerDisplay } from '~/module/physics';
import { absoluteUrls } from '~/navigation/Navigation.paths';
import { PowerRecords } from '~/store/slices';

type UserSummaryProps = {
  powerRecords?: PowerRecords;
  numRaces: number;
  numWins: number;
  wattHours: number;
};

export const UserSummary: FC<UserSummaryProps> = ({ powerRecords, numRaces, wattHours }) => {
  const powerRecord5s = powerRecords ? powerRecords[5] : null;
  const LinkComponent = numRaces > 0 ? AnchorLink : Box;
  return (
    <Box align="center" gap="medium">
      <Box>
        <Text textAlign="center">Career Profile</Text>
      </Box>
      <Box direction="row" align="start" justify="around" width="full">
        <LinkComponent to={absoluteUrls.userRaceHistory}>
          <MetricDisplay label="Races" value={numRaces} />
        </LinkComponent>
        <MetricDisplay label="Watt Hours" value={wattHours.toFixed(0)} />
        <LinkComponent to={absoluteUrls.userPower}>
          <MetricDisplay
            label={`5s power`}
            value={powerRecord5s ? formatPowerDisplay(powerRecord5s.value) : 0}
            dateRecorded={powerRecord5s?.startTime}
          />
        </LinkComponent>
      </Box>
    </Box>
  );
};
