import { FC } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
  ScaleName,
} from 'victory';

import { useVictoryChartsTheme } from '~/theme/victory';
import { Box, Spinner } from '~/components/common';
import { Datum, FormatTickFn } from './Graph.types';

export type LineChartProps = {
  height?: number;
  width?: number;
  watermark?: number;
  primaryColor?: string;
  data: Maybe<Array<Datum>>;
  expectedDomains?: {
    x: [number, number];
    y: [number, number];
  };
  isLoading?: boolean;
  scaleX?: ScaleName;
  scaleY?: ScaleName;
  tickValuesX?: number[];
  formatTickX?: FormatTickFn;
  tickValuesY?: number[];
  formatTickY?: FormatTickFn;
};

export const LineChart: FC<LineChartProps> = ({
  data,
  watermark,
  height = 250,
  width,
  primaryColor,
  expectedDomains,
  isLoading = false,
  scaleX,
  scaleY,
  tickValuesX,
  formatTickX,
  tickValuesY,
  formatTickY,
  ...props
}) => {
  const theme = useVictoryChartsTheme({ primaryColor });
  const { width: responsiveWidth, ref } = useResizeDetector();
  const specifiedWidth = width || responsiveWidth;

  return (
    <div style={{ height, position: 'relative', zIndex: 1 }}>
      <div ref={ref}>
        {!!specifiedWidth && (
          <VictoryChart
            height={height}
            width={specifiedWidth}
            domainPadding={{ x: 0, y: 16 }}
            padding={{
              top: 16,
              bottom: !!formatTickY ? 32 : 16,
              left: 48,
              right: !!formatTickY ? 16 : 0,
            }}
            scale={{ x: scaleX || 'linear', y: scaleY || 'linear' }}
            theme={theme}
            containerComponent={<VictoryVoronoiContainer />}
          >
            <VictoryAxis
              dependentAxis
              crossAxis={false}
              tickValues={tickValuesY}
              tickFormat={formatTickY}
              style={
                !data
                  ? {
                      tickLabels: {
                        fill: 'transparent',
                      },
                    }
                  : {}
              }
            />
            <VictoryAxis
              standalone
              tickFormat={formatTickX}
              tickValues={tickValuesX}
              style={{
                grid: {
                  stroke: 'none',
                },
                ticks: {
                  stroke: 'none',
                },
              }}
              crossAxis={false}
            />
            <VictoryLine
              data={data || []}
              labelComponent={<VictoryTooltip />}
              domain={
                data && data.length > 0
                  ? { x: [data[0].x, data[data.length - 1].x] }
                  : expectedDomains
              }
              labels={(data) => data.label}
            />
          </VictoryChart>
        )}
        {isLoading && (
          <Box>
            <Spinner />
          </Box>
        )}
      </div>
    </div>
  );
};
