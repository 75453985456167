import { Tag } from 'grommet';
import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Box, Spinner } from '~/components/common';
import { SepPowerRecords } from '~/components/data-viz/SepPowerRecords';
import { getMeasurementsAndRecords } from '~/components/data-viz/SepPowerRecords/utils';
import { MetricDisplay } from '~/components/MetricDisplay';
import { useFirestoreUserRaceHistoryItemListener } from '~/module/firebase';
import { useFirestoreRaceDataListener } from '~/module/firebase/firestore/useFirestoreRaceData';
import { msToTime, timeToClockString } from '~/module/utils';
import { usePowerStats } from '~/store/hooks';
import { raceHistorySelectors } from '~/store/slices';

const useUserRaceHistoryDetail = (raceId: string) => {
  useFirestoreRaceDataListener(raceId);
  useFirestoreUserRaceHistoryItemListener(raceId);

  const { powerRecords } = usePowerStats();

  const raceData = useSelector(raceHistorySelectors.getRaceData(raceId));
  const raceHistoryData = useSelector(raceHistorySelectors.getUserRaceHistoryDetail(raceId));

  return {
    raceData,
    raceHistoryData,
    powerRecords,
  };
};

export const UserRaceHistoryDetail: FC<{ raceId: string }> = ({ raceId }) => {
  const { raceData, raceHistoryData, powerRecords } = useUserRaceHistoryDetail(raceId);

  const measuresData = useMemo(() => {
    if (raceHistoryData?.measures) {
      const [measures, foundRecords] = getMeasurementsAndRecords(
        raceId,
        raceHistoryData?.measures,
        powerRecords,
      );
      return {
        measures,
        foundRecords,
      };
    }
    return null;
  }, [raceId, raceHistoryData?.measures, powerRecords]);

  if (!raceHistoryData || !raceData) {
    return (
      <Box justify="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box pad={{ vertical: 'medium' }} gap="medium">
      <Box direction="row" justify="around" gap="small">
        <Tag value={`${raceData.distance}m`} />
      </Box>
      {!!raceHistoryData.performance && (
        <Box direction="row" gap="medium" justify="around" wrap>
          <MetricDisplay
            label="Normalised Power"
            value={`${Math.floor(raceHistoryData.performance.normPower)}W`}
          />
          <MetricDisplay
            label="Average Power"
            value={`${Math.floor(raceHistoryData.performance.avgPower)}W`}
          />
          <MetricDisplay
            label="Average Speed"
            value={`${Math.floor(raceHistoryData.performance.avgSpeed * 3.6)}kph`}
          />
          <MetricDisplay
            label="Watt Hours"
            value={`${Math.floor(raceHistoryData.performance.wattHours)}`}
          />
          <MetricDisplay label="Distance" value={`${raceData.distance}m`} />
          <MetricDisplay
            label="Duration"
            value={timeToClockString(msToTime(raceHistoryData.performance.duration), {
              minutes: true,
              seconds: true,
            })}
          />
        </Box>
      )}
      <Box gap="medium" direction="row">
        {/* <Box flexNum={1}>
          {raceData.riderSummary.map(rider => {
            return (
              <Box>{rider.rank} {rider.</Box>
            )
          })}
        </Box> */}
        <Box flexNum={2} gap="medium">
          {!!measuresData && (
            <Box>
              <SepPowerRecords {...measuresData} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
