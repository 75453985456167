import styled from 'styled-components';
import { TableRow } from 'grommet';
import { normalizeColor } from 'grommet/utils';

export const StyledTableRow = styled(TableRow)`
  cursor: pointer;
  &:hover {
    background-color: ${(props) => normalizeColor('accent-2', props.theme)};
  }
`;
