import { useNavigate } from 'react-router-dom';

import { CreateSpace } from '~/features/Spaces/components/CreateSpace';
import { SceneComponent } from '~/navigation/Navigation.types';
import { BreadCrumbs } from '../layout/Content';

import { Scene } from '../layout/Scene';
import { getPathToSpace } from '../Navigation.paths';

export const CreateSpaceScene: SceneComponent<{}> = () => {
  const navigate = useNavigate();
  return (
    <Scene>
      <BreadCrumbs crumbs={[{ label: 'Create Race', active: true }]} />
      <CreateSpace onNavToSpace={(spaceId: string) => navigate(getPathToSpace({ spaceId }))} />
    </Scene>
  );
};
