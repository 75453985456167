import { VictoryTheme, VictoryThemeDefinition } from 'victory';

import _merge from 'lodash/merge';
import { Theme } from '../theme';
import { UIDisplayMode } from '../theme.types';

export type ChartConfig = {
  displayMode: UIDisplayMode;
  primaryColor?: string;
};

export const getVictoryChartTheme = (
  theme: Theme,
  config: ChartConfig = {
    displayMode: 'light',
  },
): VictoryThemeDefinition => {
  const overrides: VictoryThemeDefinition = {
    // area: {},
    axis: {
      style: {
        axis: {
          stroke: theme.charts[config.displayMode].tickColor,
          strokeWidth: 1,
          opacity: 1,
        },
        grid: {
          stroke: theme.charts[config.displayMode].tickColor,
          opacity: 1,
          strokeWidth: 1,
        },
        tickLabels: {
          fill: theme.charts[config.displayMode].tickLabelColor,
          fontFamily: theme.global?.font?.family,
          fontSize: 12,
          padding: 5,
        },
      },
    },
    // dependentAxis: {},
    // independentAxis: {},
    // polarDependentAxis: {},
    // polarIndependentAxis: {},
    // bar: {},
    // candlestick: {},
    // chart: {},
    // errorbar: {},
    // group: {},
    // legend: {},
    line: {
      style: {
        data: {
          stroke: config.primaryColor,
          strokeWidth: 3,
        },
        labels: {
          fill: theme.charts[config.displayMode].tooltipTextColor,
          fontFamily: theme.global?.font?.family,
          fontSize: 12,
        },
      },
    },
    // pie: {},
    // scatter: {},
    // stack: {},
    tooltip: {
      cornerRadius: 4,
      flyoutStyle: {
        fill: theme.charts[config.displayMode].tooltipBackgroundColor,
        stroke: theme.charts[config.displayMode].tooltipBorderColor,
        strokeWidth: 2,
      },
      style: {
        fill: theme.charts[config.displayMode].tooltipTextColor,
        fontSize: 14,
        fontFamily: theme.global?.font?.family,
        fontWeight: 'bold',
      },
    },
    // voronoi: {},
  };

  return _merge({}, VictoryTheme.grayscale, overrides);
};
