import { useSelector } from 'react-redux';

import { raceHistorySelectors } from '../slices';

export const useRaceHistory = () => {
  const userRaceHistory = useSelector(raceHistorySelectors.getUserRaceHistory);
  return {
    userRaceHistory,
  };
};
