import { forwardRef } from 'react';
import * as THREE from 'three';

type RiderProps = {
  unitRiderSize: number;
  ballColor: THREE.Color;
  emissiveColor: THREE.Color;
  startPosition: number;
};

export const Rider = forwardRef<THREE.Mesh, RiderProps>((props, ref) => {
  return (
    <mesh ref={ref} visible position={[props.startPosition, 1 / 25, 0]}>
      <sphereGeometry args={[props.unitRiderSize, 32, 16]} />
      <meshLambertMaterial
        color={props.ballColor}
        emissive={props.emissiveColor}
        emissiveIntensity={0.25}
      />
    </mesh>
  );
});
