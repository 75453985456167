import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import _get from 'lodash/get';

import { spacesActions } from '~/store/slices';
import {
  CreateSpaceRequestData,
  CreateSpaceResponseData,
  JoinSpaceRequestData,
  JoinSpaceResponseData,
  LeaveSpaceRequestData,
  LeaveSpaceResponseData,
  ObserveSpaceRequestData,
  ObserveSpaceResponseData,
  StopObservingSpaceRequestData,
  StopObservingSpaceResponseData,
} from '~/module/api/api.types';

import { ApiError, useWsSyncRequest } from './useWsSyncRequest';
import { transformSpaceDetails } from '~/subscriptions/transforms';
import { useUser } from '~/store/hooks';

interface UseSpaceActions {
  // join
  onJoinSpace: (spaceUuid: string, displayName?: string) => Promise<JoinSpaceResponseData>;
  isJoiningSpace: boolean;
  joinSpaceError: Maybe<ApiError>;
  // leave
  onLeaveSpace: (spaceUuid: string) => Promise<LeaveSpaceResponseData>;
  isLeavingSpace: boolean;
  leaveSpaceError: Maybe<ApiError>;
  // create
  onCreateSpace: (options: CreateSpaceRequestData) => Promise<CreateSpaceResponseData>;
  isCreatingSpace: boolean;
  createSpaceError: Maybe<ApiError>;
  // observe
  onObserveSpace: (
    spaceUuid: string,
    observingUserId?: string,
  ) => Promise<ObserveSpaceResponseData>;
  isPendingObservingSpace: boolean;
  observeSpaceError: Maybe<ApiError>;
  // stopObserving
  onStopObservingSpace: (spaceUuid: string) => Promise<LeaveSpaceResponseData>;
  isStoppingObservingSpace: boolean;
  stopObservingSpaceError: Maybe<ApiError>;
}

export const useWsSpaceActions = (): UseSpaceActions => {
  const dispatch = useDispatch();
  const { userId } = useUser();

  const {
    isLoading: isJoiningSpace,
    error: joinSpaceError,
    send: joinSpace,
  } = useWsSyncRequest<JoinSpaceRequestData, JoinSpaceResponseData>({ method: 'joinSpace' });

  const {
    isLoading: isLeavingSpace,
    error: leaveSpaceError,
    send: leaveSpace,
  } = useWsSyncRequest<LeaveSpaceRequestData, LeaveSpaceResponseData>({ method: 'leaveSpace' });

  const {
    isLoading: isCreatingSpace,
    error: createSpaceError,
    send: createSpace,
  } = useWsSyncRequest<CreateSpaceRequestData, CreateSpaceResponseData>({ method: 'createSpace' });

  const {
    isLoading: isPendingObservingSpace,
    error: observeSpaceError,
    send: observeSpace,
  } = useWsSyncRequest<ObserveSpaceRequestData, ObserveSpaceResponseData>({
    method: 'observeSpace',
  });

  const {
    isLoading: isStoppingObservingSpace,
    error: stopObservingSpaceError,
    send: stopObservingSpace,
  } = useWsSyncRequest<StopObservingSpaceRequestData, StopObservingSpaceResponseData>({
    method: 'stopObservingSpace',
  });

  const onJoinSpace = useCallback(
    async (spaceUuid: string, displayName?: string) => {
      const response = await joinSpace({
        spaceUUID: spaceUuid,
        // displayName,
      });
      if (
        (response && response.data && response.data.valid) ||
        response?.data?.invalid_reason === 'ALREADY_IN_ROOM'
      ) {
        dispatch(
          spacesActions.onSetActiveSpace({
            id: spaceUuid,
            details: transformSpaceDetails(response.data.details),
            observingUserId: userId,
          }),
        );
        return response.data;
      } else {
        throw new Error(
          `joinSpace - Invalid response [${_get(
            response,
            'data.invalid_reason',
            'unknown error',
          )}]`,
        );
      }
    },
    [dispatch, joinSpace, userId],
  );

  const onLeaveSpace = useCallback(
    async (spaceUuid: string) => {
      const response = await leaveSpace({
        spaceUUID: spaceUuid,
      });
      if (response && response.data && response.data.valid) {
        return response.data;
      } else {
        throw new Error(
          `leaveSpace - Invalid response [${_get(response, 'data.invalid_reason', 'unknown err')}]`,
        );
      }
    },
    [leaveSpace],
  );

  const onObserveSpace = useCallback(
    async (spaceUuid: string, observingUserId?: string) => {
      const response = await observeSpace({
        spaceUUID: spaceUuid,
      });
      if (
        (response && response.data && response.data.valid) ||
        response?.data?.invalid_reason === 'ALREADY_IN_ROOM'
      ) {
        const payload = {
          id: spaceUuid,
          details: transformSpaceDetails(response.data.details),
          observingUserId,
        };
        if (observingUserId) {
          payload.observingUserId = observingUserId;
        }
        dispatch(spacesActions.onSetActiveSpace(payload));
        return response.data;
      } else {
        throw new Error(
          `observeSpace - Invalid response [${_get(
            response,
            'data.invalid_reason',
            'unknown error',
          )}]`,
        );
      }
    },
    [dispatch, observeSpace],
  );

  const onStopObservingSpace = useCallback(
    async (spaceUuid: string) => {
      const response = await stopObservingSpace({
        spaceUUID: spaceUuid,
      });
      if (response && response.data && response.data.valid) {
        return response.data;
      } else {
        throw new Error(
          `stopObservingSpace - Invalid response [${_get(
            response,
            'data.invalid_reason',
            'unknown err',
          )}]`,
        );
      }
    },
    [stopObservingSpace],
  );

  const onCreateSpace = useCallback(
    async (options: CreateSpaceRequestData) => {
      const response = await createSpace(options);
      if (response && response.data && response.data.spaceUUID) {
        return response.data;
      } else {
        throw new Error(
          `createSpace - Invalid response [${_get(
            response,
            'data.invalid_reason',
            'unknown err',
          )}]`,
        );
      }
    },
    [createSpace],
  );

  return {
    onJoinSpace,
    isJoiningSpace,
    joinSpaceError,
    onLeaveSpace,
    isLeavingSpace,
    leaveSpaceError,
    onCreateSpace,
    isCreatingSpace,
    createSpaceError,
    onObserveSpace,
    isPendingObservingSpace,
    observeSpaceError,
    onStopObservingSpace,
    isStoppingObservingSpace,
    stopObservingSpaceError,
  };
};
