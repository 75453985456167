import { useSelector } from 'react-redux';

import { userSelectors } from '~/store/slices';

export const useUser = () => {
  const user = useSelector(userSelectors.getUser);
  const userDistanceScale = useSelector(userSelectors.getUserDistanceScale);
  const currentActiveRace = useSelector(userSelectors.getCurrentActiveRace);
  const userIdToken = useSelector(userSelectors.getUserIdToken);
  const userId = useSelector(userSelectors.getUserId);

  return {
    user,
    userDistanceScale,
    currentActiveRace,
    userId,
    userIdToken,
  };
};
