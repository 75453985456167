import { deepMerge, normalizeColor } from 'grommet/utils';
import { grommet, ThemeType } from 'grommet';
import { rgba } from 'polished';

export type ChartTheme = {
  tickLabelColor: string;
  tickColor: string;
  tooltipBackgroundColor: string;
  tooltipBorderColor: string;
  tooltipTextColor: string;
};

export type Theme = ThemeType & {
  // our base Theme extends the Grommet one
  charts: {
    light: ChartTheme;
    dark: ChartTheme;
  };
};

export const PrimaryColor = {
  light: 'brand',
  dark: 'accent-1',
};

const grommetOverrides: ThemeType = {
  global: {
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
    colors: {
      'neutral-1': '#D22779',
      'accent-1': '#FF10F0',
      brand: '#00A1E4',
      'status-ok': '#6FFFB0',
    },
    elevation: {
      light: {
        xsmall: `0px 0px 4px 2px ${rgba('#FF10F0', 0.7)}`,
        small: `0px 0px 8px 3px ${rgba('#FF10F0', 0.7)}`,
        medium: `0px 0px 12px 5px ${rgba('#FF10F0', 0.7)}`,
        large: `0px 0px 16px 16px ${rgba('#FF10F0', 0.7)}`,
        xlarge: `0px 0px 24px 24px ${rgba('#FF10F0', 0.7)}`,
      },
      dark: {
        xsmall: `0px 0px 4px 2px ${rgba('#FF10F0', 0.7)}`,
        small: `0px 0px 8px 3px ${rgba('#FF10F0', 0.7)}`,
        medium: `0px 0px 12px 5px ${rgba('#FF10F0', 0.7)}`,
        large: `0px 0px 16px 16px ${rgba('#FF10F0', 0.7)}`,
        xlarge: `0px 0px 24px 24px ${rgba('#FF10F0', 0.7)}`,
      },
    },
  },
  button: {
    extend: (props) => {
      const buttonColor = normalizeColor(
        props.color || props.colorValue || props.theme.button?.border?.color || 'control',
        props.theme,
      );
      return `
        text-transform: uppercase;
        transition: all 300ms linear;
        font-size: 0.8em;
        ${
          !props.disabled &&
          `
          ${
            props.primary
              ? `
            &:hover {
              box-shadow: ${buttonColor} 0px 0px 31px 0px;
            }
          `
              : `
            &:hover {
              box-shadow: ${buttonColor} 0px 0px 16px 0px;
            }
          `
          }
        `
        }
      `;
    },
  },
  tab: {
    margin: {
      top: 'none',
      bottom: 'medium',
      left: 'none',
      right: 'medium',
    },
    active: {
      color: { dark: 'accent-1', light: 'brand' },
    },
    color: 'text',
    border: {
      color: 'text',
      active: {
        color: { dark: 'accent-1', light: 'brand' },
      },
    },
  },
  formField: {
    round: 'small',
    label: {
      size: 'small',
      margin: {
        left: 'none',
      },
    },
    border: {
      side: 'all',
      color: 'dark-3',
    },
    focus: {
      border: {
        color: 'neutral-1',
      },
    },
  },
};

const customTheme = deepMerge(grommet, grommetOverrides);

export const theme: Theme = {
  ...customTheme,
  charts: {
    light: {
      tickLabelColor: 'grey',
      tickColor: 'lightgrey',
      tooltipBackgroundColor: normalizeColor('background-contrast', customTheme),
      tooltipBorderColor: normalizeColor('neutral-1', customTheme),
      tooltipTextColor: 'grey',
    },
    dark: {
      tickLabelColor: 'grey',
      tickColor: 'lightgrey',
      tooltipBackgroundColor: normalizeColor('background-contrast', customTheme),
      tooltipBorderColor: normalizeColor('neutral-1', customTheme),
      tooltipTextColor: '#fff',
    },
  },
};
