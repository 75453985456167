import { forwardRef, useEffect, useRef } from 'react';
import { useLoader, extend, MeshProps, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';

type TextProps = {
  text: string;
  size?: number;
  centered?: boolean;
} & MeshProps;

function useForwardedRef<T>(ref: any) {
  const innerRef = useRef<T>(null);
  useEffect(() => {
    if (!ref) return;
    if (typeof ref === 'function') {
      ref(innerRef.current);
    } else {
      ref.current = innerRef.current;
    }
  });
  return innerRef;
}

export const Text = forwardRef<THREE.Mesh, TextProps>(
  ({ text, centered, size = 1, ...props }, ref) => {
    extend({ TextGeometry });
    const font = useLoader(FontLoader, '/font/roboto-bold.json');

    const textRef = useForwardedRef<THREE.Mesh>(ref);
    const hasTranslated = useRef(false);

    function centerText() {
      const textMesh = textRef.current;
      if (textMesh && !hasTranslated.current) {
        textMesh.geometry.computeBoundingBox();
        if (textMesh.geometry.boundingBox && textMesh.visible) {
          const size = textMesh.geometry.boundingBox.max.x - textMesh.geometry.boundingBox.min.x;
          textMesh.translateX(-size / 2);
          hasTranslated.current = true;
        }
      }
    }

    useFrame(() => {
      if (centered) {
        centerText();
      }
    });

    return (
      <mesh ref={textRef} {...props}>
        <textGeometry args={[text, { size: size * 0.07, font, height: size * 0.05 }]} />
        <meshBasicMaterial attach="material" color={'gold'} />
      </mesh>
    );
  },
);
