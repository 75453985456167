export function getLongStringDuration(duration: string | number): string {
  const durationNumber = Number(duration);
  if (durationNumber % 60 === 0) {
    // minute
    const minute = Math.round(durationNumber / 60);
    if (minute === 1) {
      return String(minute) + ' minute';
    } else {
      return String(minute) + ' minutes';
    }
  } else {
    return duration + ' seconds';
  }
}

export function getStringDuration(duration: string | number): string {
  const durationNumber = Number(duration);
  if (durationNumber % 60 === 0) {
    // minute
    const minute = Math.round(durationNumber / 60);
    return String(minute) + 'm';
  } else {
    return duration + 's';
  }
}

export function powerForDuration(
  alpha: number,
  beta: number,
  gamma: number,
  duration: number,
): number {
  const logDuration = Math.log10(duration);
  const exponent = logDuration * logDuration * alpha + logDuration * beta + gamma;
  return Math.round(10 ** exponent);
}

// generating a continuous color scale for wattage values relative to max
// https://rdrr.io/cran/wesanderson/src/R/colors.R
// Zissou1 = c("#3B9AB2", "#78B7C5", "#EBCC2A", "#E1AF00", "#F21A00"),
// define it on the range halfMeanPower to maxPower
const colours = [
  [59, 154, 178],
  [120, 183, 197],
  [235, 204, 42],
  [225, 175, 0],
  [242, 26, 0],
];

function interpolateColours(
  first: number[],
  second: number[],
  distance: number,
): [number, number, number] {
  return [
    Math.round(first[0] * (1 - distance) + second[0] * distance),
    Math.round(first[1] * (1 - distance) + second[1] * distance),
    Math.round(first[2] * (1 - distance) + second[2] * distance),
  ];
}
export function rawNumberToColour(
  x: number,
  alpha: number,
  upperBound: number,
  lowerBound: number,
): string {
  // take a number x, for our wattage
  // work out where that fits in our scale 0...1
  const position = (x - lowerBound) / (upperBound - lowerBound);
  const startColour = Math.floor(position * (colours.length - 1));
  const firstColour = colours[startColour];
  const secondColour = colours[Math.min(startColour + 1, colours.length - 1)];
  const [r, g, b] = interpolateColours(
    firstColour,
    secondColour,
    (position * (colours.length - 1)) % 1,
  );
  return `rgba(${r},${g},${b},${alpha})`;
}
