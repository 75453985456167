import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import _sortBy from 'lodash/sortBy';

import { Box } from '~/components/common';
import { RootState } from '~/store/rootReducer';
import { useConnectedDevices } from '~/module/connectedDevices';
import { useActiveSpace, useDevSwitches, useUser } from '~/store/hooks';
import { getEstimatedTimeRemaining, getScaledDistance } from '~/module/physics';
import { useCallData } from '~/features/AudioVideo/hooks';

import { ThreeRaceScene } from '../ThreeRace';
import { useRaceTime, useRaceRiders } from '../../hooks';
import { RaceHUD } from './RaceHUD';
import { HudParticipantVideo } from './components';
import { findCallParticipant } from '~/features/AudioVideo/AudioVideo.utils';
import { CountdownClock } from './components/CountdownClock';

const distanceMarkerIntervals = 1000;

export const ActiveRace: FC<{ hasAv: boolean; isObserving?: boolean }> = ({
  hasAv,
  isObserving = false,
}) => {
  const { userId } = useUser();
  const { raceStartTime, raceHasStarted, raceStartingSoon, raceClockMs } = useRaceTime();
  const { numRiders, observer, otherRiders, ridersInOrder, onObserveRider } = useRaceRiders();
  const { power, heartRate, cadence } = useConnectedDevices();
  const { activeSpace } = useActiveSpace();
  const totalDistance = useSelector(
    (state: RootState) => state.spaces.activeSpace?.details.distance || 0,
  );
  const { enableThreePerf, enableThreePostProcessing } = useDevSwitches();
  const { localParticipant, callParticipants } = useCallData();
  const [timeRemainingMs, setTimeRemainingMs] = useState<number | undefined>();

  const useDistanceScale = activeSpace?.details.useDistanceScale ?? true;
  const scaledTotalDistance =
    observer && useDistanceScale
      ? getScaledDistance(totalDistance, observer.distanceScale)
      : totalDistance;
  const showStartingClock = raceHasStarted ? raceClockMs <= 2000 : raceStartingSoon;

  const observerRef = useRef(observer);
  observerRef.current = observer;
  const raceClockMsRef = useRef(raceClockMs);
  raceClockMsRef.current = raceClockMs;

  const hasObserver = !!observer;
  const riderInLead = ridersInOrder[0]?.id;

  const participants = useMemo(() => {
    const arr = _sortBy(Object.values(otherRiders), ['userName']);
    if (observer) arr.unshift(observer);
    return arr;
  }, [observer, otherRiders]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (observerRef.current) {
        setTimeRemainingMs(
          getEstimatedTimeRemaining({
            totalDistance: scaledTotalDistance,
            distanceCovered: observerRef.current.scaledDistanceCovered,
            velocity: observerRef.current.scaledVelocity,
            timeElapsedMs: raceClockMsRef.current,
          }),
        );
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [scaledTotalDistance]);

  useEffect(() => {
    if (isObserving && !hasObserver) {
      if (riderInLead) {
        onObserveRider(riderInLead);
      }
    }
  }, [isObserving, hasObserver, riderInLead, onObserveRider]);

  return (
    <Box>
      <Box
        style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: '#000' }}
      >
        <ThreeRaceScene
          raceHasStarted={raceHasStarted}
          totalDistance={scaledTotalDistance}
          distanceMarkerIntervals={distanceMarkerIntervals}
          observer={observer}
          otherRiders={otherRiders}
          enableThreePerf={enableThreePerf}
          enableThreePostProcessing={enableThreePostProcessing}
        />
        <Box style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}>
          <RaceHUD
            observerId={observer?.id}
            hudColor={observer?.assignedColour ? `#${observer?.assignedColour}` : undefined}
            numRiders={numRiders}
            raceClockMs={raceClockMs}
            raceHasStarted={raceHasStarted}
            power={(observer?.id === userId ? power : observer?.wattage) ?? 0}
            heartRate={(observer?.id === userId ? heartRate : observer?.heartRate) ?? 0}
            cadence={observer?.id === userId ? cadence : undefined}
            totalDistance={scaledTotalDistance}
            estTimeRemainingMs={timeRemainingMs}
            scaledVelocity={observer?.scaledVelocity || 0}
            positionNumber={observer?.positionNumber || numRiders}
            percentageComplete={observer?.percentageComplete || 0}
            ridersSummary={ridersInOrder}
            distanceMarkerIntervals={distanceMarkerIntervals}
            onObserveRider={onObserveRider}
          />

          {hasAv && (
            <Box style={{ position: 'fixed', left: 0, right: 0, bottom: 25 }} align="center">
              <Box align="center" justify="center" direction="row" gap="small" wrap width="50%">
                {participants.map((p) => {
                  const isMe = p.id === userId;
                  const callParticipant =
                    (isMe ? localParticipant : findCallParticipant(callParticipants, p.id)) ?? null;
                  return (
                    <HudParticipantVideo
                      key={p.id}
                      hasAv={!!callParticipant}
                      assignedColour={p.assignedColour}
                      id={callParticipant?.session_id || ''}
                      videoEnabled
                      audioEnabled={!isMe}
                      mirrorVideo={isMe}
                    />
                  );
                })}
              </Box>
            </Box>
          )}

          {showStartingClock && (
            <CountdownClock
              raceHasStarted={raceHasStarted}
              raceStartingSoon={raceStartingSoon}
              raceDurationMs={raceClockMs}
              raceStartTime={raceStartTime}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
