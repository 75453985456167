import { format, formatISO, parse } from 'date-fns';

import { reportError } from '~/module/logging';

export const msToIsoString = (date: number): string => {
  return formatISO(date);
};

export const dateToIsoString = (date: Date): string => {
  return formatISO(date, {
    format: 'extended',
    representation: 'complete',
  });
};

export const isoStringToDate = (dateString: string): Date => {
  return new Date(dateString);
};

// e.g. 2021-09-04+01:00
export const dayStringToDate = (dayString: string): Date => {
  const now = new Date();
  if (dayString.length > 'yyyy-MM-dd'.length) {
    return parse(dayString, 'yyyy-MM-ddXXX', now);
  } else {
    const localOffset = format(now, 'XXX');
    return parse(dayString + localOffset, 'yyyy-MM-ddXXX', now);
  }
};

export const dateToDayString = (date: Date): string => {
  const isoString = dateToIsoString(date);
  return formatDate(isoString, 'yyyy-MM-dd');
};

export const formatDate = (dateValue: string | number, formatString: string): string => {
  try {
    if (typeof dateValue === 'string') {
      return format(isoStringToDate(dateValue), formatString);
    } else {
      return format(new Date(dateValue), formatString);
    }
  } catch (err) {
    if (err instanceof Error) {
      reportError('Error formatting date', err);
    }
    return 'Invalid Date';
  }
};

export const isToday = (date: Date): boolean => {
  try {
    return format(date, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd');
  } catch (err) {
    if (err instanceof Error) {
      reportError('Error formatting date', err);
    }
    return false;
  }
};
