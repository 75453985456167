import { FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';
import Helmet from 'react-helmet';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';

import { Box } from '~/components/common';
import { DevMenu } from '~/features/Dev/components';
import { RejoinActiveRaceConnected } from '~/features/Spaces/components/RejoinActiveRace';
import { IS_DEV_MODE, IS_DEV_SITE } from '~/module/config';
import { absoluteUrls, dynamicUrls, getPathToSpace } from '~/navigation/Navigation.paths';
import { SceneProps } from '~/navigation/Navigation.types';
import { useUser } from '~/store/hooks';

import { FloatingOverlay } from '../FloatingOverlay';
import { Header } from './Header';

export const SceneContainer: FC<PropsWithChildren<SceneProps & { includeHeader?: boolean }>> = ({
  children,
  isAuthenticated,
  // onOpenProfileModal,
  onOpenDevicesModal,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentActiveRace } = useUser();

  const showHeader = useMemo(() => {
    const match = matchPath(absoluteUrls.stravaAuth, location.pathname);
    if (match && match.pathname === absoluteUrls.stravaAuth) {
      return false;
    }
    return true;
  }, [location.pathname]);

  const isInRaceElsewhere = useMemo(() => {
    if (currentActiveRace) {
      const match = matchPath(dynamicUrls.space, location.pathname);
      return !match;
    }
    return false;
  }, [location, currentActiveRace]);

  const [showDevMenu, setShowDevMenu] = useState(IS_DEV_MODE || IS_DEV_SITE);

  const onNavToSpace = useCallback(
    (spaceId: string) => {
      navigate(getPathToSpace({ spaceId }));
    },
    [navigate],
  );

  return (
    <>
      <Helmet titleTemplate={`Critz | %s`}>
        <title>Breakaway from boring</title>
      </Helmet>

      <Box
        style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}
        className="SceneContainer"
      >
        {showHeader && (
          <>
            {isAuthenticated && (
              <Box as="header">
                <Box border={{ side: 'bottom', color: 'brand' }}>
                  <Header
                    // onOpenProfileModal={onOpenProfileModal}
                    onOpenDevicesModal={onOpenDevicesModal}
                  />
                </Box>
              </Box>
            )}
          </>
        )}

        <Box as="main" flexNum={1} direction="column">
          {children}
        </Box>

        {/* {isAuthenticated && (
          <Box as="footer">
            <Box background="dark-1" pad="large">
              <Box align="start">
                <Button onClick={logout} label="Logout" secondary />
              </Box>
            </Box>
          </Box>
        )} */}

        {showDevMenu && (
          <FloatingOverlay>
            <DevMenu onClose={() => setShowDevMenu(false)} />
          </FloatingOverlay>
        )}
        {isInRaceElsewhere && (
          <FloatingOverlay
            style={{ top: 100, bottom: undefined, left: '25%', right: '25%', opacity: 0.9 }}
            align="center"
          >
            <RejoinActiveRaceConnected onNavToSpace={onNavToSpace} />
          </FloatingOverlay>
        )}
      </Box>
    </>
  );
};
