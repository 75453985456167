import _find from 'lodash/find';

import { ble } from './web-ble';
import { uuids } from './uuids';
import { BlePeripheral } from './BlePeripheral';

function nthBit(field: any, bit: any) {
  return (field >> bit) & 1;
}

function nthBitToBool(field: any, bit: any) {
  return !!nthBit(field, bit);
}

const heartRateFormat = (flags: any) => nthBitToBool(flags, 0);

const fields = {
  heartRate: {
    size: (flags: any) => (heartRateFormat(flags) ? 2 : 1),
  },
};

function heartRateIndex(flags: any) {
  return 1;
}

function readHeartRate(dataview: DataView) {
  const flags = dataview.getUint8(0);

  if (fields.heartRate.size(flags) === 1) {
    return dataview.getUint8(heartRateIndex(flags));
  } else {
    return dataview.getUint16(heartRateIndex(flags), true);
  }
}

function decode(dataview: DataView) {
  const data: any = {};
  data['hr'] = readHeartRate(dataview);
  return data;
}

export class HRM extends BlePeripheral {
  constructor() {
    super(ble.requestFilters.hrm);
  }

  heartRateCharacteristic: any = null;

  async getCharacteristics() {
    if (this.services) {
      const hrService = _find(this.services, ['uuid', uuids.services.heartRate]);
      if (hrService) {
        const characteristics = await ble.getCharacteristics(hrService);

        const hrMeasurement = _find(characteristics, [
          'uuid',
          uuids.characteristics.heartRateMeasurement,
        ]);
        if (hrMeasurement) {
          this.heartRateCharacteristic = hrMeasurement;
        } else {
          throw new Error('Heartrate characteristic not found on service.');
        }
      } else {
        throw new Error('Heartrate service not found on HRM.');
      }
    }
  }

  async subscribe(callback: (data: { hr: number }) => void) {
    if (this.heartRateCharacteristic) {
      await ble.sub(this.heartRateCharacteristic, (val: any) => {
        callback(decode(val.target.value));
      });
    }
  }
}
