import { SceneComponent } from '~/navigation/Navigation.types';
import { UserRaceHistory } from '~/features/History';

import { Scene } from '../layout/Scene';
import { BreadCrumbs } from '../layout/Content';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPathToRaceHistoryItem } from '../Navigation.paths';

export const RaceHistoryScene: SceneComponent<{}> = () => {
  const navigate = useNavigate();

  const onNavToUserRaceHistoryItem = useCallback(
    (raceId: string) => {
      navigate(getPathToRaceHistoryItem({ raceId }));
    },
    [navigate],
  );

  return (
    <Scene title="Race History">
      <BreadCrumbs crumbs={[{ label: 'Race History', active: true }]} />
      <UserRaceHistory onNavToUserRaceHistoryItem={onNavToUserRaceHistoryItem} />
    </Scene>
  );
};
