export type TimeObject = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  ms: number;
};

// export function secondsToTime(seconds: number): TimeObject {
//   var hours = Math.floor(seconds / (60 * 60));

//   var divisor_for_minutes = seconds % (60 * 60);
//   var minutes = Math.floor(divisor_for_minutes / 60);

//   var divisor_for_seconds = divisor_for_minutes % 60;
//   var seconds = Math.ceil(divisor_for_seconds);

//   var obj = {
//     hours,
//     minutes,
//     seconds,
//     ms: (seconds * 1000) % 1000,
//   };
//   return obj;
// }

const ONE_SECOND = 1000;
const ONE_MINUTE = 60 * ONE_SECOND;
const ONE_HOUR = 60 * ONE_MINUTE;
const ONE_DAY = 24 * ONE_HOUR;

export function msToTime(ms: number): TimeObject {
  const days = Math.floor(ms / ONE_DAY);
  const hours = Math.floor((ms % ONE_DAY) / ONE_HOUR);
  const minutes = Math.floor((ms % ONE_HOUR) / ONE_MINUTE);
  const seconds = Math.floor((ms % ONE_MINUTE) / ONE_SECOND);
  const obj = {
    days,
    hours,
    minutes,
    seconds,
    ms: Math.floor(ms % 1000),
  };
  return obj;
}

export function numberToTimePart(num: number, length: number = 2): string {
  let str = `${num}`;
  while (str.length < length) {
    str = `0${str}`;
  }
  return str;
}

export function millisecondsToMinutes(milliseconds: number) {
  if (milliseconds < 1) {
    return 0;
  }
  return Math.floor(milliseconds / 60000);
}

export const timeToClockString = (
  time: TimeObject,
  options: { hours?: boolean; minutes?: boolean; seconds?: boolean; ms?: boolean } = {},
): string => {
  let timeStr = '';
  if (options.hours) {
    timeStr += numberToTimePart(time.hours);
    if (options.minutes || options.seconds || options.ms) {
      timeStr += ':';
    }
  }
  if (options.minutes) {
    timeStr += numberToTimePart(time.minutes);
    if (options.seconds || options.ms) {
      timeStr += ':';
    }
  }
  if (options.seconds) {
    timeStr += numberToTimePart(time.seconds);
    if (options.ms) {
      timeStr += '.';
    }
  }
  if (options.ms) {
    timeStr += numberToTimePart(time.ms, 3);
  }
  return timeStr;
};
