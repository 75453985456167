import { BoxProps } from 'grommet';
import { CSSProperties, FC, PropsWithChildren } from 'react';

import { Box } from '~/components/common';

export const FloatingOverlay: FC<PropsWithChildren<BoxProps & { style?: CSSProperties }>> = ({
  children,
  style,
  ...boxProps
}) => {
  return (
    <Box
      style={{ position: 'fixed', bottom: 16, right: 16, ...style }}
      pad="small"
      flex={false}
      border={{ color: 'accent-2' }}
      round="medium"
      elevation="xsmall"
      background="background-front"
      {...boxProps}
    >
      {children}
    </Box>
  );
};
