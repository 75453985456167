import { useMemo } from 'react';
import { Select } from 'grommet';
import { useDevices, useLocalParticipant } from '@daily-co/daily-react-hooks';
import _find from 'lodash/find';

import { Box, Icon, Text } from '~/components/common';
import { ParticipantVideo } from './ParticipantVideo';
import { AvControlsLocal } from './AvControls';

export const SelectAvDevices = () => {
  const { cameras, microphones, speakers, setCamera, setMicrophone, setSpeaker } = useDevices();
  const participant = useLocalParticipant();

  const camerasFiltered = useMemo(() => {
    const groupsFound: string[] = [];
    return cameras.filter((d) => {
      if (groupsFound.indexOf(d.device.groupId) === -1) {
        groupsFound.push(d.device.groupId);
        return true;
      }
      return false;
    });
  }, [cameras]);

  const microphonesFiltered = useMemo(() => {
    const groupsFound: string[] = [];
    return microphones.filter((d) => {
      if (groupsFound.indexOf(d.device.groupId) === -1) {
        groupsFound.push(d.device.groupId);
        return true;
      }
      return false;
    });
  }, [microphones]);

  const speakersFiltered = useMemo(() => {
    const groupsFound: string[] = [];
    return speakers.filter((d) => {
      if (groupsFound.indexOf(d.device.groupId) === -1) {
        groupsFound.push(d.device.groupId);
        return true;
      }
      return false;
    });
  }, [speakers]);

  const selectedCamera = useMemo(() => {
    return _find(cameras, ['selected', true]);
  }, [cameras]);

  const selectedMicrophone = useMemo(() => {
    return _find(microphones, ['selected', true]);
  }, [microphones]);

  const selectedSpeaker = useMemo(() => {
    return _find(speakers, ['selected', true]);
  }, [speakers]);

  return (
    <Box>
      <Box align="center" margin={{ bottom: 'medium' }}>
        <Text size="xlarge" as="h2">
          Settings
        </Text>
      </Box>
      <Box direction="row" align="center">
        <Box gap="medium" flexNum={1}>
          <Box gap="small">
            <Box direction="row" gap="small" align="center">
              <Icon iconName="camera" color="accent-2" />
              <Text size="large">Camera</Text>
            </Box>
            <Select
              options={camerasFiltered}
              value={selectedCamera}
              labelKey={(option) => option.device.label}
              onChange={({ option }) => {
                console.log(option);
                setCamera(option.device.deviceId);
              }}
            />
          </Box>
          <Box gap="small">
            <Box direction="row" gap="small" align="center">
              <Icon iconName="microphone" color="accent-2" />
              <Text size="large">Microphone</Text>
            </Box>
            <Select
              options={microphonesFiltered}
              value={selectedMicrophone}
              labelKey={(option) => option.device.label}
              onChange={({ option }) => {
                console.log(option);
                setMicrophone(option.device.deviceId);
              }}
            />
          </Box>
          <Box gap="small">
            <Box direction="row" gap="small" align="center">
              <Icon iconName="volume" color="accent-2" />
              <Text size="large">Speakers</Text>
            </Box>
            <Select
              options={speakersFiltered}
              value={selectedSpeaker}
              labelKey={(option) => option.device.label}
              onChange={({ option }) => {
                console.log(option);
                setSpeaker(option.device.deviceId);
              }}
            />
          </Box>
        </Box>
        {!!participant && (
          <Box width="small" align="center" margin={{ left: 'large' }} gap="small">
            <ParticipantVideo
              id={participant.session_id}
              videoEnabled
              audioEnabled={false}
              mirrorVideo
              width="small"
              height="small"
              round
            />
            <AvControlsLocal />
          </Box>
        )}
      </Box>
    </Box>
  );
};
