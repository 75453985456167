import { UserHandle } from '~/components/UserHandle';
import { Box, Dot, Text } from '~/components/common';
import { getOrdinal, msToTime, timeToClockString } from '~/module/utils';
import { TournamentRoundRiderResult } from '~/store/slices/tournamentsSlice.types';

export type TournamentRoundLeaderboardProps = {
  userId?: string;
  roundResults: TournamentRoundRiderResult[];
  isFinal: boolean;
};

export const TournamentRoundLeaderboard = ({
  userId,
  roundResults,
  isFinal,
}: TournamentRoundLeaderboardProps) => {
  return (
    <Box>
      {roundResults.map((result) => {
        const duration = msToTime(result.duration);
        const rankOrdinal = `${result.rank}${getOrdinal(result.rank)}`;
        const isMe = result.userUUID === userId;
        return (
          <Box
            key={`${result.userUUID}-${result.spaceUUID}`}
            margin={{ vertical: 'xsmall' }}
            pad="xsmall"
            justify="between"
            direction="row"
            align="center"
            border="bottom"
          >
            <Box gap="xsmall">
              <Box direction="row" align="center" gap="small">
                <Dot
                  color={result.qualifier ? 'green' : result.fastestFinisher ? 'orange' : 'grey'}
                  size={10}
                />
                <UserHandle {...result} accent={isMe} />
              </Box>
              <Text color="text-xweak" size="small">
                {result.qualifier
                  ? isFinal
                    ? 'Winner'
                    : 'Qualified for next round'
                  : result.fastestFinisher
                  ? 'Qualified for next round as fastest runner up'
                  : isFinal
                  ? rankOrdinal
                  : 'Eliminated'}
              </Text>
            </Box>
            <Box align="end" gap="xsmall">
              <Text>{timeToClockString(duration, { minutes: true, seconds: true, ms: true })}</Text>
              <Text weight="lighter">
                {result.spaceUUID.toUpperCase()} / {rankOrdinal}
              </Text>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
