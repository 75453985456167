import React from 'react';

import { Box, Icon, Text } from '~/components/common';
import { useConnectedDevices } from '~/module/connectedDevices/context';

export const ConnectedDevicesSummary = () => {
  const { controllableConnected, power, heartRateMonitorConnected, heartRate } =
    useConnectedDevices();
  const controllableColor = controllableConnected ? 'status-ok' : 'status-error';
  const hrmColor = heartRateMonitorConnected ? 'status-ok' : 'status-error';
  return (
    <Box direction="row" align="center">
      <Box direction="row" align="center" gap="small">
        <Box
          align="center"
          round="small"
          background={controllableColor}
          pad="xsmall"
          width="xsmall"
          style={{ opacity: controllableConnected ? 1 : 0.7 }}
        >
          <Icon color="text-strong" iconName="bike" />
          <Text color="text-strong" size="small" weight="bold">
            {controllableConnected ? power : '-- '}W
          </Text>
        </Box>
        <Box
          align="center"
          round="small"
          background={hrmColor}
          pad="xsmall"
          width="xsmall"
          style={{ opacity: heartRateMonitorConnected ? 1 : 0.7 }}
        >
          <Icon color="text-strong" iconName="heart" />
          <Text color="text-strong" size="small" weight="bold">
            {heartRateMonitorConnected ? heartRate : '-- '}bpm
          </Text>
        </Box>
      </Box>
    </Box>
  );
};
