import { reportError } from '~/module/logging';
import { ble } from './web-ble';

export class BlePeripheral {
  filter: any;

  deviceId: string = '';
  name: string = '';
  device: string = '';
  server: string = '';
  services: any = null;

  constructor(filter: any) {
    this.filter = filter;
  }

  async reconnect(deviceId: string) {
    try {
      const devices = await ble.getDevices();
      for (const device of devices) {
        if (device.id === deviceId) {
          const server = await ble.gattConnect(device);
          const services = await ble.getPrimaryServices(server);

          this.device = device;
          this.server = server;
          this.name = device.name;
          this.deviceId = device.id;

          this.services = services;
          return true;
        }
      }
    } catch (err) {
      reportError('Error reconecting to ble device', err);
    }
    return false;
  }

  async connect() {
    const res = await ble.connect(this.filter);

    this.device = res.device;
    this.server = res.server;
    this.name = res.device.name;
    this.deviceId = res.device.id;

    this.services = res.services;
  }
}
