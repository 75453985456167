import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { devicesSelectors, devicesActions, Device } from '~/store/slices';

export const useDevices = () => {
  const dispatch = useDispatch();
  const pairedControllable = useSelector(devicesSelectors.getPairedControllable);

  const onControllablePaired = useCallback(
    (device: Device) => {
      dispatch(devicesActions.onControllablePaired({ device }));
    },
    [dispatch],
  );

  const pairedHRM = useSelector(devicesSelectors.getPairedHRM);

  const onHRMPaired = useCallback(
    (device: Device) => {
      dispatch(devicesActions.onHRMPaired({ device }));
    },
    [dispatch],
  );

  return {
    pairedControllable,
    onControllablePaired,
    pairedHRM,
    onHRMPaired,
  };
};
