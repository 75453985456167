import { forwardRef } from 'react';
import { MeshLambertMaterialProps } from '@react-three/fiber';
import * as THREE from 'three';
import { Text } from './ThreeText';

const DistanceArchRadius = 0.5;
const DistanceArchColor = 0x66005f;
const FinishArchColor = 0x5f6100;

type PortalProps = MeshLambertMaterialProps & {
  position: THREE.Vector3Tuple;
  text?: string;
};

const Portal = forwardRef<THREE.Group, PortalProps>(({ position, text, ...props }, ref) => {
  return (
    <group ref={ref} position={position}>
      <mesh>
        <torusGeometry args={[DistanceArchRadius, 0.02, 16, 100]} />
        <meshLambertMaterial {...props} transparent opacity={0.6} />
      </mesh>
      <mesh>
        <circleGeometry args={[DistanceArchRadius, 32]} />
        <meshLambertMaterial {...props} transparent opacity={0.1} />
      </mesh>
      {!!text && (
        <group position={[0, 0.25, 0]}>
          <Text text={text} centered />
        </group>
      )}
    </group>
  );
});

type DistancePortalProps = {
  position: THREE.Vector3Tuple;
  text?: string;
};

export const DistancePortal = forwardRef<THREE.Group, DistancePortalProps>(
  ({ position, text }, ref) => {
    return (
      <Portal
        ref={ref}
        color={DistanceArchColor}
        position={position}
        emissive={DistanceArchColor}
        text={text}
      />
    );
  },
);

export const FinishPortal = forwardRef<THREE.Group, DistancePortalProps>(
  ({ position, text }, ref) => {
    return (
      <Portal
        ref={ref}
        color={FinishArchColor}
        position={position}
        emissive={FinishArchColor}
        text={text}
      />
    );
  },
);
