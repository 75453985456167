import React from 'react'
import { Link, LinkProps } from 'react-router-dom'
import { Anchor, AnchorProps } from 'grommet'

type AnchorLinkProps = LinkProps &
  AnchorProps &
  Omit<JSX.IntrinsicElements['a'], 'color'>

export const AnchorLink: React.FC<AnchorLinkProps> = props => {
  return <Anchor as={Link} {...props} />
}
