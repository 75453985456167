import { PowerRecords } from '~/store/slices';
import { FoundRecords, HistoryDatum, MeasureData, UsageRecords } from '../data-viz.types';

type FieldMeasure = { serverTimeRecorded: number; value: number };

export function getUsageRecords({
  usageRecords,
  raceId,
  minTimestamp,
}: {
  usageRecords: UsageRecords;
  raceId: string;
  minTimestamp: number;
}): FoundRecords[] {
  const availablePeriods = Object.keys(usageRecords.periods);

  const foundRecords: FoundRecords[] = [];
  availablePeriods.forEach((period) => {
    const powerRecordPeriod = usageRecords.periods[period].powerRecords;
    for (const [duration, record] of Object.entries(powerRecordPeriod)) {
      if (record.raceID === raceId) {
        // we have a matching power record
        const relevantPeriod = period;
        const periodStyle = usageRecords.periods[period].period; // eurgh, periods as in all of them, [period] as in which one (say '2022'), period as in type "month", "year", etc
        const startTime = record.startTime;
        const value = record.value;
        if (periodStyle === 'year') {
          foundRecords.push({
            period: relevantPeriod,
            periodStyle: periodStyle,
            startTime: startTime - minTimestamp,
            value: value,
            duration: Number(duration),
          });
        }
      }
    }
  });

  return foundRecords;
}

export function getPowerRecords({
  powerRecords,
  raceId,
  minTimestamp,
}: {
  powerRecords: PowerRecords;
  raceId: string;
  minTimestamp: number;
}): FoundRecords[] {
  const foundRecords: FoundRecords[] = [];
  Object.entries(powerRecords).forEach(([interval, record]) => {
    if (record.raceID === raceId) {
      foundRecords.push({
        period: 'unknown',
        periodStyle: 'unknown',
        startTime: record.startTime - minTimestamp,
        value: record.value,
        duration: Number(interval),
      });
    }
  });
  return foundRecords;
}

export function getMeasurementsAndRecords(
  raceID: string,
  measurements: any,
  _usageRecords: any,
): [MeasureData, FoundRecords[]] {
  const fieldOrder = ['wattage', 'heartrate', 'cadence', 'speed'];
  const measures: MeasureData = {};
  let minTimestamp = new Date().getTime();
  // get minimum timestamp from measures
  fieldOrder.forEach((field) => {
    if (field in measurements) {
      const correctField = field as keyof typeof measurements;
      const lowestFound: number = getMinXValue(measurements[correctField]);
      if (lowestFound < minTimestamp) {
        minTimestamp = lowestFound;
      }
    }
  });

  // need to set the mintimestamp to midnight
  const midnightMin = new Date(0).setHours(0, 0, 0, 0);
  minTimestamp -= midnightMin;

  fieldOrder.forEach((field) => {
    if (field in measurements) {
      const correctField = field as keyof typeof measurements;
      measures[field] = getXYValue(measurements[correctField], field, minTimestamp);
    }
  });

  // const foundRecords = getUsageRecords({
  //   usageRecords: _usageRecords as UsageRecords,
  //   raceId: raceID,
  //   minTimestamp,
  // });
  const foundRecords = getPowerRecords({
    powerRecords: _usageRecords as PowerRecords,
    raceId: raceID,
    minTimestamp,
  });

  return [measures, foundRecords];
}

function getMinXValue(fieldMeasures: FieldMeasure[]): number {
  const Data = fieldMeasures.map((entry): number => {
    return entry.serverTimeRecorded;
  });
  return Math.min(...Data);
}

function getXYValue(
  fieldMeasures: FieldMeasure[],
  field: string,
  minTimestamp: number,
): HistoryDatum[] {
  const Data = fieldMeasures.map((entry): HistoryDatum => {
    let factor = 1;
    if (field === 'speed') {
      factor = 3.6;
    }
    return {
      x: new Date(entry.serverTimeRecorded - minTimestamp),
      y: entry.value * factor,
    };
  });
  return Data;
}
