import { FC } from 'react';

import { Box, Text } from '~/components/common';
import { DateCountdown } from '~/components/DateCountdown';
import { formatDate } from '~/module/dates';

import { TournamentItemState } from '../../../Tournaments.types';

export const TournamentStartingSoon: FC<{ tournament: TournamentItemState }> = ({ tournament }) => {
  return (
    <Box style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Box gap="small" align="center">
          <Text size="3xl" weight="lighter">
            {tournament.name}
          </Text>
          <Text size="xlarge" weight="lighter">
            {formatDate(tournament.details.startTime, 'HH:mm, dd MMM yyyy')}
          </Text>
          <Box
            align="center"
            elevation="small"
            pad="large"
            gap="small"
            width="large"
            margin={{ vertical: 'medium' }}
            round
          >
            <Text uppercase>Tournament starting soon</Text>
            <DateCountdown size="xxlarge" date={tournament.details.startTimeWithOffset} />
            <Text weight="lighter">Are you ready to race?</Text>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
