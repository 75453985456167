import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Home } from '~/features/Home';
import { usePromptStravaAuth } from '~/features/Strava';
import { SceneComponent } from '~/navigation/Navigation.types';
import {
  absoluteUrls,
  getPathToRaceHistoryItem,
  getPathToSpace,
  getPathToTournament,
} from '../Navigation.paths';

export const HomeScene: SceneComponent<{}> = ({ onOpenDevicesModal }) => {
  const navigate = useNavigate();
  const { onPromptAuth } = usePromptStravaAuth();

  const onNavToSpace = useCallback(
    (spaceId: string) => {
      navigate(getPathToSpace({ spaceId }));
    },
    [navigate],
  );

  const onNavToCreateSpace = useCallback(() => {
    navigate(absoluteUrls.createSpace);
  }, [navigate]);

  const onNavToProfile = useCallback(() => {
    navigate(`${absoluteUrls.userProfile}?returnTo=${encodeURIComponent(absoluteUrls.home)}`);
  }, [navigate]);

  const onNavToFindRace = useCallback(() => {
    navigate(absoluteUrls.findRace);
  }, [navigate]);

  const onStartStravaAuth = useCallback(() => {
    // navigate(absoluteUrls.userStravaSettings);
    onPromptAuth();
  }, [onPromptAuth]);

  const onNavToUserRaceHistoryItem = useCallback(
    (raceId: string) => {
      navigate(getPathToRaceHistoryItem({ raceId }));
    },
    [navigate],
  );

  const onNavToTournament = useCallback(
    (tournamentId: string) => {
      navigate(getPathToTournament({ tournamentId }));
    },
    [navigate],
  );

  return (
    <Home
      onNavToSpace={onNavToSpace}
      onNavToProfile={onNavToProfile}
      onNavToCreateSpace={onNavToCreateSpace}
      onNavToFindRace={onNavToFindRace}
      onNavToConnectControllable={onOpenDevicesModal}
      onNavToStravaSettings={onStartStravaAuth}
      onNavToUserRaceHistoryItem={onNavToUserRaceHistoryItem}
      onNavToTournament={onNavToTournament}
    />
  );
};
