import React, { FC } from 'react';

import { Box, Text } from '~/components/common';
import { formatDate } from '~/module/dates';

type MetricDisplayProps = { value: string | number; label: string; dateRecorded?: number };

export const MetricDisplay: FC<MetricDisplayProps> = ({ value, label, dateRecorded }) => {
  return (
    <Box align="center" style={{ minWidth: 100 }}>
      <Text size="3xl" weight="bold">
        {value}
      </Text>
      <Text size="small">{label}</Text>
      {!!dateRecorded && (
        <Box align="center" margin={{ top: 'xsmall' }}>
          <Text size="xsmall">{formatDate(dateRecorded, 'dd MMM yy')}</Text>
        </Box>
      )}
    </Box>
  );
};
