import { useLocation, useSearchParams } from 'react-router-dom';

import { SceneComponent } from '~/navigation/Navigation.types';
import { Box, Spinner } from '~/components/common';
import { StravaReceiveAuth } from '~/features/Strava';

import { Scene } from '../layout/Scene';
import { useEffect } from 'react';

export const StravaAuthScene: SceneComponent<{}> = () => {
  const [params] = useSearchParams();
  const location = useLocation();

  const oAuthCode = params.get('code');
  const oAuthScope = params.get('scope');
  const redirectToDev = params.get('redirectToDev') === '1';

  console.log(redirectToDev);

  // site supports a redirect to dev option as strava api apps
  // can only support one valid callback domain, so it all has
  // to run via the apex critz.cc
  // dev.critz.cc -> strava -> critz.cc redirect to dev.critz.cc
  // critz.cc -> strava -> critz.cc
  useEffect(() => {
    if (redirectToDev) {
      window.location.href = `https://dev.critz.cc${location.pathname}${location.search.replace(
        'redirectToDev=1',
        '',
      )}`;
    }
  }, [redirectToDev, location]);

  if (redirectToDev) {
    return (
      <Box align="center" pad="large">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box align="center">
      <Scene width="medium">
        <StravaReceiveAuth oAuthCode={oAuthCode} oAuthScope={oAuthScope} />
      </Scene>
    </Box>
  );
};
