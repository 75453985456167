import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Space, spacesActions, spacesSelectors } from '../slices';

export function useAvailableSpaces() {
  const dispatch = useDispatch();
  const availableSpaces = useSelector(spacesSelectors.getAvailableSpaces);

  const onAvailableSpacesUpdated = useCallback(
    (spaces: Space[]) => {
      dispatch(spacesActions.onAvailableSpacesUpdated({spaces}));
    },
    [dispatch],
  )

  return {
    availableSpaces,
    onAvailableSpacesUpdated,
  };
}
