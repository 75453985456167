import { useCallback } from 'react';

import { useWsSyncRequest } from '~/module/api';
import {
  GetMyTournamentEntriesRequestData,
  GetMyTournamentEntriesResponseData,
  RegisterForTournamentRequestData,
  RegisterForTournamentResponseData,
  UnRegisterForTournamentRequestData,
  UnRegisterForTournamentResponseData,
} from '~/module/api/api.types';

export const useTournamentActions = (tournamentId: string) => {
  const { isLoading: isFetchingTournamentEntries, send: getMyTournamentEntries } = useWsSyncRequest<
    GetMyTournamentEntriesRequestData,
    GetMyTournamentEntriesResponseData
  >({
    method: 'getMyTournamentEntries',
  });

  const { isLoading: isJoining, send: joinTournament } = useWsSyncRequest<
    RegisterForTournamentRequestData,
    RegisterForTournamentResponseData
  >({
    method: 'registerForTournament',
  });

  const { isLoading: isLeaving, send: leaveTournament } = useWsSyncRequest<
    UnRegisterForTournamentRequestData,
    UnRegisterForTournamentResponseData
  >({
    method: 'unregisterForTournament',
  });

  const { isLoading: isConfirmingEntry, send: confirmEntry } = useWsSyncRequest<
    UnRegisterForTournamentRequestData,
    UnRegisterForTournamentResponseData
  >({
    method: 'confirmTournamentEntry',
  });

  const onJoinTournament = useCallback(async () => {
    const response = await joinTournament({ tournamentID: tournamentId });
    console.log('onJoinTournament', response);
  }, [tournamentId, joinTournament]);

  const onLeaveTournament = useCallback(async () => {
    const response = await leaveTournament({ tournamentID: tournamentId });
    console.log('onLeaveTournament', response);
  }, [tournamentId, leaveTournament]);

  const onConfirmEntry = useCallback(async () => {
    const response = await confirmEntry({ tournamentID: tournamentId });
    console.log('onConfirmEntry', response);
  }, [tournamentId, confirmEntry]);

  return {
    isFetchingTournamentEntries,
    getMyTournamentEntries,
    isJoining,
    onJoinTournament,
    isLeaving,
    onLeaveTournament,
    isConfirmingEntry,
    onConfirmEntry,
  };
};
