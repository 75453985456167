import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';
import { RaceHistoryItem, RaceData } from './raceHistorySlice.types';

type RaceHistoryDetail = {
  measures?: {};
  performance?: {
    wattHours: number;
    normPower: number;
    avgPower: number;
    avgSpeed: number;
    duration: number;
  };
};

export type RaceHistoryState = {
  userRaceHistory: RaceHistoryItem[];
  userRaceHistoryDetail: Record<string, RaceHistoryDetail>;
  raceData: Record<string, RaceData>;
};

const initialRaceHistoryState: RaceHistoryState = {
  userRaceHistory: [],
  userRaceHistoryDetail: {},
  raceData: {},
};

export const raceHistorySlice = createSlice({
  name: 'raceHistory',
  initialState: initialRaceHistoryState,
  reducers: {
    onSetUserRaceHistory(state, action: PayloadAction<{ races: RaceHistoryItem[] }>) {
      state.userRaceHistory = action.payload.races;
    },
    onSetUserRaceHistoryDetail(
      state,
      action: PayloadAction<{ raceId: string; data: RaceHistoryDetail }>,
    ) {
      const { raceId, data } = action.payload;
      state.userRaceHistoryDetail[raceId] = {
        ...state.userRaceHistoryDetail[raceId],
        ...data,
      };
    },
    onSetRaceData(state, action: PayloadAction<{ raceId: string; data: RaceData }>) {
      state.raceData[action.payload.raceId] = action.payload.data;
    },
  },
});

export const raceHistoryReducer = raceHistorySlice.reducer;
export const raceHistoryActions = raceHistorySlice.actions;
export const raceHistorySelectors = {
  getUserRaceHistory: (state: RootState) => state.raceHistory.userRaceHistory,
  getUserRaceHistoryDetail: (raceId: string) => (state: RootState) =>
    state.raceHistory.userRaceHistoryDetail[raceId],
  getRaceData: (raceId: string) => (state: RootState) => state.raceHistory.raceData[raceId],
};
