import {
  signOut,
  signInWithEmailAndPassword,
  UserCredential,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from './InitFirebase';

export const logout = () => {
  signOut(auth);
};

export const signIn = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<UserCredential> => {
  const result = await signInWithEmailAndPassword(auth, email, password);
  return result;
};

export const signUp = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}): Promise<UserCredential> => {
  const result = await createUserWithEmailAndPassword(auth, email, password);
  return result;
};

export const requestPasswordReset = async ({ email }: { email: string }) => {
  await sendPasswordResetEmail(auth, email);
};
