import { Tournament } from '~/store/slices/tournamentsSlice.types';

export const canSignUp = (tournament: Tournament): boolean => {
  return tournament.state === 'pre-event' && tournament.details.registrationOpen;
};

export const getTournamentRoundNumber = ({
  tournamentState,
  numRounds,
}: {
  tournamentState: string;
  numRounds: number;
}): number => {
  if (tournamentState === 'complete') {
    return numRounds;
  }
  if (tournamentState === 'pre-event') {
    return 1;
  }
  const possibleRoundNumberStr = tournamentState
    .replace('pendingRound', '')
    .replace('racingRound', '')
    .replace('completeRound', '');
  const roundNumber = Number(possibleRoundNumberStr);
  if (isNaN(roundNumber)) {
    return 1;
  }
  return roundNumber;
};

export const getSimplifiedState = (state: string) => {
  switch (state) {
    case 'pre-event':
    case 'complete':
      return state;
    default:
      return 'in-progress';
  }
};
