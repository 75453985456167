import _find from 'lodash/find';

import { ble } from './web-ble';
import { uuids } from './uuids';
import { BlePeripheral } from './BlePeripheral';
import { FEC2 } from './utils/fec2';

// const allServices = Object.entries(uuids.services).map(([name, uuid]) => {
//   return {
//     name,
//     uuid,
//   };
// });

// const allCharacteristics = Object.entries(uuids.characteristics).map(([name, uuid]) => {
//   return {
//     name,
//     uuid,
//   };
// });

// function logServicesDetails(services: any[]) {
//   services.forEach((service: any) => {
//     const s = _find(allServices, ['uuid', service.uuid]);
//     if (s) {
//       console.log(`Controllable: Found service '${s.name}'`);
//     } else {
//       console.log(`Unable to find matching service ${service.uuid}`);
//     }
//   });
// }

// function logCharacteristicsDetails(characteristics: any[]) {
//   characteristics.forEach((char: any) => {
//     const c = _find(allCharacteristics, ['uuid', char.uuid]);
//     const s = _find(allServices, ['uuid', char.service.uuid]);
//     if (c) {
//       console.log(
//         `Controllable: Found characteristic '${c.name}'${(!!s && ` on service '${s.name}'`) || ''}`,
//       );
//     } else {
//       console.log(`Unable to find matching characteristic ${char.uuid}`);
//     }
//   });
// }

const definitions = {
  flags: { size: 2, resolution: 1, unit: '' },
  power: { size: 2, resolution: 1, unit: 'W' },
};

function powerIndex(flags: number) {
  let i = definitions.flags.size;
  return i;
}

function decodePower(dataview: DataView) {
  const flags = dataview.getUint16(0, true);
  return dataview.getInt16(powerIndex(flags), true);
}

// function cadenceIndex(flags: number) {
//   let i = definitions.flags.size;
//   return i;
// }

// function decodeCadence(dataview: DataView) {
//   const flags = dataview.getUint16(0, true);
//   return dataview.getInt16(cadenceIndex(flags), true);
// }

export class Controllable extends BlePeripheral {
  constructor() {
    super(ble.requestFilters.controllable);
  }

  cyclingPowerCharacteristic: any = null;
  cadenceCharacteristic: any = null;
  fec2Characteristic: any = null;

  async getCharacteristics() {
    if (this.services) {
      // logServicesDetails(this.services);

      const cyclingPowerService = _find(this.services, ['uuid', uuids.services.cyclingPower]);
      if (cyclingPowerService) {
        const characteristics = await ble.getCharacteristics(cyclingPowerService);
        // logCharacteristicsDetails(characteristics);

        const powerMeasurement = _find(characteristics, [
          'uuid',
          uuids.characteristics.cyclingPowerMeasurement,
        ]);
        if (powerMeasurement) {
          this.cyclingPowerCharacteristic = powerMeasurement;
        } else {
          throw new Error(
            'Cycling power measurement characteristic not found on cycling power service.',
          );
        }
      } else {
        throw new Error('Cycling power service not found on controllable.');
      }

      const fecService = _find(this.services, ['uuid', uuids.services.fec]);
      if (fecService) {
        const characteristics = await ble.getCharacteristics(fecService);
        // logCharacteristicsDetails(characteristics);

        const fec2Characteristic = _find(characteristics, ['uuid', uuids.characteristics.fec2]);
        if (fec2Characteristic) {
          this.fec2Characteristic = fec2Characteristic;
        }
      }

      // const cyclingCadenceService = _find(this.services, ['uuid', uuids.services.speedCadence]);
      // if (cyclingCadenceService) {
      //   const characteristics = await ble.getCharacteristics(cyclingCadenceService);
      //   const cadenceMeasurement = _find(characteristics, [
      //     'uuid',
      //     uuids.characteristics.speedCadenceMeasurement,
      //   ]);
      //   if (cadenceMeasurement) {
      //     this.cadenceCharacteristic = cadenceMeasurement;
      //   } else {
      //     console.warn('Cadence measurement characteristic not found on speed-cadence service.');
      //   }
      // } else {
      //   console.warn('CyclingCadence service not found in services');
      // }

      // if (!this.cadenceCharacteristic) {
      //   const fitnessMachineService = _find(this.services, ['uuid', uuids.services.fitnessMachine]);
      //   if (fitnessMachineService) {
      //     const characteristics = await ble.getCharacteristics(fitnessMachineService);

      //     console.log(characteristics);
      //     const cadenceMeasurement = _find(characteristics, [
      //       'uuid',
      //       // uuids.characteristics.speedCadenceMeasurement,
      //       uuids.characteristics.fitnessMachineFeature,
      //     ]);
      //     if (cadenceMeasurement) {
      //       this.cadenceCharacteristic = cadenceMeasurement;
      //     } else {
      //       console.warn(
      //         'Cadence measurement characteristic not found on fitness machine service.',
      //       );
      //     }
      //   } else {
      //     console.warn('Fitness machine service not found in services');
      //   }
      // }

      // if (!this.cadenceCharacteristic) {
      //   const wahooFitnessMachineService = _find(this.services, [
      //     'uuid',
      //     uuids.services.wahooFitnessMachine,
      //   ]);
      //   if (wahooFitnessMachineService) {
      //     const characteristics = await ble.getCharacteristics(wahooFitnessMachineService);

      //     console.log(characteristics);
      //     const cadenceMeasurement = _find(characteristics, [
      //       'uuid',
      //       uuids.characteristics.speedCadenceMeasurement,
      //     ]);
      //     if (cadenceMeasurement) {
      //       this.cadenceCharacteristic = cadenceMeasurement;
      //     } else {
      //       console.warn(
      //         'Cadence measurement characteristic not found on wahoo fitness machine service.',
      //       );
      //     }
      //   } else {
      //     console.warn('Wahoo Fitness machine service not found in services');
      //   }
      // }

      // if (!this.cadenceCharacteristic) {
      //   console.warn('Cadence service not found on controllable.');
      // }
    }
  }

  async subscribe(callback: (data: { power: number; cadence: number }) => void) {
    let power: number = 0;
    let cadence: number = 0;
    if (this.cyclingPowerCharacteristic) {
      await ble.sub(this.cyclingPowerCharacteristic, (val: any) => {
        power = decodePower(val.target.value);
        callback({ power, cadence });
      });
    }
    // if (this.cadenceCharacteristic) {
    //   await ble.sub(this.cadenceCharacteristic, (val: any) => {
    //     cadence = decodeCadence(val.target.value);
    //     callback({ power, cadence });
    //   });
    // }
    if (this.fec2Characteristic) {
      const fec2 = FEC2();
      await ble.sub(this.fec2Characteristic, (val: any) => {
        const msg = fec2.decode(val.target.value) as any;
        if (msg && typeof msg.cadence === 'number') {
          cadence = msg.cadence;
          callback({ power, cadence });
        }
      });
    }
  }
}
