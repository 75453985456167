import { first, equals, isNil } from 'lodash';
import { uuids } from './uuids';

////
// private
////
const _type = 'web-ble';

const _hrm = {
  filters: [{ services: [uuids.services.heartRate] }],
  optionalServices: [uuids.services.deviceInformation],
};

const _controllable = {
  filters: [
    { services: [uuids.services.fitnessMachine] },
    { services: [uuids.services.fec] },
    { services: [uuids.services.wahooFitnessMachine] },
    { services: [uuids.services.cyclingPower] },
  ],
  optionalServices: [uuids.services.deviceInformation],
};

const _power = {
  filters: [{ services: [uuids.services.cyclingPower] }],
  optionalServices: [uuids.services.deviceInformation],
};

const _speedCadence = {
  filters: [{ services: [uuids.services.speedCadence] }],
  optionalServices: [uuids.services.deviceInformation],
};

const _all = { acceptAllDevices: true };

function filterIn(coll, prop, value) {
  return first(coll.filter((x) => x[prop] === value));
}

function filterByValue(obj, value) {
  return Object.entries(obj).filter((kv) => kv[1] === value);
}

function findByValue(obj, value) {
  return first(first(filterByValue(obj, value)));
}

function filterDevice(devices, id) {
  return filterIn(devices, id);
}

function includesDevice(devices, id) {
  return devices.map((device) => device.id).includes((device) => equals(device.id, id));
}

const _ = { filterDevice, includesDevice };

////
// public
////

class WebBLE {
  requestFilters = {
    hrm: _hrm,
    controllable: _controllable,
    speedCadence: _speedCadence,
    power: _power,
    all: _all,
  };
  // constructor(args) {}
  get type() {
    return _type;
  }
  async connect(filter) {
    const self = this;
    const device = await self.request(filter);
    const server = await self.gattConnect(device);
    const services = await self.getPrimaryServices(server);
    return {
      device,
      server,
      services,
    };
  }
  async disconnect(device) {
    const self = this;
    await self.gattDisconnect(device);
    return device;
  }
  isConnected(device) {
    if (isNil(device.gatt)) return false;
    return device.gatt.connected;
  }
  async watchAdvertisements(id) {
    const devices = await navigator.bluetooth.getDevices();
    const device = first(devices.filter((d) => d.id === id));

    console.log('Found device', device);

    let resolve;
    const p = new Promise(function (res, rej) {
      resolve = res;
    });

    const abortController = new AbortController();
    device.addEventListener('advertisementreceived', onAdvertisementReceived.bind(this), {
      once: true,
    });

    async function onAdvertisementReceived(e) {
      console.log('advertisementReceived', e);
      abortController.abort();
      resolve(e.device);
    }

    await device.watchAdvertisements({ signal: abortController.signal });

    return p;
  }
  async sub(characteristic, handler) {
    const self = this;
    await self.startNotifications(characteristic, handler);
    return characteristic;
  }
  async unsub(characteristic, handler) {
    const self = this;
    await self.stopNotifications(characteristic, handler);
    return characteristic;
  }
  async request(filter) {
    return await navigator.bluetooth.requestDevice(filter);
  }
  async getDevices() {
    return await navigator.bluetooth.getDevices();
  }
  async isPaired(device) {
    const self = this;
    const devices = await self.getDevices();
    return includesDevice(devices, device.id);
  }
  async getPairedDevice(deviceId) {
    const self = this;
    const devices = await self.getDevices();
    return filterDevice(devices, deviceId);
  }
  async gattConnect(device) {
    const server = await device.gatt.connect();
    return server;
  }
  async gattDisconnect(device) {
    return await device.gatt.disconnect();
  }
  async getPrimaryServices(server) {
    const services = await server.getPrimaryServices();
    return services;
  }
  async getService(server, uuid) {
    const service = await server.getPrimaryService(uuid);
    return service;
  }
  async getCharacteristic(service, uuid) {
    const characteristic = await service.getCharacteristic(uuid);
    return characteristic;
  }
  async getCharacteristics(service) {
    const characteristics = await service.getCharacteristics();
    return characteristics;
  }
  async getDescriptors(characteristic) {
    const descriptors = await characteristic.getDescriptors();
    return descriptors;
  }
  async getDescriptor(characteristic, uuid) {
    const descriptor = await characteristic.getDescriptor(uuid);
    return descriptor;
  }
  async startNotifications(characteristic, handler) {
    await characteristic.startNotifications();
    characteristic.addEventListener('characteristicvaluechanged', handler);
    console.log(
      `Notifications started on ${findByValue(uuids, characteristic.uuid)}: ${
        characteristic.uuid
      }.`,
    );
    return characteristic;
  }
  async stopNotifications(characteristic, handler) {
    await characteristic.stopNotifications();
    characteristic.removeEventListener('characteristicvaluechanged', handler);
    console.log(
      `Notifications stopped on ${findByValue(uuids, characteristic.uuid)}: ${
        characteristic.uuid
      }.`,
    );
    return characteristic;
  }
  async writeCharacteristic(characteristic, value) {
    let res = undefined;
    try {
      if (!isNil(characteristic.writeValueWithResponse)) {
        res = await characteristic.writeValueWithResponse(value);
      } else {
        res = await characteristic.writeValue(value);
      }
    } catch (e) {
      console.error(`characteristic.writeValue:`, e);
    }
    return res;
  }
  async readCharacteristic(characteristic) {
    let value = new DataView(new Uint8Array([0]).buffer); // ????
    try {
      value = await characteristic.readValue();
    } catch (e) {
      console.error(`characteristic.readValue: ${e}`);
    }
    return value;
  }
  isSupported() {
    if (isNil(navigator)) throw new Error(`Trying to use web-bluetooth in non-browser env!`);
    return 'bluetooth' in navigator;
  }
  isSwitchedOn() {
    return navigator.bluetooth.getAvailability();
  }
}

const ble = new WebBLE();

export { ble, _ };
