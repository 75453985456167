import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { SceneComponent } from '~/navigation/Navigation.types';
import { getPathToObserveSpace, getPathToSpace } from '~/navigation/Navigation.paths';
import { FindARace } from '~/features/Spaces';

import { Scene } from '../layout/Scene';
import { BreadCrumbs } from '../layout/Content';

export const FindRaceScene: SceneComponent<{}> = () => {
  const navigate = useNavigate();

  const onNavToSpace = useCallback(
    (spaceId: string) => {
      navigate(getPathToSpace({ spaceId }));
    },
    [navigate],
  );

  const onNavToObserveSpace = useCallback(
    (spaceId: string) => {
      navigate(getPathToObserveSpace({ spaceId }));
    },
    [navigate],
  );

  return (
    <Scene>
      <BreadCrumbs crumbs={[{ label: 'Find a Race', active: true }]} />
      <FindARace onNavToSpace={onNavToSpace} onNavToObserveSpace={onNavToObserveSpace} />
    </Scene>
  );
};
