import { FC, PropsWithChildren } from 'react';
import { ColumnSizeType, Layer } from 'grommet';

import { Box } from '~/components/common';

type ModalProps = {
  width?: ColumnSizeType;
  isVisible: boolean;
  onRequestClose: () => void;
};

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  isVisible,
  children,
  width,
  onRequestClose,
}) => {
  return (
    <>
      {isVisible && (
        <Layer onEsc={onRequestClose} onClickOutside={onRequestClose} modal>
          <Box
            pad="medium"
            width={width}
            style={{ minWidth: '40vw', maxWidth: '90vw', maxHeight: '90vh' }}
            flex={false}
            overflow="auto"
            elevation="medium"
            border={{ color: 'accent-2' }}
            round="large"
          >
            {children}
          </Box>
        </Layer>
      )}
    </>
  );
};
