import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { RootState } from '../rootReducer';
import { userActions } from '../slices/userSlice';

export function useAuth() {
  const dispatch = useDispatch();
  const {
    authStateKnown,
    userId,
    idToken: userIdToken,
    email,
  } = useSelector((state: RootState) => state.user);

  const onSetAuth = useCallback(
    ({
      id,
      isAnonymous,
      idToken,
      email,
    }: {
      id?: string;
      isAnonymous?: boolean;
      idToken?: string;
      email?: string;
    }) => {
      dispatch(userActions.onSetAuthState({ userId: id, isAnonymous, idToken, email }));
    },
    [dispatch],
  );

  return {
    authStateKnown,
    authenticated: !!userIdToken,
    userId,
    userIdToken,
    email,
    onSetAuth,
  };
}
