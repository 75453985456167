import { FC } from 'react';

import { Box, Tag, Text } from '~/components/common';
import { formatDate } from '~/module/dates';
import { Tournament } from '~/store/slices';

type TournamentListItemProps = {
  tournament: Tournament;
  showState?: boolean;
};

export const TournamentListItem: FC<TournamentListItemProps> = ({
  tournament,
  showState = false,
}) => {
  return (
    <Box gap="xsmall">
      <Box direction="row" justify="between" gap="medium">
        <Text uppercase size="small">
          {formatDate(tournament.details.startTime, 'HH:mm, dd MMM yyyy')}
        </Text>
        {showState && <Tag size="xsmall" value={tournament.state} />}
      </Box>
      <Text size="medium" weight="bold">
        {tournament.name}
      </Text>
      <Text size="small" color="text-xweak">
        {tournament.signups} signups
      </Text>
    </Box>
  );
};
