import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';

export type IntegrationState = {
  isStravaConnected: boolean;
  hasStravaAuthToken: boolean;
  shouldAutoUploadRacesToStrava: boolean;
};

const initialState: IntegrationState = {
  isStravaConnected: false,
  hasStravaAuthToken: false,
  shouldAutoUploadRacesToStrava: false,
};

export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {
    onStravaConnectionState(
      state,
      action: PayloadAction<{
        isConnected: boolean;
        hasAuthToken: boolean;
        shouldAutoUploadRaces: boolean;
      }>,
    ) {
      state.isStravaConnected = action.payload.isConnected;
      state.hasStravaAuthToken = action.payload.hasAuthToken;
      state.shouldAutoUploadRacesToStrava = action.payload.shouldAutoUploadRaces;
    },
  },
});

export const integrationReducer = integrationSlice.reducer;
export const integrationActions = integrationSlice.actions;
export const integrationSelectors = {
  getIsStravaConnected: (state: RootState) => state.integration.isStravaConnected,
  getHasStravaAuthToken: (state: RootState) => state.integration.hasStravaAuthToken,
  getShouldAutoUploadRacesToStrava: (state: RootState) =>
    state.integration.shouldAutoUploadRacesToStrava,
};
