import { useNavigate, useParams } from 'react-router-dom';

import { SceneComponent } from '~/navigation/Navigation.types';
import { Box } from '~/components/common';
import { Tournament } from '~/features/Tournaments/components/Tournament';

import { absoluteUrls, DynamicURLTypes, getPathToObserveSpace } from '../Navigation.paths';
import { useEffect } from 'react';

export const TournamentScene: SceneComponent<{}> = ({ onOpenDevicesModal }) => {
  const navigate = useNavigate();
  const { tournamentId } = useParams<DynamicURLTypes['tournament']>();

  const onNavToObserveRace = (spaceId: string) => navigate(getPathToObserveSpace({ spaceId }));

  useEffect(() => {
    if (!tournamentId) {
      navigate(absoluteUrls.notFound);
    }
  }, [tournamentId, navigate]);

  if (!tournamentId) return null;

  return (
    <Box>
      <Tournament
        tournamentId={tournamentId}
        onNavToObserveRace={onNavToObserveRace}
        onOpenDevicesModal={onOpenDevicesModal}
      />
    </Box>
  );
};
