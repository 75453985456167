import { FC } from 'react';
import { Box as GrommetBox, BoxProps } from 'grommet';
import { normalizeColor } from 'grommet/utils';

import { useTheme } from '~/theme';

type DotProps = Omit<BoxProps, 'background' | 'width' | 'round'> & {
  color: string;
  size: number;
};

export const Dot: FC<DotProps> = ({ color, size, ...props }) => {
  const theme = useTheme();
  const dotColor = normalizeColor(color, theme);

  return (
    <GrommetBox
      background={dotColor}
      round="full"
      width={`${size}px`}
      height={`${size}px`}
      {...props}
    />
  );
};
