import { useMemo } from 'react';
import _orderBy from 'lodash/orderBy';

import { Box, Tag, Text } from '~/components/common';
import { formatDate } from '~/module/dates';
import {
  Tournament,
  TournamentRoundInfo,
  TournamentRaceRiderRank,
} from '~/store/slices/tournamentsSlice.types';

import { TournamentRiderRanks } from './TournamentRiderRanks';
import { TournamentRound } from './TournamentRound';

// TODO - indicate where you finished, or if you took part

export const TournamentComplete = ({
  userId,
  tournament,
  riderRanks,
  rounds,
}: {
  userId?: string;
  tournament: Tournament;
  riderRanks: TournamentRaceRiderRank[];
  rounds: TournamentRoundInfo[];
}) => {
  const roundsInReverseOrder = useMemo(() => {
    return _orderBy(rounds, ['roundNumber'], ['desc']);
  }, [rounds]);
  return (
    <Box pad="large" gap="large">
      <Box gap="small">
        <Box direction="row" align="center" gap="medium">
          <Text size="4xl">{tournament.name}</Text>
          <Tag value="Finished" />
        </Box>
        <Text size="2xl" weight="lighter">
          {formatDate(tournament.details.startTime, 'HH:mm, dd MMM yyyy')}
        </Text>
      </Box>

      <Box>
        <Text size="xxlarge" weight="bold" margin={{ bottom: 'medium' }}>
          Leaderboard
        </Text>
        <Box>
          <TournamentRiderRanks userId={userId} riderRanks={riderRanks} />
        </Box>
      </Box>

      <Box>
        <Text size="xxlarge" weight="bold" margin={{ bottom: 'medium' }}>
          Tournament Round Breakdown
        </Text>
        <Box>
          {roundsInReverseOrder.map((round) => {
            return (
              <Box key={round.roundNumber} margin={{ bottom: 'xlarge' }}>
                <TournamentRound userId={userId} round={round} />
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
