import { useRef } from 'react';
import { useConnectedDevices } from '~/module/connectedDevices';
import { useClockOffset } from '~/store/hooks';

export const useRaceRefs = () => {
  const { power } = useConnectedDevices();
  const clientPowerRef = useRef(power);
  clientPowerRef.current = power;

  const clientTimeOffset = useClockOffset();
  const clientTimeOffsetRef = useRef(clientTimeOffset);
  clientTimeOffsetRef.current = clientTimeOffset;

  return {
    clientPowerRef,
    clientTimeOffsetRef,
  };
};
