import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Spinner, Text } from '~/components/common';
import { useFirestoreStravaIntegration } from '~/module/firebase';
import { reportError } from '~/module/logging';
import { useUser } from '~/store/hooks';

import { CritzStravaConnection } from './CritzStravaConnection';

export const StravaReceiveAuth: FC<{ oAuthCode: string | null; oAuthScope: string | null }> = ({
  oAuthCode,
  oAuthScope,
}) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isProcessed, setIsProcessed] = useState(false);
  const { userId } = useUser();
  const { onCreateIntegration } = useFirestoreStravaIntegration();

  const hasRequiredValues = useMemo(() => {
    if (!oAuthCode) return false;
    if (!oAuthScope) return false;
    const scopes = oAuthScope.split(',');
    if (!scopes.includes('read') || !scopes.includes('activity:write')) return false;
    return true;
  }, [oAuthCode, oAuthScope]);

  const onSaveOAuthCreds = useCallback(
    async (authCode: string) => {
      // TODO: save to redux and to DB
      setIsProcessing(true);
      try {
        await onCreateIntegration(authCode);
        setIsProcessed(true);
      } catch (err) {
        reportError('Error saving strava creds', err);
        setIsProcessed(false);
      }
      setIsProcessing(false);
    },
    [onCreateIntegration],
  );

  useEffect(() => {
    if (hasRequiredValues) {
      setIsProcessing(true);
      setTimeout(() => {
        onSaveOAuthCreds(oAuthCode!);
      }, 3000);
    }
  }, [hasRequiredValues, oAuthCode, onSaveOAuthCreds]);

  if (!userId) return null;

  return (
    <Box>
      <CritzStravaConnection />
      {isProcessing && (
        <Box align="center" margin="large">
          <Spinner />
          <Text size="small" margin={{ top: 'small' }}>
            Processing
          </Text>
        </Box>
      )}
      {isProcessed && (
        <Box align="center" margin="large">
          <Text size="large">Connected</Text>
          <Text size="small" margin={{ top: 'small' }}>
            You can now close this window.
          </Text>
        </Box>
      )}
      {(!hasRequiredValues || isProcessed) && (
        <Box align="center">
          <Button
            onClick={() => {
              window.close();
            }}
            label="Close"
          />
        </Box>
      )}
    </Box>
  );
};
