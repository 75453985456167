import { FC, useState } from 'react';

import { Box, Text } from '~/components/common';

import { Tournament } from '~/store/slices';

import { useTournamentList } from './useTournamentList';
import { TournamentListItem } from './TournamentListItem';
import { Card } from '~/components/Card';
import { Accordion, AccordionPanel } from 'grommet';

type TournamentListNavProps = {
  onNavToTournament: (tournamentId: string) => void;
};

type TournamentListProps = {
  today: Tournament[];
  upcoming: Tournament[];
  complete: Tournament[];
};

export const TournamentList: FC<TournamentListProps & TournamentListNavProps> = ({
  today,
  upcoming,
  complete,
  onNavToTournament,
}) => {
  const [showPrevious, setShowPrevious] = useState(true);

  return (
    <Box gap="medium" pad={{ vertical: 'medium' }}>
      {today.length > 0 && (
        <Box pad={{ horizontal: 'medium' }}>
          <Box margin={{ bottom: 'medium' }}>
            <Text uppercase size="small" weight="bold">
              Today
            </Text>
          </Box>
          <Box>
            {today.map((t) => {
              return (
                <Card
                  key={t.id}
                  margin={{ bottom: 'medium' }}
                  width="medium"
                  onClick={() => onNavToTournament(t.id)}
                >
                  <TournamentListItem tournament={t} showState />
                </Card>
              );
            })}
          </Box>
        </Box>
      )}

      <Box pad={{ horizontal: 'medium' }}>
        <Box margin={{ bottom: 'medium' }}>
          <Text uppercase size="small" weight="bold">
            Upcoming {upcoming.length > 0 && `(${upcoming.length})`}
          </Text>
        </Box>
        <Box>
          {!upcoming.length && (
            <Text color="text-xweak" size="small">
              No upcoming tournaments. Contact <a href="mailto:matt@critz.cc">matt@critz.cc</a> to
              chat about hosting a tournament.
            </Text>
          )}
          {upcoming.map((t) => {
            return (
              <Card
                key={t.id}
                margin={{ bottom: 'medium' }}
                width="medium"
                onClick={() => onNavToTournament(t.id)}
              >
                <TournamentListItem tournament={t} />
              </Card>
            );
          })}
        </Box>
      </Box>

      {complete.length > 0 && (
        <Accordion
          margin="medium"
          activeIndex={showPrevious ? 0 : -1}
          onActive={(index) => setShowPrevious(index.length ? true : false)}
        >
          <AccordionPanel label={`Previous Tournaments (${complete.length})`}>
            <Box direction="row" align="center" wrap>
              {complete.map((t) => {
                // TODO - group by month
                return (
                  <Card
                    key={t.id}
                    width="medium"
                    margin="medium"
                    onClick={() => onNavToTournament(t.id)}
                  >
                    <TournamentListItem tournament={t} />
                  </Card>
                );
              })}
            </Box>
          </AccordionPanel>
        </Accordion>
      )}
    </Box>
  );
};

export default function TournamentListConnected(navProps: TournamentListNavProps) {
  const props = useTournamentList();
  return <TournamentList {...props} {...navProps} />;
}
