import * as ReactDOMClient from 'react-dom/client';

import App from './App';
import './index.css';

import { initFirebase } from './module/firebase';
import { initLogging } from './module/logging';

initFirebase();
initLogging();

const container = document.getElementById('root');

const root = ReactDOMClient.createRoot(container!);
root.render(<App />);
