import { UserHandle } from '~/components/UserHandle';
import { Box, Button, Dot, Tag, Text } from '~/components/common';
import { TournamentRoundSpace } from '~/store/slices/tournamentsSlice.types';

export type TournamentSpaceDetailsProps = {
  userId?: string;
  space: TournamentRoundSpace;
  onNavToObserveRace?: (spaceId: string) => void;
};

export const TournamentSpaceDetails = ({
  userId,
  space,
  onNavToObserveRace,
}: TournamentSpaceDetailsProps) => {
  const canObserve = !!onNavToObserveRace && space.state === 'incomplete';
  return (
    <Box elevation={canObserve ? 'small' : 'xsmall'} round pad="medium" width="medium">
      <Box direction="row" align="center" gap="small" margin={{ bottom: 'small' }}>
        <Text size="xlarge" weight="bold" uppercase>
          {space.spaceUUID}
        </Text>
        <Tag value={space.state === 'complete' ? 'Complete' : 'Pending'} size="xsmall" />
      </Box>
      <Box>
        {space.users.map((user) => {
          const isMe = user.id === userId;
          return (
            <Box key={user.id} direction="row" align="center" gap="small">
              {space.state === 'complete' && (
                <Dot
                  color={user.qualifier ? 'green' : user.fastestFinisher ? 'orange' : 'grey'}
                  size={10}
                />
              )}
              <UserHandle {...user} accent={isMe} />
            </Box>
          );
        })}
      </Box>
      {canObserve && (
        <Box margin={{ top: 'medium' }} flexNum={1} justify="end">
          <Button label="Watch race" onClick={() => onNavToObserveRace(space.spaceUUID)} />
        </Box>
      )}
    </Box>
  );
};
