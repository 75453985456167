import React, { FC } from 'react';

import { Box, Text } from '~/components/common';
import { DateCountdown } from '~/components/DateCountdown';

export const CountdownClock: FC<{
  raceStartTime: number;
  raceDurationMs: number;
  raceStartingSoon: boolean;
  raceHasStarted: boolean;
}> = ({ raceStartTime, raceDurationMs, raceHasStarted, raceStartingSoon }) => {
  return (
    <Box
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        zIndex: 3,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {raceHasStarted && raceDurationMs <= 2000 && (
        <Text style={{ fontSize: 130 }} color="brand">
          GO!!!!
        </Text>
      )}
      {raceStartingSoon && (
        <DateCountdown date={raceStartTime} color="accent-1" size="120px" concise />
      )}
    </Box>
  );
};
