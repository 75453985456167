import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useWsApi, useWsConnectedStatus } from '~/module/api';
import { useIsUserActive } from '~/hooks';
import { spacesActions } from '~/store/slices';

import { useListeners } from './useListeners';

export function useSubscriptionManager() {
  const ws = useWsApi();

  const { startMessageListeners, stopMessageListeners } = useListeners();
  const isSocketConnected = useWsConnectedStatus(ws);
  const isUserActive = useIsUserActive();

  useEffect(() => {
    // only auto reconnect if user is active
    if (isUserActive) {
      ws.startReconnectionListener();
      if (!isSocketConnected) {
        console.log('Attempting to reconnect');
        ws.reconnect();
      }
    } else {
      ws.stopReconnectionListener();
    }

    if (isUserActive && isSocketConnected) {
      ws.startPing();
      startMessageListeners(ws);
      // ws.requestCurrentState();
    } else {
      ws.stopPing();
      stopMessageListeners();
    }
  }, [ws, isSocketConnected, isUserActive, startMessageListeners, stopMessageListeners]);

  return null;
}

export const SubscriptionManager = () => {
  const ws = useWsApi();
  const dispatch = useDispatch();
  useSubscriptionManager();

  useEffect(() => {
    return () => {
      ws.teardown();
    };
  }, [ws]);

  useEffect(() => {
    const unsubscribe = ws.registerClockListener(({ clockOffset, latency }) => {
      dispatch(spacesActions.onSetClientOffset({ clockOffset, latency }));
    });
    return () => unsubscribe();
  }, [ws, dispatch]);

  return <></>;
};
