import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { doc, onSnapshot } from 'firebase/firestore';

import { integrationActions, userActions } from '~/store/slices';
import { transformFirestoreUser } from '~/subscriptions/transforms';
import { useUser } from '~/store/hooks';

import { firestore } from '../InitFirebase';
import { UserData } from './firestore.types';

function userDataListener(userId: string, callback: (arg: UserData) => void): UnsubcribeFn {
  const userDocRef = doc(firestore, `users/${userId}`);
  const unsubscribe = onSnapshot(userDocRef, (snapshot) => {
    const data = snapshot.data() as UserData;
    if (data) {
      callback(data);
    }
  });
  return unsubscribe;
}

export const useFirestoreUserDataListener = () => {
  const dispatch = useDispatch();

  const { userId } = useUser();

  const onUserData = useCallback(
    (userData: UserData) => {
      const user = transformFirestoreUser(userData);
      dispatch(userActions.onSetUser({ user }));
      dispatch(
        integrationActions.onStravaConnectionState({
          isConnected: userData.strava?.active ?? false,
          hasAuthToken: userData.strava?.authCode ? true : false,
          shouldAutoUploadRaces: userData.strava?.uploadRace ?? true,
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    if (userId) {
      const unsubscribe = userDataListener(userId, onUserData);
      return () => unsubscribe();
    }
  }, [userId, onUserData]);
};
