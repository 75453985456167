import { useEffect, useState } from 'react';
import { WSService } from '../services';

export const useWsAuthedStatus = (ws: Maybe<WSService>) => {
  const [authed, setAuthed] = useState(false);

  useEffect(() => {
    if (ws) {
      const deregister = ws.registerAuthListener((status) => {
        if (status) {
          setAuthed(true);
        } else {
          setAuthed(false);
        }
      });
      return () => deregister();
    }
  }, [ws, setAuthed]);

  return authed;
};
