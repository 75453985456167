export const getScaledDistance = (distance: number, distanceScale: number): number => {
  return distance * distanceScale;
};

export const getScaledVelocity = ({
  velocity,
  theirDistanceScale,
  myDistanceScale,
}: {
  velocity: number;
  theirDistanceScale: number;
  myDistanceScale: number;
}): number => {
  return (velocity / theirDistanceScale) * myDistanceScale;
};

export const getScaledAcceleration = ({
  acceleration,
  theirDistanceScale,
  myDistanceScale,
}: {
  acceleration: number;
  theirDistanceScale: number;
  myDistanceScale: number;
}): number => {
  return (acceleration / theirDistanceScale) * myDistanceScale;
};

export const getPowerMeasure = (scaledVelocity: number, scaledAcceleration: number): number => {
  // want to map the energy to a Error function which approaches -1, 1 at extremes
  // https://en.wikipedia.org/wiki/Error_function
  const SPEED_POWER_SCALE = 2.5;
  const ENERGY_SCALE = 40;
  const energy = Math.pow(scaledVelocity, SPEED_POWER_SCALE) * scaledAcceleration;
  const energyScaled = energy / ENERGY_SCALE;
  const erfValue = erf(energyScaled);
  return erfValue;
};

export const getDistanceCovered = (percentageComplete: number, distance: number): number => {
  return percentageComplete * distance;
};

export const getVelocityKph = (velocityMps: number) => {
  return Math.round(velocityMps * 3.6 * 100) / 100;
};

export function erf(y: number): number {
  // save the sign of y
  var sign = y >= 0 ? 1 : -1;
  y = Math.abs(y);

  var a1 = 0.254829592;
  var a2 = -0.284496736;
  var a3 = 1.421413741;
  var a4 = -1.453152027;
  var a5 = 1.061405429;
  var p = 0.3275911;

  var t = 1.0 / (1.0 + p * y);
  var z = 1.0 - ((((a5 * t + a4) * t + a3) * t + a2) * t + a1) * t * Math.exp(-y * y);

  return sign * z;
}

export const getEstimatedTimeRemaining = ({
  totalDistance,
  distanceCovered,
  velocity,
  timeElapsedMs,
}: {
  totalDistance: number;
  distanceCovered: number;
  velocity: number;
  timeElapsedMs: number;
}) => {
  const distanceRemaining = totalDistance - distanceCovered;
  if (distanceRemaining <= 0) {
    return 0;
  }
  if (distanceRemaining <= 5) {
    return 1000;
  }
  const avgSpeedMps = (distanceCovered || 0.01) / ((timeElapsedMs || 1) / 1000);

  if (velocity > 0) {
    // todo - make velocity a function of how far through you are
    // const progress = (distanceCovered || 0.01) / totalDistance;
    const remainingSeconds = Math.ceil(distanceRemaining / ((velocity + avgSpeedMps) / 2));
    return remainingSeconds * 1000;
  } else {
    const remainingSeconds = Math.ceil(distanceRemaining / avgSpeedMps);
    return remainingSeconds * 1000;
  }
};
