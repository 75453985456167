import { useEffect, useRef } from 'react';
import { Perf, usePerf } from 'r3f-perf';

const timeInFpsInitial = {
  '0to10': 0,
  '10to20': 0,
  '20to30': 0,
  '30to40': 0,
  '40to50': 0,
  '50to60': 0,
  '60+': 0,
};

const PerfHook = () => {
  const { log } = usePerf();
  const timeInFPS = useRef(timeInFpsInitial);
  const ts = useRef<number>(new Date().getTime());

  useEffect(() => {
    const now = new Date().getTime();
    const diff = now - ts.current;
    switch (true) {
      case diff < 10:
        timeInFPS.current['0to10'] += diff;
        break;
      case diff < 20:
        timeInFPS.current['10to20'] += diff;
        break;
      case diff < 30:
        timeInFPS.current['20to30'] += diff;
        break;
      case diff < 40:
        timeInFPS.current['30to40'] += diff;
        break;
      case diff < 50:
        timeInFPS.current['40to50'] += diff;
        break;
      case diff < 60:
        timeInFPS.current['50to60'] += diff;
        break;
      default:
        timeInFPS.current['60+'] += diff;
        break;
    }
  }, [log]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      console.log(timeInFPS.current);
    }, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return null;
};

export const PerfMeasure = () => {
  return (
    <>
      <Perf style={{ top: 60 }} />
      <PerfHook />
    </>
  );
};
