import { useRaceState } from '~/store/hooks';
import { useTimeUntil } from '~/hooks';

export const useRaceTime = () => {
  const { raceStartTime, raceEnded, raceDuration, details } = useRaceState();

  const msUntilRaceStart = useTimeUntil(raceStartTime);

  const raceStartingSoon =
    details?.state === 'countdown' ||
    (!!raceStartTime && msUntilRaceStart <= 10000 && msUntilRaceStart >= 0);
  const raceHasStarted = !!raceStartTime && msUntilRaceStart <= 0;

  return {
    raceStartTime,
    raceStartingSoon,
    raceHasStarted,
    raceHasEnded: raceEnded,
    raceDuration: raceDuration || 0,
    raceClockMs: raceHasStarted ? -msUntilRaceStart : 0,
    raceCountdownMs: msUntilRaceStart,
  };
};
