import { useCallback, useState } from 'react';

import { requestPasswordReset } from '~/module/firebase';
import { Box, Button, Text, Form, FormField, TextInput } from '~/components/common';

const useResetPassword = () => {
  const [email, setEmail] = useState('');

  const onRequestPasswordReset = useCallback((email: string) => {
    return requestPasswordReset({ email });
  }, []);

  return {
    email,
    onChangeEmail: setEmail,
    onRequestPasswordReset,
  };
};

export const ResetPassword: React.FC<{ onNavToSignIn: () => void }> = ({ onNavToSignIn }) => {
  const [passwordResetComplete, setPasswordResetComplete] = useState(false);
  const { email, onChangeEmail, onRequestPasswordReset } = useResetPassword();
  return (
    <Box flex="grow" justify="center" align="center" pad={{ bottom: 'xlarge' }}>
      <Box pad={{ top: 'xlarge' }}>
        <Text tag="h3" textAlign="center" size="xxlarge">
          Reset Password
        </Text>
      </Box>
      <Box
        background={{ light: 'light-2', dark: 'dark-1' }}
        pad="medium"
        round="medium"
        width={{ max: '500px', min: 'medium' }}
      >
        {passwordResetComplete ? (
          <Box>
            <Text>
              Thank you. You will receive an email in the next few minutes with instructions for how
              to reset your password.
            </Text>
            <Box margin={{ top: 'medium' }}>
              <Button onClick={onNavToSignIn} primary label="Go to Login" />
            </Box>
          </Box>
        ) : (
          <Form
            value={{
              email,
            }}
            onChange={({ email }) => {
              onChangeEmail(email);
            }}
            onSubmit={async () => {
              await onRequestPasswordReset(email);
              setPasswordResetComplete(true);
            }}
          >
            <Box>
              <FormField name="email" label="Enter your email address" width="medium">
                <TextInput name="email" />
              </FormField>
            </Box>
            <Box align="center" margin={{ top: 'large' }} gap="medium">
              <Button label="Submit" type="submit" size="large" primary disabled={!email} />
            </Box>
          </Form>
        )}
      </Box>
    </Box>
  );
};
