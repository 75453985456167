import React from 'react';

import { Card } from '~/components/Card';
import { Box, Button, Pill, Text } from '~/components/common';
import { getScaledDistance } from '~/module/physics';
import { Space } from '~/store/slices';

type SpaceListItemProps = {
  space: Space;
  userDistanceScale: number;
  isCurrentActiveSpace?: boolean;
  onNavToSpace: (spaceId: string) => void;
  onNavToObserveSpace: (spaceId: string) => void;
};

export const SpaceListItem: React.FC<SpaceListItemProps> = ({
  space,
  userDistanceScale,
  isCurrentActiveSpace,
  onNavToSpace,
  onNavToObserveSpace,
}) => {
  const isFull = space.currentParticipantCount === space.details.fieldSize;
  const isWaiting = space.details.state === 'awaiting';
  const canJoin =
    (!isFull && isWaiting) || (isCurrentActiveSpace && space.details.state === 'inplay');
  const canWatch =
    space.currentParticipantCount > 0 ||
    space.details.state === 'countdown' ||
    space.details.state === 'inplay';

  const useDistanceScale = space.details.useDistanceScale;

  return (
    <Card background={isCurrentActiveSpace ? 'brand' : undefined}>
      <Box direction="row" justify="between">
        <Box margin={{ right: 'medium' }} gap="xsmall">
          <Box direction="row" gap="small" align="center">
            <Text size="large" weight="bold" uppercase>
              {space.id}
            </Text>
            <Pill label={`${space.details.duration}min`} />
            <Pill label={`${space.details.distance / 1000}km`} />
            {!useDistanceScale && <Pill label="No Handicap" />}
          </Box>
          {useDistanceScale && userDistanceScale !== 1 && (
            <Box align="center" direction="row" gap="small">
              <Text size="small">Scaled Distance:</Text>
              <Text size="small">
                {Math.round(getScaledDistance(space.details.distance, userDistanceScale)) / 1000}
                km
              </Text>
            </Box>
          )}
        </Box>
        <Box justify="center" gap="small" width="small">
          <Text textAlign="center">
            {isWaiting ? (
              <>
                {`${space.currentParticipantCount}/${space.details.fieldSize}`} -{' '}
                {isFull ? 'Race Full' : 'Waiting for players'}
              </>
            ) : space.details.state === 'finished' ? (
              'Race Complete'
            ) : (
              'Race in progress'
            )}
          </Text>
          <Button
            primary
            onClick={() => onNavToSpace(space.id)}
            label={isCurrentActiveSpace ? 'Re-join race' : 'Join Race'}
            disabled={!canJoin}
          />
          {canWatch && (
            <Button secondary onClick={() => onNavToObserveSpace(space.id)} label={'Watch Race'} />
          )}
        </Box>
      </Box>
    </Card>
  );
};
