import { useCallback, useEffect, useMemo } from 'react';
import { DailyProvider } from '@daily-co/daily-react-hooks';

import { Box, Button, Spinner, Text } from '~/components/common';
import {
  useActiveSpace,
  useAuth,
  useAvailableSpaces,
  useDevSwitches,
  useUser,
} from '~/store/hooks';
import { getScaledDistance } from '~/module/physics';
import { useSimpleAvCall } from '~/features/AudioVideo/hooks';

import { ActiveRace, RaceOver, RaceLobby } from './Race';
import { useRaceTime } from '../hooks';
import { RiderParticipantSummary } from '../Race.types';
import { useWsApi, useWsAuthedStatus } from '~/module/api';

type SpaceProps = {
  id: string;
  onNavToHome: () => void;
  onNavToObserveSpace: (spaceId: string) => void;
  onNavToSpace: (spaceId: string) => void;
  onNavToRaceList: () => void;
};

export const Space = ({
  id,
  onNavToHome,
  onNavToSpace,
  onNavToObserveSpace,
  onNavToRaceList,
}: SpaceProps) => {
  const { userId, user, userDistanceScale } = useUser();
  const { authenticated, authStateKnown } = useAuth();
  const wsAuthed = useWsAuthedStatus(useWsApi());
  const { activeSpace, activeSpaceParticipants, joining, error, onJoinSpace, onLeaveSpace } =
    useActiveSpace();
  const { raceHasStarted, raceStartingSoon, raceHasEnded, raceDuration } = useRaceTime();
  const { enableAv } = useDevSwitches();
  const { availableSpaces } = useAvailableSpaces();

  const canJoinSpace = authStateKnown && authenticated && wsAuthed;
  const isSpaceStateReady = !!activeSpace && activeSpace.id === id;
  const useDistanceScale = activeSpace?.details.useDistanceScale;

  const ridersSummary = useMemo((): Array<RiderParticipantSummary> => {
    if (activeSpace) {
      const { raceEndRiderSummary } = activeSpace;
      return raceEndRiderSummary.map((rider) => {
        const p = activeSpaceParticipants[rider.id];
        return {
          ...rider,
          ...p,
          assignedColor: p?.riderColour,
          distanceDelta: useDistanceScale
            ? getScaledDistance(rider.distanceDelta, userDistanceScale)
            : rider.distanceDelta,
        };
      });
    }
    return [];
  }, [activeSpace, activeSpaceParticipants, userDistanceScale, useDistanceScale]);

  const leaveSpace = useCallback(() => {
    onNavToRaceList();
  }, [onNavToRaceList]);

  useEffect(() => {
    if (canJoinSpace && id) {
      onJoinSpace(id);
      return () => {
        onLeaveSpace(id);
      };
    }
  }, [onJoinSpace, onLeaveSpace, id, canJoinSpace]);

  const { callObject, appState } = useSimpleAvCall(id, userId, enableAv);
  const isAvReady = enableAv && appState > 0;

  useEffect(() => {
    if (callObject) {
      callObject.join();
      return () => {
        callObject.leave();
      };
    }
  }, [callObject]);

  if (joining) {
    return (
      <Box align="center" justify="center" flexNum={1}>
        <Spinner />
      </Box>
    );
  }

  if (error) {
    return (
      <Box align="center" justify="center" flexNum={1}>
        {error.code === 'ROOM_IS_CLOSED' ? (
          <Box>
            <Text color="status-error">Racing is underway.</Text>
            <Button onClick={() => onNavToObserveSpace(id)} label="Watch race" />
          </Box>
        ) : (
          <Text color="status-error">{error.message}</Text>
        )}
      </Box>
    );
  }

  if (!isSpaceStateReady) {
    return (
      <Box align="center" justify="center" flexNum={1}>
        <Spinner />
      </Box>
    );
  }

  if (raceHasEnded) {
    return (
      <DailyProvider callObject={callObject ?? undefined}>
        <RaceOver
          ridersSummary={ridersSummary}
          raceDuration={raceDuration}
          observerUserId={user?.userId}
          hasAv={isAvReady}
          nextUpRaces={availableSpaces.filter((s) => s.id !== activeSpace.id)}
          onNavToHome={onNavToHome}
          onNavToSpace={onNavToSpace}
          onNavToObserveSpace={onNavToObserveSpace}
        />
      </DailyProvider>
    );
  }

  if (raceHasStarted || raceStartingSoon) {
    return (
      <DailyProvider callObject={callObject ?? undefined}>
        <ActiveRace hasAv={isAvReady} />
      </DailyProvider>
    );
  }

  return (
    <DailyProvider callObject={callObject ?? undefined}>
      <RaceLobby
        id={id}
        participants={activeSpaceParticipants}
        maxRiders={activeSpace.details.fieldSize}
        hasAv={isAvReady}
        leaveSpace={leaveSpace}
        {...activeSpace.details}
      />
    </DailyProvider>
  );
};
