import { initializeApp, FirebaseApp } from 'firebase/app';
import { initializeAuth, Auth, EmailAuthProvider, browserLocalPersistence } from 'firebase/auth';
import { getFirestore, Firestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCSdzELmY-s24cW6KlHPShsRRpGTyAWLtA',
  authDomain: 'critz-1d415.firebaseapp.com',
  projectId: 'critz-1d415',
  storageBucket: 'critz-1d415.appspot.com',
  messagingSenderId: '681691842788',
  appId: '1:681691842788:web:3ebe8e51c0850ef26104f1',
  measurementId: 'G-LPQHJZ1N2G',
};

export let app: FirebaseApp;
export let auth: Auth;
export let firestore: Firestore;

export const FirebaseAuthOptions = [EmailAuthProvider.PROVIDER_ID];

export const initFirebase = () => {
  app = initializeApp(firebaseConfig);
  auth = initializeAuth(app, { persistence: [browserLocalPersistence] });
  firestore = getFirestore(app);
};
