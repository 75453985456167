import { useParticipant } from '@daily-co/daily-react-hooks';
import { BoxProps } from 'grommet';
import { Box, Icon, Text } from '~/components/common';
import { AudioTrack } from './AudioTrack';
import { VideoTile } from './VideoTile';

export type ParticipantVideoProps = {
  id: string;
  audioEnabled?: boolean;
  videoEnabled?: boolean;
  mirrorVideo?: boolean;
};

export const ParticipantVideo = ({
  id,
  audioEnabled = true,
  videoEnabled = true,
  mirrorVideo = false,
  ...boxProps
}: ParticipantVideoProps & BoxProps) => {
  const participant = useParticipant(id);

  const audioDisabled = !participant?.audio;
  const videoDisabled = !participant?.video;

  return (
    <Box
      {...boxProps}
      background="background-front"
      overflow="hidden"
      style={{ position: 'relative' }}
    >
      {videoEnabled && participant?.video && (
        <VideoTile
          id={id}
          style={{
            objectFit: 'cover',
            transform: mirrorVideo ? 'rotateY(180deg)' : '',
          }}
        />
      )}
      {audioEnabled && participant?.audio && <AudioTrack id={id} />}

      {(videoDisabled || audioDisabled) && (
        <Box
          style={{ position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 }}
          align="center"
          justify="end"
          gap="small"
          pad="small"
        >
          {audioDisabled && (
            <Box direction="row" align="center">
              <Icon iconName="microphone" color="text-weak" size="small" />
              <Text size="xsmall" margin={{ left: 'xsmall' }}>
                Muted
              </Text>
            </Box>
          )}
          {videoDisabled && (
            <Box direction="row" align="center">
              <Icon iconName="camera" color="text-weak" size="small" />
              <Text size="xsmall" margin={{ left: 'xsmall' }}>
                Video disabled
              </Text>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};
