import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  absoluteUrls,
  DynamicURLTypes,
  getPathToSpace,
  getPathToObserveSpace,
} from '~/navigation/Navigation.paths';
import { SceneComponent } from '~/navigation/Navigation.types';
import { Space } from '~/features/Spaces';
import { Box } from '~/components/common';
import { Helmet } from 'react-helmet';

export const SpaceScene: SceneComponent<{}> = () => {
  const { spaceId: spaceIdUrl } = useParams<DynamicURLTypes['space']>();
  const spaceId = spaceIdUrl?.toLowerCase();
  const navigate = useNavigate();

  const onNavToHome = useCallback(() => {
    navigate(absoluteUrls.home);
  }, [navigate]);

  const onNavToSpace = useCallback(
    (spaceId: string) => {
      navigate(getPathToSpace({ spaceId }));
    },
    [navigate],
  );

  const onNavToObserveSpace = useCallback(
    (spaceId: string) => {
      navigate(getPathToObserveSpace({ spaceId }));
    },
    [navigate],
  );

  const onNavToRaceList = useCallback(() => {
    navigate(absoluteUrls.findRace);
  }, [navigate]);

  if (!spaceId) {
    return null;
  }

  return (
    <>
      <Helmet>
        <title>{spaceId}</title>
      </Helmet>
      <Box style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <Space
          id={spaceId}
          onNavToHome={onNavToHome}
          onNavToSpace={onNavToSpace}
          onNavToObserveSpace={onNavToObserveSpace}
          onNavToRaceList={onNavToRaceList}
        />
      </Box>
    </>
  );
};
