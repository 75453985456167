import { Box, Button, Icon, Pill, Text } from '~/components/common';
import { useConnectedDevices } from '~/module/connectedDevices/context';

export const ConnectDevices = () => {
  const {
    controllable,
    controllableConnected,
    power,
    cadence,
    onConnectControllable,
    heartRateMonitor,
    heartRateMonitorConnected,
    heartRate,
    onConnectHRM,
  } = useConnectedDevices();

  const controllableStatusColor = controllableConnected ? 'status-ok' : 'status-error';
  const heartRateMonitorStatusColor = heartRateMonitorConnected ? 'status-ok' : 'status-error';

  return (
    <Box margin={{ top: 'medium' }}>
      <Box gap="large">
        <Box gap="medium" elevation="small" round pad="medium">
          <Box direction="row" align="center" justify="between">
            <Box>
              <Text size="2xl">Indoor Trainer</Text>
            </Box>
            <Box>
              <Pill
                label={controllableConnected ? 'Connected' : 'Not Connected'}
                color={controllableStatusColor}
              />
            </Box>
          </Box>
          {!!controllable && (
            <Box margin={{ bottom: 'small' }}>
              <Text>{controllable.name}</Text>
              <Box
                align="center"
                margin={{ vertical: 'medium' }}
                direction="row"
                justify="center"
                gap="medium"
              >
                {controllableConnected && <Text size="4xl">{power}W</Text>}
                {controllableConnected && <Text size="4xl">{cadence}RPM</Text>}
              </Box>
            </Box>
          )}
          {!controllableConnected && (
            <Button
              onClick={onConnectControllable}
              primary
              label="Search for device"
              size="large"
              icon={<Icon iconName="bluetooth" />}
            />
          )}
        </Box>
        <Box gap="medium" elevation="small" round pad="medium">
          <Box direction="row" align="center" justify="between">
            <Box>
              <Text size="2xl">Heart Rate Monitor</Text>
            </Box>
            <Box>
              <Pill
                label={heartRateMonitorConnected ? 'Connected' : 'Not Connected'}
                color={heartRateMonitorStatusColor}
              />
            </Box>
          </Box>
          {!!heartRateMonitor && (
            <Box margin={{ bottom: 'small' }}>
              <Text>{heartRateMonitor.name}</Text>
              <Box align="center" margin={{ vertical: 'medium' }}>
                {heartRateMonitorConnected && <Text size="4xl">{heartRate} BPM</Text>}
              </Box>
            </Box>
          )}
          {!heartRateMonitorConnected && (
            <Button
              onClick={onConnectHRM}
              primary
              label="Search for device"
              size="large"
              icon={<Icon iconName="bluetooth" />}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
