import { TextProps } from 'grommet';
import { FC, useEffect, useState } from 'react';

import { Text } from '~/components/common';
import { msToTime } from '~/module/utils';

export const useMsUntil = (ms: number) => {
  const [now, setNow] = useState(Number(new Date()));

  useEffect(() => {
    setInterval(() => {
      setNow(Number(new Date()));
    }, 1000);
  }, []);

  return ms - now;
};

const labels = {
  default: {
    days: [' day', ' days'],
    hours: [' hour', ' hours'],
    minutes: [' minute', ' minutes'],
    seconds: [' second', ' seconds'],
  },
  concise: {
    days: ['d', 'd'],
    hours: ['h', 'h'],
    minutes: ['m', 'm'],
    seconds: ['s', 's'],
  },
};

export const DateCountdown: FC<
  {
    date: number;
    concise?: boolean;
    showDays?: boolean;
    showHours?: boolean;
    showMinutes?: boolean;
    showSeconds?: boolean;
  } & TextProps
> = ({
  date,
  concise = false,
  showDays = true,
  showHours = true,
  showMinutes = true,
  showSeconds = true,
  ...textProps
}) => {
  const msUntil = useMsUntil(date);
  const { days, hours, minutes, seconds } = msToTime(msUntil);

  const hasDays = days > 0;
  const hasHours = hasDays || hours > 0;
  const hasMinutes = hasDays || hasHours || minutes > 0;
  const hasSeconds = hasDays || hasHours || hasMinutes || seconds > 0;

  const labelsToUse = concise ? labels.concise : labels.default;

  return (
    <Text {...textProps}>
      {hasDays && showDays && (
        <>
          {days}
          {days === 1 ? labelsToUse.days[0] : labelsToUse.days[1]}
          {showHours && ', '}
        </>
      )}
      {hasHours && showHours && (
        <>
          {hours}
          {hours === 1 ? labelsToUse.hours[0] : labelsToUse.hours[1]}
          {showMinutes && ', '}
        </>
      )}
      {hasMinutes && showMinutes && (
        <>
          {minutes}
          {minutes === 1 ? labelsToUse.minutes[0] : labelsToUse.minutes[1]}
          {showSeconds && ', '}
        </>
      )}
      {hasSeconds && showSeconds && (
        <>
          {seconds}
          {seconds === 1 ? labelsToUse.seconds[0] : labelsToUse.seconds[1]}
        </>
      )}
    </Text>
  );
};
