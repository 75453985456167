import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useWsSpaceActions } from '~/module/api/hooks/useWsSpaceActions';

import { spacesSelectors } from '../slices';

export function useActiveSpace() {
  const activeSpace = useSelector(spacesSelectors.getActiveSpace);
  const spaceParticipants = useSelector(spacesSelectors.getRaceParticipants);

  const activeSpaceParticipants = useMemo(() => {
    if (activeSpace && spaceParticipants[activeSpace.id]) {
      return spaceParticipants[activeSpace.id].participants;
    }
    return {};
  }, [activeSpace, spaceParticipants]);

  const {
    isJoiningSpace: joining,
    isPendingObservingSpace: pendingObserve,
    joinSpaceError,
    observeSpaceError,
    onJoinSpace,
    onLeaveSpace,
    onObserveSpace,
    onStopObservingSpace,
  } = useWsSpaceActions();

  return {
    joining: joining || pendingObserve,
    error: joinSpaceError || observeSpaceError,
    activeSpace,
    activeSpaceParticipants,
    onJoinSpace,
    onLeaveSpace,
    onObserveSpace,
    onStopObservingSpace,
  };
}
