import React from 'react';

import { Box } from '~/components/common';
import { Space } from '~/store/slices';

import { SpaceListItem } from './SpaceListItem';

type AvailableSpacesSceneProps = {
  onNavToSpace: (spaceId: string) => void;
  onNavToObserveSpace: (spaceId: string) => void;
};

type AvailableSpacesProps = AvailableSpacesSceneProps & {
  userDistanceScale: number;
  currentActiveRace?: string | null;
  availableSpaces: Space[];
};

export const AvailableSpaces: React.FC<AvailableSpacesProps> = ({
  userDistanceScale,
  currentActiveRace,
  availableSpaces,
  onNavToSpace,
  onNavToObserveSpace,
}) => {
  if (!availableSpaces.length) return null;
  return (
    <Box wrap direction="row" justify="start">
      {availableSpaces.map((space) => {
        const isCurrentActiveSpace = space.id === currentActiveRace;
        return (
          <Box width="large" key={space.id} margin="small">
            <SpaceListItem
              space={space}
              userDistanceScale={userDistanceScale}
              isCurrentActiveSpace={isCurrentActiveSpace}
              onNavToSpace={onNavToSpace}
              onNavToObserveSpace={onNavToObserveSpace}
            />
          </Box>
        );
      })}
    </Box>
  );
};
