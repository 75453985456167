import { fec } from './fec';
import { message } from './ant-message';

function dataviewToArray(dataview) {
  return Array.from(new Uint8Array(dataview.buffer));
}

export function FEC2() {
  function dataPageDecoder(dataview) {
    const pageNumber = dataview.getUint8(0, true);

    if (pageNumber === fec.dataPage16.number) {
      return fec.dataPage16.decode(dataview);
    }
    if (pageNumber === fec.dataPage25.number) {
      return fec.dataPage25.decode(dataview);
    }
    // manufacturer specific data pages (page numbers 240 - 255)
    // transmit additional parameters to calculate total resistance in SIM mode
    // not interoperable, should only be used to supplement
    // 240 -> [240, 0, 0, 0, 0, 0, 0, 0]
    // 249 -> [249, 0, 0, 0, 218, 178, 4, 0]
    //  80 -> [80, 255, 255, 1, 89, 0, 84, 11]
    // ? 81 missing in transmission

    return dataviewToArray(dataview);
  }

  function decode(dataview) {
    const msg = message.acknowledgedData.decode(dataview, dataPageDecoder);

    if (msg.payload) {
      return msg.payload;
    }
    return {};
  }

  return Object.freeze({
    decode,
  });
}
