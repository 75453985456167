import { FC, PropsWithChildren } from 'react';

import { Box, Icon, Text } from '~/components/common';

export const ModalSceneContainer: FC<
  PropsWithChildren<{ title: string; onCloseModal: () => void }>
> = ({ children, title, onCloseModal }) => {
  return (
    <Box className="ModalSceneContainer" pad="medium">
      <Box direction="row" justify="between" align="center" margin={{ bottom: 'medium' }}>
        <Box>
          <Text size="xxlarge" weight="bold">
            {title}
          </Text>
        </Box>
        <Box onClick={onCloseModal} pad="xsmall">
          <Icon iconName="close" />
        </Box>
      </Box>
      <Box flex={false}>{children}</Box>
    </Box>
  );
};
