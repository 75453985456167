import { useCallback, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { absoluteUrls, DynamicURLTypes } from '~/navigation/Navigation.paths';
import { SceneComponent } from '~/navigation/Navigation.types';
import { UserRaceHistoryDetail } from '~/features/History/components/UserRaceHistoryDetail';

import { BreadCrumbs } from '../layout/Content';
import { Scene } from '../layout/Scene';

export const RaceHistoryItemScene: SceneComponent<{}> = () => {
  const { raceId } = useParams<DynamicURLTypes['userRaceHistoryItem']>();
  const navigate = useNavigate();

  const onNavToRaceHistory = useCallback(() => {
    navigate(absoluteUrls.userRaceHistory);
  }, [navigate]);

  useEffect(() => {
    if (!raceId) {
      navigate(absoluteUrls.notFound);
    }
  }, [raceId, navigate]);

  const raceName = raceId?.split('-').slice(1, 3).join('-');

  return (
    <Scene title={`${raceId}`}>
      <BreadCrumbs
        crumbs={[
          { label: 'Race History', active: false, onClick: onNavToRaceHistory },
          { label: raceName?.toUpperCase() ?? 'Race', active: true },
        ]}
      />
      {!!raceId && <UserRaceHistoryDetail raceId={raceId} />}
    </Scene>
  );
};
