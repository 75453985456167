import { normalizeColor } from 'grommet/utils';

import { useDisplayMode } from '~/store/hooks';

import { PrimaryColor } from '../theme';
import { useTheme } from '../useTheme';
import { ChartConfig, getVictoryChartTheme } from './VictoryTheme';

export const useVictoryChartsTheme = (config?: Partial<ChartConfig>) => {
  const theme = useTheme();
  const { displayMode } = useDisplayMode();

  const colorLight = normalizeColor(config?.primaryColor || PrimaryColor.light, theme);
  const colorDark = normalizeColor(config?.primaryColor || PrimaryColor.dark, theme);

  const chartConfig: ChartConfig = {
    displayMode,
    primaryColor: displayMode === 'light' ? colorLight : colorDark,
  };

  return getVictoryChartTheme(theme, chartConfig);
};
