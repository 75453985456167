import {
  Moon,
  Sun,
  Bike,
  ContactInfo,
  Bluetooth,
  Search,
  Edit,
  Close,
  Flag,
  Play,
  Aed as Heart,
  Download,
  Camera,
  Microphone,
  Volume,
} from 'grommet-icons';

export type IconName =
  | 'light-mode'
  | 'dark-mode'
  | 'bike'
  | 'user'
  | 'bluetooth'
  | 'search'
  | 'edit'
  | 'close'
  | 'flag'
  | 'play'
  | 'heart'
  | 'download'
  | 'microphone'
  | 'camera'
  | 'volume';

interface IIcon {
  iconName: IconName;
  size?: 'small' | 'medium' | 'large';
  color?: string;
  onClick?: () => void;
}

export function Icon({ iconName, size = 'medium', color, onClick }: IIcon) {
  const props = { size, onClick, color };

  switch (iconName) {
    case 'dark-mode':
      return <Moon {...props} />;

    case 'light-mode':
      return <Sun {...props} />;

    case 'bike':
      return <Bike {...props} />;

    case 'user':
      return <ContactInfo {...props} />;

    case 'bluetooth':
      return <Bluetooth {...props} />;

    case 'search':
      return <Search {...props} />;

    case 'edit':
      return <Edit {...props} />;

    case 'close':
      return <Close {...props} />;

    case 'flag':
      return <Flag {...props} />;

    case 'play':
      return <Play {...props} />;

    case 'heart':
      return <Heart {...props} />;

    case 'download':
      return <Download {...props} />;

    case 'camera':
      return <Camera {...props} />;

    case 'microphone':
      return <Microphone {...props} />;

    case 'volume':
      return <Volume {...props} />;

    default:
      return null;
  }
}
