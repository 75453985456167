import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { collection, doc, onSnapshot, orderBy, query } from 'firebase/firestore';
import _orderBy from 'lodash/orderBy';

import { tournamentsActions } from '~/store/slices';
import {
  transformFirestoreTournament,
  transformFirestoreTournamentDetail,
} from '~/subscriptions/transforms';
import { Tournament } from '~/store/slices/tournamentsSlice.types';

import { firestore } from '../InitFirebase';
import { Tournament as TournamentDB } from './firestore.types';

// todo - add paging, e.g. split into future and past

function tournamentsListener(callback: (d: TournamentDB[]) => void): UnsubcribeFn {
  const collectionRef = collection(firestore, `tournaments`);
  const q = [orderBy('startTime', 'desc')];

  const unsubscribe = onSnapshot(query(collectionRef, ...q), (snapshot) => {
    const items = snapshot.docs.map((doc) => {
      return doc.data() as TournamentDB;
    });
    if (items) {
      callback(items);
    }
  });

  return unsubscribe;
}

export const useFirestoreTournamentsListener = () => {
  const dispatch = useDispatch();

  const onDataUpdated = useCallback(
    (items: TournamentDB[]) => {
      const arr: Tournament[] = [];
      items.forEach((item) => {
        arr.push(transformFirestoreTournament(item));
      });
      dispatch(
        tournamentsActions.onTournamentListUpdated({
          tournaments: _orderBy(arr, ['startTime'], ['desc']),
        }),
      );
    },
    [dispatch],
  );

  useEffect(() => {
    const unsubscribe = tournamentsListener(onDataUpdated);
    return () => unsubscribe();
  }, [onDataUpdated]);
};

export function tournamentDetailListener(
  tournamentId: string,
  callback: (d: TournamentDB) => void,
): UnsubcribeFn {
  const docRef = doc(firestore, `tournaments/${tournamentId}`);

  const unsubscribe = onSnapshot(docRef, (snapshot) => {
    if (snapshot && snapshot.exists()) {
      callback(snapshot.data() as TournamentDB);
    }
  });

  return unsubscribe;
}

export const useFirestoreTournamentListener = (tournamentId: string) => {
  const dispatch = useDispatch();

  const onDataUpdated = useCallback(
    (item: TournamentDB) => {
      dispatch(
        tournamentsActions.onTournamentDetailUpdated({
          tournamentId,
          detail: transformFirestoreTournamentDetail(item),
        }),
      );
    },
    [dispatch, tournamentId],
  );

  useEffect(() => {
    const unsubscribe = tournamentDetailListener(tournamentId, onDataUpdated);
    return () => unsubscribe();
  }, [tournamentId, onDataUpdated]);
};
