import { useCallback, useState } from 'react';

import { signUp } from '~/module/firebase';
import { Box, Button, Text, Form, FormField, TextInput, AnchorLink } from '~/components/common';
import { absoluteUrls } from '~/navigation/Navigation.paths';
import { Logo } from '~/components/Logo';

const useSignUp = ({ onSignInSuccess }: { onSignInSuccess: () => void }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>('');

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    try {
      await signUp({ email, password });
      onSignInSuccess();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message.replace('Firebase: ', ''));
      }
    }
    setSubmitting(false);
  }, [email, password, onSignInSuccess]);

  return {
    email,
    onChangeEmail: setEmail,
    password,
    onChangePassword: setPassword,
    submitting,
    error,
    onSubmit,
  };
};

export const SignUp: React.FC<{
  onSignInSuccess: () => void;
  onNavToSignIn: () => void;
}> = ({ onSignInSuccess, onNavToSignIn }) => {
  const { email, password, submitting, error, onChangeEmail, onChangePassword, onSubmit } =
    useSignUp({ onSignInSuccess });
  return (
    <Box flex="grow" justify="center" align="center" pad={{ bottom: 'xlarge' }} flexNum={1}>
      <Logo margin={{ bottom: 'small' }} width={300} />
      <Box
        background="background-contrast"
        pad="medium"
        round="medium"
        width={{ max: '500px', min: 'medium' }}
      >
        <Box margin={{ vertical: 'medium' }}>
          <Text size="xlarge" textAlign="start">
            Create an account
          </Text>
        </Box>
        <Form
          value={{
            email,
            password,
          }}
          onChange={({ email, password }) => {
            onChangeEmail(email);
            onChangePassword(password);
          }}
          onSubmit={onSubmit}
        >
          <Box>
            <FormField name="email" label="Email" width="medium">
              <TextInput name="email" />
            </FormField>
            <FormField name="password" label="Choose password" width="medium">
              <TextInput type="password" name="password" />
            </FormField>
          </Box>
          <Box align="center" margin={{ top: 'medium' }} gap="medium">
            <Box>
              <Button label="Sign Up" type="submit" size="large" primary disabled={submitting} />
              {!!error && (
                <Text margin={{ top: 'small' }} color="status-error">
                  {error}
                </Text>
              )}
            </Box>
          </Box>
        </Form>
      </Box>
      <Box margin={{ vertical: 'medium' }}>
        <AnchorLink to={absoluteUrls.signIn} label="Already a member? Sign In" size="small" />
      </Box>
    </Box>
  );
};
