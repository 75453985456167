import { createRef, FC, RefObject, useRef, useMemo, MutableRefObject, useEffect } from 'react';
import * as THREE from 'three';
import { useFrame, useThree } from '@react-three/fiber';
import _sortBy from 'lodash/sortBy';

import { RaceRider, RaceRiders } from '../../Race.types';
import { Rider } from './ThreeRider';
import { getNewSpeed } from './ThreePhysics';
import { reportError } from '~/module/logging';

// TODO - move all this to a nice config file with some descriptors
const LATERALSPEED = 1 / 50;
const DRIFT_TO_CENTRE = 1 / 50;
const DRAFT_SCALE = 0.6;
const MIN_DRAFT_EFFICIENCY_FOR_VISUAL = 0.15;

const OBSEVER_DEFAULT_COLOUR = 0xff10f0;
const OTHER_RIDER_DEFAULT_COLOUR = 0x00a1e4;

const glowEmissiveNeutral = 0.25;
const glowEmissiveUpper = 0.9;
const glowEmissiveLower = 0.05;

const startPositionJumpPath = [3 / 25, -3 / 25, 6 / 25];

function getStartPositions(otherRiders: RaceRider[]): number[] {
  const isOdd = otherRiders.length % 2;
  let startPositions: number[] = [];

  let observerStart = 0;
  if (isOdd) {
    observerStart = -1.5 / 25;
  }
  startPositions.push(observerStart);
  otherRiders.forEach((rider, index) => {
    startPositions.push(observerStart + startPositionJumpPath[index]);
  });

  return startPositions;
}

function glowStrengthCalc(riderErf: number, currentIntensity: number): number {
  let target = glowEmissiveNeutral;
  if (riderErf < 0) {
    target = THREE.MathUtils.lerp(glowEmissiveLower, glowEmissiveNeutral, riderErf + 1);
  } else if (riderErf > 0) {
    target = THREE.MathUtils.lerp(glowEmissiveNeutral, glowEmissiveUpper, riderErf);
  }
  const frameTarget = THREE.MathUtils.lerp(target, currentIntensity, 0.98);
  return frameTarget;
}

function blobSizeCalc(targetDraftEfficiency: number, currentScale: number): number {
  const draftEffect =
    targetDraftEfficiency > MIN_DRAFT_EFFICIENCY_FOR_VISUAL ? targetDraftEfficiency : 0;
  const targetSize = 1 - (1 - DRAFT_SCALE) * draftEffect;
  return THREE.MathUtils.lerp(targetSize, currentScale, 0.98);
}

function glowStrength(
  clientRiderRef: RefObject<THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>>,
  otherRidersRefs: React.MutableRefObject<{
    [key: string]: RefObject<THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>>;
  }>,
  otherRiders: RaceRider[],
  observer: RaceRider,
) {
  // for each rider, let's make the intensity of their glow proportional to the acceleration power
  // emmissive intensity is baseline 0.25
  let riderErf = observer?.powerErf || 0;
  let myMaterial = clientRiderRef.current!.material as THREE.MeshLambertMaterial;
  let currentIntensity = myMaterial.emissiveIntensity;
  let frameTarget = glowStrengthCalc(riderErf, currentIntensity);

  (clientRiderRef.current!.material as THREE.MeshLambertMaterial).emissiveIntensity = frameTarget;

  otherRiders.forEach((rider, index) => {
    riderErf = rider.powerErf;
    const riderRef = otherRidersRefs.current[rider.id]?.current;
    if (riderRef) {
      currentIntensity = (riderRef.material as THREE.MeshLambertMaterial).emissiveIntensity;
      frameTarget = glowStrengthCalc(riderErf, currentIntensity);
      (riderRef.material as THREE.MeshLambertMaterial).emissiveIntensity = frameTarget;
    }
  });
}

function setBlobSize(
  clientRiderRef: RefObject<THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>>,
  otherRidersRefs: React.MutableRefObject<{
    [key: string]: RefObject<THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>>;
  }>,
  otherRiders: RaceRider[],
  observer: RaceRider,
) {
  const myRef = clientRiderRef.current!;
  const myTargetDraftEfficiency = observer?.draftEfficiency || 0;
  const myBlobScale = myRef.scale;
  const myBlobSize = blobSizeCalc(myTargetDraftEfficiency, myBlobScale.x);
  myRef.scale.set(myBlobSize, myBlobSize, myBlobSize);

  otherRiders.forEach((rider, index) => {
    const riderRef = otherRidersRefs.current[rider.id]?.current;
    if (riderRef) {
      const targetDraftEfficiency = rider.draftEfficiency;
      const blobScale = riderRef.scale;
      const blobSize = blobSizeCalc(targetDraftEfficiency, blobScale.x);
      riderRef.scale.set(blobSize, blobSize, blobSize);
    }
  });
}

function moveLaterally(
  clientRiderRef: RefObject<THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>>,
  otherRidersRefs: React.MutableRefObject<{
    [key: string]: RefObject<THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[]>>;
  }>,
  otherRiders: RaceRider[],
  observer: RaceRider,
  delta: number,
) {
  // A collection of measures we need in a simple array ordered [observer, competitor 0, competitor 1, ...]
  // everyone wants to move to the zero track (centre), but can't if blocked by other elements

  let riderXValues: number[] = [];
  let riderZValues: number[] = [];
  let riderLeftLimits: number[] = [];
  let riderRightLimits: number[] = [];
  let speeds: number[] = [];
  let riderIds: string[] = [];
  let riderDeltas: number[] = [];

  riderXValues.push(clientRiderRef.current!.position.x);
  riderZValues.push(clientRiderRef.current!.position.z);
  speeds.push(observer.scaledVelocity);
  riderIds.push('Observer');
  riderDeltas.push(0);

  otherRiders.forEach((rider, index) => {
    const riderRef = otherRidersRefs.current[rider.id]?.current;
    if (riderRef) {
      riderXValues.push(riderRef.position.x);
      riderZValues.push(riderRef.position.z);
      speeds.push(otherRiders[index].scaledVelocity);
      riderIds.push(otherRiders[index].id);
      riderDeltas.push(0);
    }
  });

  const riderCount = riderZValues.length;
  let pairwiseLeftLimits = Array.from(
    Array<number>(riderCount),
    () => new Array<number>(riderCount),
  );
  let pairwiseRightLimits = Array.from(
    Array<number>(riderCount),
    () => new Array<number>(riderCount),
  );

  for (var i = 0; i < riderCount; ++i) {
    // for each rider
    let leftLimit = -100;
    let rightLimit = 100;
    for (var j = 0; j < riderCount; ++j) {
      // for each competitor
      if (i !== j) {
        // pairwise comparison of rider i and rider j
        let currentXPosition = riderXValues[i];
        let currentZPosition = riderZValues[i];
        let competitorXPosition = riderXValues[j];
        let competitorZPosition = riderZValues[j];
        pairwiseLeftLimits[i][j] = leftLimit;
        pairwiseRightLimits[i][j] = rightLimit;
        // when we're approaching a slow moving competitor we don't start moving laterally early enough
        // if we have the distance for overlapping fixed at 2/25
        // as such, we need to start moving earlier based on our speed differential
        // at a first guess, when our speed differential is <1 then it's OK to move at 2/25
        // let's just linearly scale that up so we move earlier the faster we're going
        const speedDiffAbs = Math.abs(speeds[i] - speeds[j]);
        const moveAtZDiff = Math.max(0.5 * speedDiffAbs * (2 / 25), 2 / 25); //0.5 is arbitrary value of when to go
        // the moveAtZDiff value used to be fixed at 2/25
        if (Math.abs(currentZPosition - competitorZPosition) < moveAtZDiff) {
          // overlapping wheel
          if (competitorXPosition <= currentXPosition) {
            // competitor on our left (or directly in front), could be blocking
            let thisLeftLimit = competitorXPosition + 3 / 25;
            pairwiseLeftLimits[i][j] = thisLeftLimit;
            if (thisLeftLimit > leftLimit) {
              leftLimit = thisLeftLimit;
            }
          } else if (competitorXPosition >= currentXPosition) {
            // competitor on our right (or directly in front)
            let thisRightLimit = competitorXPosition - 3 / 25;
            pairwiseRightLimits[i][j] = thisRightLimit;
            if (thisRightLimit < rightLimit) {
              rightLimit = thisRightLimit;
            }
          }
        }
      }
    }
    // right, at this point we know we're overlapping wheels with someone, and
    // we have a range of lateral movement we can make
    riderLeftLimits.push(leftLimit);
    riderRightLimits.push(rightLimit);
  }

  for (i = 0; i < riderCount; ++i) {
    let target = 0;
    // if we're on the right, head as far left as we can, stopping at 0 if we can
    if (riderXValues[i] > 0) {
      target = Math.max(riderLeftLimits[i], 0);
    }
    // if we're on the left, head as far right as we can, stopping at 0 if we can
    if (riderXValues[i] < 0) {
      target = Math.min(riderRightLimits[i], 0);
    }
    // if we're overtaking, move outside the rider on the side with more space
    for (j = i; j < riderCount; ++j) {
      if (i !== j) {
        if (pairwiseRightLimits[i][j] < pairwiseLeftLimits[i][j]) {
          // competitor directly in front
          let otherLeftLimit = -100;
          let otherRightLimit = 100;
          for (var k = 0; k < riderCount; ++k) {
            if (k !== i && k !== j) {
              // if it's another competitor
              if (pairwiseLeftLimits[i][k] > otherLeftLimit) {
                otherLeftLimit = pairwiseLeftLimits[i][k];
              }
              if (pairwiseRightLimits[i][k] < otherRightLimit) {
                otherRightLimit = pairwiseRightLimits[i][k];
              }
            }
          }
          let spaceToRight = otherRightLimit - riderXValues[i];
          let spaceToLeft = riderXValues[i] - otherLeftLimit;
          if (spaceToRight > spaceToLeft) {
            // move right
            target = pairwiseLeftLimits[i][j];
          } else {
            // move left
            target = pairwiseRightLimits[i][j];
          }
        }
      }
    }
    let delta = (target - riderXValues[i]) * LATERALSPEED;
    riderDeltas[i] += delta;
  }

  // rebalance all riders
  let riderXValuesWithDeltas: number[] = [];

  for (i = 0; i < riderXValues.length; ++i) {
    riderXValuesWithDeltas.push(riderXValues[i] + riderDeltas[i]);
  }

  const avgPosition =
    riderXValuesWithDeltas.reduceRight((a, b) => a + b, 0) / (1 + otherRiders.length);

  let newXPositions: number[] = [];

  for (i = 0; i < riderXValues.length; ++i) {
    newXPositions.push(riderXValuesWithDeltas[i] - avgPosition * DRIFT_TO_CENTRE);
  }

  clientRiderRef.current!.position.x = newXPositions[0];
  otherRiders.forEach((rider, index) => {
    const riderRef = otherRidersRefs.current[rider.id]?.current;
    if (riderRef) {
      riderRef.position.x = newXPositions[index + 1];
    }
  });
}

type RidersProps = {
  raceHasStarted: boolean;
  trackUnitDistance: number;
  observer: RaceRider | null;
  otherRiders: RaceRiders;
  clientPowerRef: MutableRefObject<number>;
  clientTimeOffsetRef: MutableRefObject<number>;
};

export const Riders: FC<RidersProps> = ({
  raceHasStarted,
  trackUnitDistance,
  observer,
  otherRiders,
  clientPowerRef,
}) => {
  const { camera } = useThree();

  const unitRiderSize = 1 / trackUnitDistance;
  const hasReportedError = useRef(false);

  const otherRidersArr = useMemo(() => {
    return Object.values(otherRiders);
  }, [otherRiders]);

  const clientRiderRef = useRef<THREE.Mesh>(null);
  const otherRidersRefs = useRef(
    otherRidersArr.reduce((refs, rider) => {
      return {
        ...refs,
        [rider.id]: createRef<THREE.Mesh>(),
      };
    }, {} as { [key: string]: RefObject<THREE.Mesh> }),
  );

  useEffect(() => {
    const currIds = Object.keys(otherRidersRefs.current).sort().join('-');
    const newIds = otherRidersArr
      .map((r) => r.id)
      .sort()
      .join('-');
    if (currIds !== newIds) {
      otherRidersRefs.current = otherRidersArr.reduce((refs, rider) => {
        return {
          ...refs,
          [rider.id]: createRef<THREE.Mesh>(),
        };
      }, {} as { [key: string]: RefObject<THREE.Mesh> });
    }
  }, [otherRidersArr, observer]);

  const otherRidersOrdered = useMemo(() => {
    return _sortBy(otherRidersArr, ['id']);
  }, [otherRidersArr]);

  const startPositions = getStartPositions(otherRidersArr);

  useFrame((state, delta) => {
    const clientRiderWeight = observer?.weight ?? 0;
    const clientPower = clientPowerRef.current;
    const clientLastUpdated = observer?.lastUpdated ?? Number(new Date());

    // todo
    // so here, we take the current riders position from the last update from the server
    const clientDistanceCovered = observer?.scaledDistanceCovered ?? 0;
    const clientTime = new Date().getTime();
    const clientLastUpdate = clientLastUpdated;
    const clientLastKnownSpeed = observer?.scaledVelocity ?? 0;
    const clientPredictionWindow = clientTime - clientLastUpdate;
    let clientNewSpeed = 0;
    if (raceHasStarted) {
      clientNewSpeed = getNewSpeed(
        clientLastKnownSpeed,
        clientRiderWeight,
        clientPower,
        clientPredictionWindow,
      );
    }
    const avgSpeed = (clientNewSpeed + clientLastKnownSpeed) / 2;
    const clientSideDistanceCovered =
      clientDistanceCovered + avgSpeed * (clientPredictionWindow / 1000);

    // TODO - don't update these directly, instead update in separate state somewhere

    // update the observer to record the local calculations we've made
    if (observer) {
      if (
        (isNaN(clientNewSpeed) || isNaN(clientSideDistanceCovered)) &&
        !hasReportedError.current
      ) {
        hasReportedError.current = true;
        reportError('found NaN values', {
          clientNewSpeed,
          clientDistanceCovered,
          avgSpeed,
          clientPredictionWindow,
          clientLastKnownSpeed,
          clientRiderWeight,
          clientPower,
        });
        return;
      }
      observer.lastUpdated = clientTime;
      observer.scaledDistanceCovered = clientSideDistanceCovered;
      observer.scaledVelocity = clientNewSpeed;
    }

    // what we want to do it work out everyone's "true" estimated position by looking at:
    // last update plus (speed * timedelta(now() - last update time))
    // to do that, we need to work out how our client time compares to server time
    // i.e. we need to translate the time stamps of "when" this happened, into a local frame
    // whatsmore, for our local client, we need to / want to take their speed based on local
    // wattage measurements, not the speed we get back from the server
    // we want to do this because otherwise riders don't start moving until their first message back
    // which can be 1s from the actual start.

    otherRidersOrdered.forEach((rider) => {
      const riderRef = otherRidersRefs.current[rider.id]?.current;
      if (riderRef) {
        const riderLastUpdated = rider.lastUpdated;
        const riderSpeed = rider.scaledVelocity;
        const riderDistanceCovered = rider.scaledDistanceCovered;
        const timeDelta = clientTime - riderLastUpdated;
        const riderNewDistanceCovered = riderDistanceCovered + riderSpeed * (timeDelta / 1000);
        const distanceDelta = clientSideDistanceCovered - riderNewDistanceCovered;

        // TODO - don't update these directly, instead update in separate state somewhere

        // update the rider obj
        if (isNaN(riderNewDistanceCovered)) {
          reportError('found NaN values', {
            riderSpeed,
            timeDelta,
            riderDistanceCovered,
            clientTime,
            riderLastUpdated,
            clientSideDistanceCovered,
          });
          return;
        }
        rider.lastUpdated = clientTime;
        rider.scaledDistanceCovered = riderNewDistanceCovered;

        const currentZ = riderRef.position.z;
        const targetZ = distanceDelta / trackUnitDistance;
        const target = THREE.MathUtils.lerp(currentZ, targetZ, 0.05);
        riderRef.position.z = target;
      }
    });

    if (observer) {
      moveLaterally(clientRiderRef, otherRidersRefs, otherRidersArr, observer, delta);
      glowStrength(clientRiderRef, otherRidersRefs, otherRidersArr, observer);
      setBlobSize(clientRiderRef, otherRidersRefs, otherRidersArr, observer);
    }

    if (clientRiderRef.current) {
      // look at the rider, but don't deviate along the x axis
      camera.lookAt(
        new THREE.Vector3(0, clientRiderRef.current.position.y, clientRiderRef.current.position.z),
      );
    }
  });

  return (
    <>
      {!!observer && (
        <Rider
          key={observer.id}
          ref={clientRiderRef}
          unitRiderSize={unitRiderSize}
          ballColor={new THREE.Color(0x0c0c0c)}
          emissiveColor={new THREE.Color(`#${observer.assignedColour}` || OBSEVER_DEFAULT_COLOUR)}
          startPosition={startPositions[0]}
        />
      )}
      {otherRidersOrdered.map((r, index) => {
        return (
          <Rider
            key={r.id}
            ref={otherRidersRefs.current[r.id]}
            unitRiderSize={unitRiderSize}
            ballColor={new THREE.Color(0x0c0c0c)}
            emissiveColor={new THREE.Color(`#${r.assignedColour}` || OTHER_RIDER_DEFAULT_COLOUR)}
            startPosition={startPositions[index + 1]}
          />
        );
      })}
    </>
  );
};
