import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import {
  appReducer,
  integrationReducer,
  raceHistoryReducer,
  spacesReducer,
  tournamentsReducer,
  userReducer,
} from './slices';
import { devicesReducer } from './slices/devicesSlice';

const persistReducerProps: Pick<PersistConfig<any>, 'storage' | 'writeFailHandler'> = {
  storage,
  // writeFailHandler: (err) => logger.error('redux-persist writeFail', err),
};

const rootReducer = combineReducers({
  app: appReducer,
  spaces: spacesReducer,
  tournaments: tournamentsReducer,
  user: persistReducer(
    {
      ...persistReducerProps,
      key: 'user',
      version: 1,
      blacklist: ['idToken', 'authStateKnown', 'currentActiveRace'],
    },
    userReducer,
  ),
  devices: persistReducer(
    {
      ...persistReducerProps,
      key: 'devices',
      version: 1,
    },
    devicesReducer,
  ),
  raceHistory: persistReducer(
    {
      ...persistReducerProps,
      key: 'raceHistory',
      version: 1,
    },
    raceHistoryReducer,
  ),
  integration: persistReducer(
    {
      ...persistReducerProps,
      key: 'integration',
      version: 1,
    },
    integrationReducer,
  ),
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
