import React, { FC, useMemo } from 'react';
import { CheckBox } from 'grommet';

import { Box, Button, Icon, Text } from '~/components/common';
import { UserRaceHistory, UserSummary } from '~/features/History';
import { usePowerStats, useRaceHistory, useStravaIntegration, useUser } from '~/store/hooks';
import { PowerRecords, RaceHistoryItem, Tournament } from '~/store/slices';
import { useConnectedDevices } from '~/module/connectedDevices';

import { TournamentList } from '~/features/Tournaments/components/TournamentList/TournamentList';
import { useTournamentList } from '~/features/Tournaments/components/TournamentList/useTournamentList';
import { Card } from '~/components/Card';
import { TournamentListItem } from '~/features/Tournaments/components/TournamentList/TournamentListItem';

type HomeSceneProps = {
  onNavToSpace: (spaceId: string) => void;
  onNavToProfile: () => void;
  onNavToCreateSpace: () => void;
  onNavToFindRace: () => void;
  onNavToConnectControllable: () => void;
  onNavToStravaSettings: () => void;
  onNavToUserRaceHistoryItem: (raceId: string) => void;
  onNavToTournament: (tournamentId: string) => void;
};

type HomeProps = HomeSceneProps & {
  controllableConnected: boolean;
  profileUpdated: boolean;
  powerRecords?: PowerRecords;
  userRaceHistory: RaceHistoryItem[];
  wattHours: number;
  stravaConnected: boolean;
  todaysTournaments: Tournament[];
};

export const Home: React.FC<HomeProps> = ({
  controllableConnected,
  profileUpdated,
  powerRecords,
  userRaceHistory,
  wattHours,
  stravaConnected,
  todaysTournaments,
  onNavToCreateSpace,
  onNavToFindRace,
  onNavToProfile,
  onNavToConnectControllable,
  onNavToStravaSettings,
  onNavToUserRaceHistoryItem,
  onNavToTournament,
}) => {
  const isNewUser = userRaceHistory.length === 0;

  const onboardingSteps = useMemo(() => {
    return [
      {
        id: '1',
        title: 'Complete your rider profile',
        isComplete: profileUpdated,
        action: onNavToProfile,
      },
      {
        id: '2',
        title: 'Setup your indoor trainer',
        isComplete: controllableConnected,
        action: onNavToConnectControllable,
      },
      {
        id: '3',
        title: 'Link your Strava account',
        isComplete: stravaConnected,
        action: onNavToStravaSettings,
      },
      {
        id: '4',
        title: 'Find a race, and get ready to suffer!',
        isComplete: false,
        action: onNavToFindRace,
      },
    ];
  }, [
    controllableConnected,
    profileUpdated,
    stravaConnected,
    onNavToConnectControllable,
    onNavToStravaSettings,
    onNavToFindRace,
    onNavToProfile,
  ]);

  return (
    <Box gap="medium" pad={{ vertical: 'medium', top: 'large' }}>
      <Box direction="row-responsive" align="center">
        <Box flexNum={1} pad="large">
          <Text size="4xl" weight="bolder">
            {isNewUser ? 'Welcome to Critz' : 'Welcome back!'}
          </Text>
          <Text size="xlarge" weight="bold">
            Are you ready to race?
          </Text>
          <Box direction="row" justify="start" gap="large" margin={{ vertical: 'medium' }}>
            <Button
              primary
              size="large"
              label="Find a race"
              icon={<Icon iconName="search" />}
              onClick={onNavToFindRace}
            />
            <Button
              primary
              size="large"
              color="accent-2"
              onClick={onNavToCreateSpace}
              label="Build a race"
              icon={<Icon iconName="edit" />}
            />
          </Box>
        </Box>
        <Box flexNum={1} elevation="small" margin={{ horizontal: 'large' }} pad="medium" round>
          <UserSummary
            powerRecords={powerRecords}
            numRaces={userRaceHistory.length}
            numWins={userRaceHistory.filter((r) => r.riderSummary.rank === 1).length}
            wattHours={wattHours}
          />
        </Box>
      </Box>

      {todaysTournaments.length > 0 && (
        <Box pad={{ horizontal: 'large' }}>
          <Text size="xlarge" margin={{ bottom: 'medium' }}>
            Tournaments
          </Text>
          <Box direction="row" align="center" wrap>
            {todaysTournaments.map((t) => {
              return (
                <Card
                  key={t.id}
                  margin={{ bottom: 'medium', right: 'medium' }}
                  width="medium"
                  onClick={() => onNavToTournament(t.id)}
                >
                  <TournamentListItem tournament={t} showState />
                </Card>
              );
            })}
          </Box>
        </Box>
      )}

      {userRaceHistory.length > 0 ? (
        <Box pad={{ horizontal: 'large', vertical: 'medium' }}>
          <Text size="xlarge" margin={{ bottom: 'medium' }}>
            Race History
          </Text>
          <UserRaceHistory onNavToUserRaceHistoryItem={onNavToUserRaceHistoryItem} />
          {!stravaConnected && (
            <Box
              margin={{ vertical: 'medium' }}
              align="center"
              width="medium"
              pad="small"
              background="background-contrast"
              round
            >
              <Text textAlign="center" margin={{ bottom: 'small' }}>
                Connect your Strava account to Critz and your race data will auto-upload.
              </Text>
              <Button label="Configure Strava" onClick={onNavToStravaSettings} />
            </Box>
          )}
        </Box>
      ) : (
        <Box pad={{ horizontal: 'large' }}>
          <Text size="xlarge" weight="bold" margin={{ bottom: 'medium' }}>
            How to get started
          </Text>
          <Box gap="small" align="flex-start">
            {onboardingSteps.map((step) => {
              return (
                <Box direction="row" align="center" gap="small" key={step.id} onClick={step.action}>
                  <CheckBox checked={step.isComplete} />
                  <Text style={step.isComplete ? { textDecoration: 'line-through' } : {}}>
                    {step.title}
                  </Text>
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};

function useHome(props: HomeSceneProps): HomeProps {
  const { cumulativePower, powerRecords } = usePowerStats();
  const { userRaceHistory } = useRaceHistory();
  const { controllableConnected } = useConnectedDevices();
  const { user } = useUser();
  const { isConnected: stravaConnected } = useStravaIntegration();
  const profileUpdated = user ? user.weight !== 75 : false;
  const { today: todaysTournaments } = useTournamentList();
  return {
    ...props,
    powerRecords,
    userRaceHistory,
    wattHours: cumulativePower,
    controllableConnected,
    profileUpdated,
    stravaConnected,
    todaysTournaments,
  };
}

const HomeConnected: FC<HomeSceneProps> = (sceneProps) => {
  const props = useHome(sceneProps);
  return <Home {...props} />;
};

export default HomeConnected;
