import { Image } from 'grommet';

import { Box, Text } from '~/components/common';
import { Logo } from '~/components/Logo';

export const CritzStravaConnection = () => {
  return (
    <Box direction="row" align="center">
      <Logo />
      <Box>
        <Text>&lt;&gt;</Text>
      </Box>
      <Box height="xsmall" width="small">
        <Image fit="contain" src="/img/partner/strava-logo.webp" alt="Strava" />
      </Box>
    </Box>
  );
};
