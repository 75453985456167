import { useCallback, useState } from 'react';

export const useBooleanToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);

  const onSetTrue = useCallback(() => {
    setValue(true);
  }, [setValue]);

  const onSetFalse = useCallback(() => {
    setValue(false);
  }, [setValue]);

  const onToggle = useCallback(() => {
    setValue(!value);
  }, [setValue, value]);

  return {
    value,
    onSetTrue,
    onSetFalse,
    onToggle,
  };
};
