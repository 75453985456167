import { useMemo } from 'react';

import * as THREE from 'three';

const colors = {
  spotlight1: '#ff00dd',
  spotlight2: '#00eeff',
};

const lightParams = {
  intensity: 40,
  distance: 55,
  angle: Math.PI * 0.1,
  penumbra: 0.25,
  decay: 1,
};

export const TrackLighting = () => {
  const { light1, light2 } = useMemo(() => {
    return {
      light1: new THREE.SpotLight(
        new THREE.Color(colors.spotlight1),
        lightParams.intensity,
        lightParams.distance,
        lightParams.angle,
        lightParams.penumbra,
        lightParams.decay,
      ),
      light2: new THREE.SpotLight(
        new THREE.Color(colors.spotlight2),
        lightParams.intensity,
        lightParams.distance,
        lightParams.angle,
        lightParams.penumbra,
        lightParams.decay,
      ),
    };
  }, []);

  return (
    <>
      <ambientLight color="#ffffff" intensity={1} />
      <>
        <primitive object={light1} position={[0.5, 0.75, 2.1]} />
        <primitive object={light1.target} position={[-0.25, 0.25, 0]} />
      </>
      <>
        <primitive object={light2} position={[-0.5, 0.75, 2.1]} />
        <primitive object={light2.target} position={[0.25, 0.25, 0]} />
      </>
    </>
  );
};
