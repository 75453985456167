import { StoreProvider } from './store';

import { SubscriptionManager } from './subscriptions';
import { SceneManager } from './navigation/SceneManager';
import { ThemeProvider } from './theme';
import { ConnectedDevicesProvider } from './module/connectedDevices/context';
import { AuthListener } from './features/Auth';
import {
  useFirestoreUserDataListener,
  useFirestoreUserRaceHistoryListener,
} from './module/firebase';

function GlobalListeners() {
  useFirestoreUserDataListener();
  useFirestoreUserRaceHistoryListener();
  return (
    <>
      <SubscriptionManager />
      <AuthListener />
    </>
  );
}

function App() {
  return (
    <StoreProvider>
      <ConnectedDevicesProvider>
        <ThemeProvider>
          <GlobalListeners />
          <SceneManager />
        </ThemeProvider>
      </ConnectedDevicesProvider>
    </StoreProvider>
  );
}

export default App;
