import { normalizeColor } from 'grommet/utils';
import { FC } from 'react';
import styled from 'styled-components';
import { PrimaryColor } from '~/theme/theme';

import { Text } from './Text';

const PillContainer = styled.div<{ bgColor: ColorType; borderColor: ColorType }>`
  background-color: ${(props) => normalizeColor(props.bgColor, props.theme)};
  border-radius: 16px;
  padding: 3px 10px;
  border: 1px solid ${(props) => normalizeColor(props.borderColor, props.theme)};
`;

export const Pill: FC<{ label: string; primary?: boolean; color?: string }> = ({
  label,
  primary = false,
  color,
}) => {
  return (
    <PillContainer bgColor={color || (primary ? PrimaryColor : 'light-2')} borderColor="dark-1">
      <Text color={primary ? 'light-1' : 'dark-1'} size="small" weight="bold">
        {label}
      </Text>
    </PillContainer>
  );
};
