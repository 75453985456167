import { useEffect, useMemo, useState } from 'react';

function getNow() {
  return Number(new Date());
}

const INTERVAL_MS = 300;

export const useTimeUntil = (time: number): number => {
  const [now, setNow] = useState(getNow());

  // TODO - optimise with accuracy to prevent needless frequent updates
  const timeUntil = useMemo(() => {
    return time - now;
  }, [now, time]);

  useEffect(() => {
    if (time > 0) {
      setNow(getNow());
      const intervalId = setInterval(() => {
        setNow(getNow());
      }, INTERVAL_MS);
      return () => clearInterval(intervalId);
    }
  }, [time]);

  return timeUntil;
};
