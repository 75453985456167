import { CheckBox } from 'grommet';
import { FC, useCallback, useState } from 'react';

import {
  AnchorLink,
  Box,
  Button,
  Form,
  FormField,
  Spinner,
  Text,
  TextInput,
} from '~/components/common';
import { CreateSpaceRequestData } from '~/module/api/api.types';
import { useWsSpaceActions } from '~/module/api/hooks/useWsSpaceActions';
import { absoluteUrls } from '~/navigation/Navigation.paths';

const defaultInputs: CreateSpaceRequestData = {
  duration: 5,
  fieldSize: 4,
  useDistanceScale: true,
  allowManualStart: true,
  isPrivate: false,
  countdownSeconds: 10,
};

export const CreateSpace: FC<{ onNavToSpace: (spaceId: string) => void }> = ({ onNavToSpace }) => {
  const { createSpaceError, isCreatingSpace, onCreateSpace } = useWsSpaceActions();

  const [inputs, setInputs] = useState<CreateSpaceRequestData>({
    ...defaultInputs,
  });

  const onChangeInputs = useCallback((inputs: CreateSpaceRequestData) => {
    const data: CreateSpaceRequestData = inputs;
    if (typeof inputs.duration === 'string') {
      data.duration = Number(inputs.duration);
    }
    if (typeof inputs.fieldSize === 'string') {
      data.fieldSize = Number(inputs.fieldSize);
    }
    if (typeof inputs.countdownSeconds === 'string') {
      data.countdownSeconds = Number(inputs.countdownSeconds);
    }
    setInputs(data);
  }, []);

  const createSpace = useCallback(async () => {
    const { spaceUUID } = await onCreateSpace(inputs);
    onNavToSpace(spaceUUID);
  }, [onNavToSpace, onCreateSpace, inputs]);

  return (
    <Box pad={{ vertical: 'medium' }}>
      <Box elevation="small" round="small" width="large">
        {createSpaceError && <Text color="status-error">{createSpaceError.message}</Text>}
        {isCreatingSpace && <Spinner />}

        <Form onChange={(val) => onChangeInputs(val)} onSubmit={() => createSpace()} value={inputs}>
          <Box gap="small" margin="medium">
            <FormField label="Duration (minutes)" name="duration" type="number">
              <TextInput name="duration" defaultValue={defaultInputs.duration} />
            </FormField>

            <FormField label="Field Size (between 1-4)" name="fieldSize" type="number">
              <TextInput name="fieldSize" defaultValue={defaultInputs.fieldSize} />
            </FormField>

            <FormField label="Countdown (seconds)" name="countdownSeconds" type="number">
              <TextInput name="countdownSeconds" defaultValue={defaultInputs.countdownSeconds} />
            </FormField>

            <Box>
              <CheckBox
                checked={inputs.isPrivate}
                label="Private Race?"
                onChange={(event) =>
                  setInputs((inputs) => ({ ...inputs, isPrivate: event.target.checked }))
                }
              />
            </Box>

            <Box>
              <CheckBox
                checked={inputs.useDistanceScale}
                label="Use Handicaps?"
                onChange={(event) =>
                  setInputs((inputs) => ({ ...inputs, useDistanceScale: event.target.checked }))
                }
              />
            </Box>

            <Box>
              <CheckBox
                checked={!inputs.allowManualStart}
                label="Start race automatically when full?"
                onChange={(event) =>
                  setInputs((inputs) => ({ ...inputs, allowManualStart: !event.target.checked }))
                }
              />
            </Box>
          </Box>

          <Box margin="medium" gap="medium">
            <Button label="Create Race" type="submit" primary size="large" />
            <Box align="center">
              <AnchorLink to={absoluteUrls.home} label="Back to home" size="small" />
            </Box>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};
