import { SceneComponent } from '~/navigation/Navigation.types';

import { ModalSceneContainer } from '../layout/Content';
import { ConnectDevices } from '~/features/Devices';

export const ConnectDevicesScene: SceneComponent<{}> = ({ onCloseDevicesModal }) => {
  return (
    <ModalSceneContainer title="Connect Devices" onCloseModal={onCloseDevicesModal}>
      <ConnectDevices />
    </ModalSceneContainer>
  );
};
