import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../rootReducer';
import { Tournament, TournamentDetail, TournamentEntry } from './tournamentsSlice.types';

export type TournamentsState = {
  tournamentsList: Tournament[];
  tournamentDetail: Record<string, TournamentDetail>;
  availableTournaments: Tournament[];
  tournamentEntries: TournamentEntry[];
};

const initialTournamentsState: TournamentsState = {
  tournamentsList: [],
  tournamentDetail: {},
  availableTournaments: [],
  tournamentEntries: [],
};

export const tournamentsSlice = createSlice({
  name: 'tournaments',
  initialState: initialTournamentsState,
  reducers: {
    onAvailableTournamentsUpdated(state, action: PayloadAction<{ tournaments: Tournament[] }>) {
      state.availableTournaments = action.payload.tournaments;
    },
    onTournamentListUpdated(state, action: PayloadAction<{ tournaments: Tournament[] }>) {
      state.tournamentsList = action.payload.tournaments;
    },
    onTournamentDetailUpdated(
      state,
      action: PayloadAction<{ tournamentId: string; detail: TournamentDetail }>,
    ) {
      state.tournamentDetail[action.payload.tournamentId] = action.payload.detail;
    },
    onTournamentEntriesUpdated(
      state,
      action: PayloadAction<{ tournamentEntries: TournamentEntry[] }>,
    ) {
      state.tournamentEntries = action.payload.tournamentEntries;
    },
  },
});

export const tournamentsReducer = tournamentsSlice.reducer;
export const tournamentsActions = tournamentsSlice.actions;

export const tournamentsSelectors = {
  getTournamentsList: (state: RootState) => state.tournaments.tournamentsList,
  getTournamentDetailSelector: (tournamentId: string) => (state: RootState) =>
    state.tournaments.tournamentDetail[tournamentId] as TournamentDetail | undefined,
  getAvailableTournaments: (state: RootState) => state.tournaments.availableTournaments,
  getTournamentEntries: (state: RootState) => state.tournaments.tournamentEntries,
};
