import { useMemo } from 'react';

import { Box, Text } from '~/components/common';
import { TournamentPendingUpdate } from './TournamentPendingUpdate';
import { Tournament, RaceStatus, TournamentRoundInfo } from '~/store/slices';
import { ActiveRace } from '~/features/Spaces/components/Race';
import { DateCountdown } from '~/components/DateCountdown';
import { formatDate } from '~/module/dates';
import { useTimeUntil } from '~/hooks';

import { TournamentRound, TournamentRoundProps } from './TournamentRound';

type TournamentLiveRoundProps = TournamentRoundProps & {
  tournament: Tournament;
  previousRounds: TournamentRoundInfo[];
  hasJoined: boolean;
  hasJoinedRace: boolean;
  raceState: RaceStatus | null;
  currentRaceId?: string | null;
  raceStartTime?: number;
};

const RACE_INTERFACE_SHOWN_TIME = 30000;

export const TournamentLiveRound = ({
  userId,
  tournament,
  round,
  previousRounds,
  hasJoined,
  hasJoinedRace,
  currentRaceId,
  raceState,
  raceStartTime,
  onNavToObserveRace,
}: TournamentLiveRoundProps) => {
  const isRoundPending = round.state === 'pending';
  const isRoundRacing = round.state === 'racing';
  const isRoundComplete = round.state === 'complete';
  const isRaceFinished = raceState === 'finished';
  const isWaitingToJoinRace = hasJoined && !!currentRaceId && !hasJoinedRace && !isRoundComplete;
  const isRacingOrRacingSoon = hasJoined && !!currentRaceId && !isRoundComplete && !isRaceFinished;
  const hasMoreRounds = round.roundsAfterThis > 0;

  const nextRoundEstimatedStartTime = useMemo(() => {
    let time = round.earliestRaceStart;
    const slowestFinishers = [...(round.fastestFinishersList ?? [])].reverse();
    if (slowestFinishers.length) {
      time += slowestFinishers[0].duration;
    }
    time += tournament.details.breaksDurationsBetweenRoundsSeconds * 1000;
    return time;
  }, [round, tournament]);

  const timeUntilStart = useTimeUntil(raceStartTime ?? round.earliestRaceStart ?? 0);

  const isInCountdown = raceState === 'countdown';
  const isInPlay = raceState === 'inplay';
  const isStartingSoonTime = timeUntilStart <= RACE_INTERFACE_SHOWN_TIME;

  const shouldShowThisRoundCountdown = (isRoundPending || isRoundRacing) && timeUntilStart > 0;
  const shouldShowNextRoundCountdown = isRoundComplete && hasMoreRounds;
  const shouldShowRoundCountdown = shouldShowThisRoundCountdown || shouldShowNextRoundCountdown;

  // part of tournament, racing in this round, but not yet joined the race
  if (isWaitingToJoinRace) {
    return <TournamentPendingUpdate message="Preparing race, please wait" />;
  }

  // part of tournament and racing in this round, and either in play now or less than 30s to go
  if (isRacingOrRacingSoon && ((isInCountdown && isStartingSoonTime) || isInPlay)) {
    return (
      <Box style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <ActiveRace hasAv={false} isObserving={false} />
      </Box>
    );
  }

  // not part of tournament, or
  // not racing in this round, or
  // racing in this round and race complete, or
  // racing in this round and race more than 30s from starting
  // round complete, waiting for next round to start
  return (
    <Box style={{ position: 'relative', paddingTop: shouldShowRoundCountdown ? 80 : 0 }}>
      {shouldShowRoundCountdown && (
        <Box
          background="neutral-1"
          pad="medium"
          align="center"
          style={{ position: 'fixed', top: 0, left: 0, right: 0 }}
        >
          <Text size="large" textAlign="center">
            Next round starting in{' '}
            {shouldShowNextRoundCountdown ? (
              <DateCountdown date={nextRoundEstimatedStartTime} showSeconds={false} size="xlarge" />
            ) : (
              <DateCountdown date={round.earliestRaceStart} size="large" weight="bolder" />
            )}
          </Text>
        </Box>
      )}
      <Box pad="medium" gap="medium">
        <Box direction="row" justify="between" align="start">
          <Box gap="small">
            <Text size="3xl" weight="lighter">
              {tournament.name}
            </Text>
            <Text size="xlarge" weight="lighter">
              {formatDate(tournament.details.startTime, 'HH:mm, dd MMM yyyy')}
            </Text>
          </Box>
        </Box>

        <TournamentRound userId={userId} round={round} onNavToObserveRace={onNavToObserveRace} />
      </Box>

      {previousRounds.length > 0 && (
        <Box pad="medium">
          {previousRounds.map((round) => {
            return (
              <Box key={round.roundNumber} margin={{ bottom: 'xlarge' }}>
                <TournamentRound userId={userId} round={round} />
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};
