import { SceneComponent } from '~/navigation/Navigation.types';
import { StravaSettings } from '~/features/Strava';

import { Scene } from '../layout/Scene';

export const StravaSettingsScene: SceneComponent<{}> = () => {
  return (
    <Scene width="large">
      <StravaSettings />
    </Scene>
  );
};
