import { APP_CACHE_KEY_PREFIX } from '~/module/config';

export enum CacheKey {
  UI_DISPLAY_MODE = 'UI_DISPLAY_MODE',
  AUTH_TOKEN = 'AUTH_TOKEN',
}

export function getCacheItem(key: CacheKey) {
  const storageKey = `${APP_CACHE_KEY_PREFIX}${key}`;
  return window.localStorage.getItem(storageKey);
}

export function setCacheItem(key: CacheKey, value: string) {
  const storageKey = `${APP_CACHE_KEY_PREFIX}${key}`;
  window.localStorage.setItem(storageKey, value);
}

export function removeCacheItem(key: CacheKey) {
  const storageKey = `${APP_CACHE_KEY_PREFIX}${key}`;
  window.localStorage.removeItem(storageKey);
}
