import { useEffect, useState } from 'react';
import {
  useActiveParticipant,
  useDaily,
  useLocalParticipant,
  useParticipantIds,
} from '@daily-co/daily-react-hooks';
import { DailyParticipant } from '@daily-co/daily-js';

export const useCallData = () => {
  const [callParticipants, setCallParticipants] = useState<DailyParticipant[]>([]);

  const callObject = useDaily();
  const localParticipant = useLocalParticipant();
  const participantIds = useParticipantIds({ filter: 'remote' });
  const activeSpeaker = useActiveParticipant({ ignoreLocal: true });

  useEffect(() => {
    if (callObject && participantIds.length) {
      setCallParticipants(Object.values(callObject.participants()));
    }
  }, [callObject, participantIds]);

  return {
    callObject,
    localParticipant,
    callParticipants,
    activeSpeaker,
  };
};
