import { useEffect, useState } from 'react';
import Daily, {
  DailyCall,
  DailyEvent,
  DailyEventObjectCameraError,
  DailyEventObjectFatalError,
} from '@daily-co/daily-js';
import { reportError } from '~/module/logging';

/* We decide what UI to show to users based on the state of the app, which is dependent on the state of the call object. */
const STATE_IDLE = 0;
const STATE_JOINED = 1;
const STATE_ERROR = -1;

export const useSimpleAvCall = (roomId: string, userId: string, enabled: boolean) => {
  const [appState, setAppState] = useState(STATE_IDLE);
  const [callObject, setCallObject] = useState<DailyCall | null>(null);

  useEffect(() => {
    if (!callObject) return;

    const events: DailyEvent[] = ['joined-meeting', 'left-meeting', 'error', 'camera-error'];

    function handleNewMeetingState() {
      if (!callObject) return;
      switch (callObject.meetingState()) {
        case 'joined-meeting':
          setAppState(STATE_JOINED);
          break;
        case 'error':
          setAppState(STATE_ERROR);
          break;
        default:
          break;
      }
    }

    function handleError(e?: DailyEventObjectFatalError | DailyEventObjectCameraError) {
      reportError(`[daily:${e?.error?.type || 'unknown'}] ${e?.errorMsg}`);
    }

    handleNewMeetingState();

    events.forEach((event) => callObject.on(event, handleNewMeetingState));
    callObject.on('error', handleError);
    callObject.on('camera-error', handleError);
    return () => {
      events.forEach((event) => callObject.off(event, handleNewMeetingState));
      callObject.off('error', handleError);
      callObject.off('camera-error', handleError);
    };
  }, [callObject]);

  useEffect(() => {
    if (enabled) {
      setCallObject(
        Daily.createCallObject({
          url: `https://critz.daily.co/${roomId}`,
          userName: userId,
          subscribeToTracksAutomatically: true,
          dailyConfig: {
            // TODO - work out if we need to change this
            userMediaVideoConstraints: {
              width: 320,
              height: 240,
            },
          },
          // token:
          //  'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyIjoib3VyLWNvdW50IiwiZCI6IjMwY2M0NTlmLTQ4ZDgtNDhlNC05ZmIwLTBlMzYwN2Q0ODcxMCIsImlhdCI6MTY2ODMzNDY5MH0.zk-2V04R8ZHJZI2CUfcI7BVF06b9iU8Vy7nYcfcy3SA',
        }),
      );
    }
  }, [enabled, userId, roomId]);

  return {
    appState,
    callObject,
  };
};
