const RHO = 1.225; //density of air at sea level
const ROLLINGCOEFF = 5.0e-3; //ashphalt / slick tyres (https://www.omnicalculator.com/sports/cycling-wattage)
const G = 9.80665; // gravity
const ARBITRARY_EXTRA_RESISTANCE = 1.4;

export {};

function getFrontalArea(mass: number): number {
  const height = 1.75; // TODO make this a user property
  return 0.18964 * height + 0.00215 * mass - 0.07861;
}

function fDrag(v: number, frontalArea: number, dragCoeff: number): number {
  return 0.5 * dragCoeff * frontalArea * RHO * v * v * ARBITRARY_EXTRA_RESISTANCE;
}

function fRolling(mass: number, v: number): number {
  mass += 10; // add some fixed weight for the bike itself
  if (v > 0.01) {
    return G * mass * ROLLINGCOEFF;
  } else {
    return 0.0;
  }
}

function getDragCoeff(mass: number): number {
  return 4.45 * Math.pow(mass, -0.45);
}

export function getNewSpeed(
  initialVelocity: number,
  mass: number,
  wattage: number,
  timeStep_ms: number,
): number {
  const dragCoeff = getDragCoeff(mass);
  const frontalArea = getFrontalArea(mass);
  const totalForce =
    fDrag(initialVelocity, frontalArea, dragCoeff) + fRolling(mass, initialVelocity);
  const powerNeeded = totalForce * initialVelocity;
  const netPower = wattage - powerNeeded;
  const v = Math.sqrt(
    initialVelocity * initialVelocity + (2 * netPower * (timeStep_ms / 1000)) / mass,
  );
  return v;
}
