import { forwardRef, useRef, useMemo, useState, useEffect } from 'react';
import { useLoader, useFrame } from '@react-three/fiber';
import * as THREE from 'three';

import { DistancePortal, FinishPortal } from './ThreeDistanceMarker';
import { getCountdownIntervals } from '../Race/race.utils';

type GridPlaneProps = {
  position: [number, number, number];
};

const GridPlane = forwardRef<THREE.Mesh, GridPlaneProps>((props, ref) => {
  const gridTexture = useLoader(THREE.TextureLoader, '/viz/grid-6.png');
  const heightTexture = useLoader(THREE.TextureLoader, '/viz/displacement-7.png');
  const metalnessTexture = useLoader(THREE.TextureLoader, '/viz/metalness-2.png');

  return (
    <mesh ref={ref} visible position={props.position} rotation={[-Math.PI / 2, 0, 0]}>
      <planeGeometry args={[1, 2, 24, 24]} />
      <meshStandardMaterial
        map={gridTexture}
        displacementMap={heightTexture}
        displacementScale={0.5}
        metalnessMap={metalnessTexture}
        metalness={1}
        roughness={0.5}
      />
    </mesh>
  );
});

type RaceTrackProps = {
  trackUnitDistance: number;
  speed: number;
  distanceCovered: number;
  distanceMarkerIntervals: number;
  totalDistance: number;
};

type DistanceMarker = {
  type: 'interval' | 'countdown' | 'finish';
  distance: number;
  label: string;
};

export function RaceTrack({
  trackUnitDistance,
  speed,
  distanceCovered,
  distanceMarkerIntervals,
  totalDistance,
}: RaceTrackProps) {
  const trackGroupRef = useRef<THREE.Group>(null);
  const planeGroupRef = useRef<THREE.Group>(null);
  const distanceMarkersGroupRef = useRef<THREE.Group>(null);

  const [initialGroupPosition, setInitialGroupPosition] = useState<THREE.Vector3>();

  const distanceMarkers = useMemo(() => {
    const markers = getCountdownIntervals({
      totalDistance,
      markerInterval: distanceMarkerIntervals,
    });
    const markerPoints: DistanceMarker[] = markers.map(({ distance, fromFinish }) => ({
      distance,
      type: 'countdown',
      label: `${fromFinish / 1000}km to go`,
    }));
    markerPoints.push({
      type: 'finish',
      distance: totalDistance,
      label: 'FINISH',
    });
    return markerPoints;
  }, [totalDistance, distanceMarkerIntervals]);

  useEffect(() => {
    if (!initialGroupPosition && distanceCovered > 0) {
      setInitialGroupPosition(new THREE.Vector3(0, 0, distanceCovered / trackUnitDistance));
    }
  }, [initialGroupPosition, distanceCovered, trackUnitDistance]);

  useFrame((state, delta) => {
    const unitTrackSpeed = speed / trackUnitDistance;
    const distanceAdvancement = delta * unitTrackSpeed;

    if (trackGroupRef.current) {
      trackGroupRef.current.position.z += distanceAdvancement;
      const groupPosition = trackGroupRef.current.position.z;

      // when planes move out of camera, shift them to the back
      if (planeGroupRef.current) {
        planeGroupRef.current.children.forEach((plane) => {
          const planePosition = plane.position.z;
          if (0 - groupPosition <= planePosition - 2) {
            plane.position.z -= 8;
          }
        });
      }

      // hide the portals of the markers the rider has passed through
      if (distanceMarkersGroupRef.current) {
        distanceMarkersGroupRef.current.children.forEach((distanceMarker, index) => {
          const { distance } = distanceMarkers[index];
          if (groupPosition * trackUnitDistance >= distance) {
            if (distanceMarker.visible) {
              // close the gap between this and the next distance marker
              const currentPosition = distanceMarker.children[0].position.z;
              const nextMarker = distanceMarkersGroupRef.current?.children[index + 1] ?? null;
              const targetPosition = nextMarker
                ? nextMarker.position.z - distanceMarker.position.z
                : -2;
              // @ts-ignore
              const currentOpacity = distanceMarker.children[0].material.opacity;

              // move whilst still visible
              if (currentOpacity > 0) {
                distanceMarker.children[0].position.z = THREE.MathUtils.lerp(
                  targetPosition,
                  currentPosition,
                  0.99,
                );
                // @ts-ignore
                distanceMarker.children[0].material.opacity -= 0.01;
              } else {
                distanceMarker.visible = false;
              }

              distanceMarker.children[1].visible = false;
            }
          }
        });
      }
    }
  });

  return (
    <group position={initialGroupPosition} ref={trackGroupRef}>
      <group ref={planeGroupRef}>
        <GridPlane position={[0, 0, 0.15]} />
        <GridPlane position={[0, 0, -1.85]} />
        <GridPlane position={[0, 0, -3.85]} />
        <GridPlane position={[0, 0, -5.85]} />
      </group>
      <group ref={distanceMarkersGroupRef}>
        {distanceMarkers.map(({ type, distance, label }, i) => {
          const zPosition = -distance / trackUnitDistance;
          const position: THREE.Vector3Tuple = [0, 0, zPosition];
          const PortalComponent = type === 'finish' ? FinishPortal : DistancePortal;
          return <PortalComponent key={`${type}-${distance}`} position={position} text={label} />;
        })}
      </group>
    </group>
  );
}
