import { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Button, Form, FormField, Text, TextInput } from '~/components/common';
import { useWsUser } from '~/module/api/hooks/useWsUser';
import { useUser } from '~/store/hooks';
import { User } from '~/store/slices';

const defaultUserState: Partial<User> = {
  displayName: '',
  userName: '',
  weight: 0,
};

export const UserProfile: FC<{ onComplete: () => void }> = ({ onComplete }) => {
  const { updateUserError, onUpdateUser, onValidateUserName } = useWsUser();
  const { user } = useUser();
  const [userEdit, setUserEdit] = useState<Partial<User>>(user || { ...defaultUserState });
  const [userNameInvalid, setUserNameInvalid] = useState(false);

  const currentUserName = user?.userName;

  const hasChanges = useMemo(() => {
    if (user && userEdit) {
      return JSON.stringify(user) !== JSON.stringify(userEdit);
    }
    return false;
  }, [user, userEdit]);

  const onSaveData = useCallback(async () => {
    setUserNameInvalid(false);
    let isUserNameValid = true;
    if (currentUserName && userEdit.userName && userEdit.userName !== currentUserName) {
      isUserNameValid = await onValidateUserName(userEdit.userName || '');
    }
    if (isUserNameValid) {
      await onUpdateUser(userEdit);
      if (onComplete) {
        onComplete();
      }
    } else {
      setUserNameInvalid(true);
    }
  }, [userEdit, currentUserName, onUpdateUser, onValidateUserName, onComplete]);

  const onChangeUser = useCallback((user: Partial<User>) => {
    setUserEdit(user);
  }, []);

  useEffect(() => {
    setUserEdit(user || { ...defaultUserState });
  }, [user]);

  return (
    <Box>
      <Form value={userEdit} onChange={(val) => onChangeUser(val)} onSubmit={() => onSaveData()}>
        <FormField
          name="userName"
          label="Username"
          error={userNameInvalid ? 'Username already taken' : ''}
        >
          <TextInput name="userName" />
        </FormField>
        <FormField name="displayName" label="Display Name">
          <TextInput name="displayName" />
        </FormField>
        <FormField name="weight" label="Weight">
          <TextInput name="weight" type="number" />
        </FormField>
        <Box margin={{ top: 'large', bottom: 'small' }} gap="medium">
          <Button label="Save" type="submit" primary disabled={!hasChanges} />
        </Box>
      </Form>
      {!!updateUserError && (
        <Box>
          <Text color="status-error">{updateUserError.message}</Text>
        </Box>
      )}
    </Box>
  );
};
