import { FC, Fragment, useMemo } from 'react';
import _find from 'lodash/find';

import { Box, Dot, Text } from '~/components/common';
// import { msToTime, timeToClockString } from '~/module/utils';

import { RaceRider } from '../../../Race.types';

function getDistanceBetween(distance1: number | null, distance2: number | null) {
  if (distance1 && distance2) {
    const delta = Math.abs(distance1 - distance2);
    return Math.round(delta * 100) / 100;
  }
}

type RiderSummary = Pick<
  RaceRider,
  | 'id'
  | 'displayName'
  | 'scaledDistanceCovered'
  | 'scaledDistance'
  | 'hasFinished'
  | 'finishTimeMs'
  | 'riderColour'
>;

export const HudRiderSummary: FC<{
  observerId?: string;
  ridersSummary: RiderSummary[];
  onObserveRider: (riderId: string) => void;
}> = ({ observerId, ridersSummary, onObserveRider }) => {
  const observedRider = useMemo(() => {
    return _find(ridersSummary, ['id', observerId]) || null;
  }, [ridersSummary, observerId]);

  return (
    <Box pad={{ horizontal: 'small' }} align="start">
      <Box>
        {ridersSummary.map((r, index) => {
          const isObserver = observerId ? r.id === observerId : false;
          const hasObserverFinished = observedRider?.hasFinished ?? false;
          const distanceFromObserver = observedRider
            ? getDistanceBetween(observedRider.scaledDistanceCovered, r.scaledDistanceCovered)
            : 0;
          const distanceFromFinish =
            getDistanceBetween(r.scaledDistance, r.scaledDistanceCovered) || 0;

          const isAheadOfObserver = observedRider
            ? observedRider.scaledDistanceCovered > r.scaledDistanceCovered
            : true;

          // const finishTime = timeToClockString(msToTime(r.finishTimeMs), {
          //   minutes: true,
          //   seconds: true,
          //   ms: true,
          // });
          // const leader = riderSummaryInOrder[0];

          return (
            <Fragment key={r.id}>
              <Box
                elevation={isObserver ? 'small' : undefined}
                round="small"
                pad={{ horizontal: 'small' }}
                margin={{ bottom: 'small' }}
                onClick={() => onObserveRider(r.id)}
              >
                <Box direction="row" align="center">
                  <Dot color={`#${r.riderColour ?? 'FFF'}`} size={10} margin={{ right: 'small' }} />
                  <Text style={{ width: 120 }}>
                    {index + 1}. {r.displayName}
                  </Text>

                  {r.hasFinished ? (
                    <>
                      {/* <Text>{finishTime}</Text>
                      {leader.hasFinished && leader.id !== r.id && (
                        <Text>
                          &nbsp; (+
                          {timeToClockString(msToTime(r.finishTimeMs - leader.finishTimeMs), {
                            ms: true,
                            seconds: true,
                            minutes: true,
                          })}
                          )
                        </Text>
                      )} */}
                      <Text>Finished</Text>
                    </>
                  ) : (
                    <>
                      {!!distanceFromObserver && (
                        <Text>
                          {hasObserverFinished ? (
                            `${distanceFromFinish}m to go`
                          ) : (
                            <>
                              {isAheadOfObserver
                                ? `${distanceFromObserver}m behind`
                                : `${distanceFromObserver}m ahead`}
                            </>
                          )}
                        </Text>
                      )}
                    </>
                  )}
                </Box>
              </Box>
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};
