import React, { createContext, useContext } from 'react';

import {
  useConnectedDevicesContext,
  defaultState,
  ConnectedDevicesContext as ConnectedDevicesContextType,
} from './useConnectedDevicesContext';

const ConnectedDevicesContext = createContext<ConnectedDevicesContextType>(defaultState);

const ConnectedDevicesProvider = (props: any) => {
  return <ConnectedDevicesContext.Provider {...props} value={useConnectedDevicesContext()} />;
};

const useConnectedDevices = () => useContext(ConnectedDevicesContext);
export { ConnectedDevicesProvider, ConnectedDevicesContext, useConnectedDevices };
