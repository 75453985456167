import { useMemo } from 'react';
import _sortBy from 'lodash/sortBy';
import _findIndex from 'lodash/findIndex';

import {
  getDistanceCovered,
  getScaledDistance,
  getScaledVelocity,
  getPowerMeasure,
  getScaledAcceleration,
} from '~/module/physics';
import { useRaceState } from '~/store/hooks';

import { RaceRider, RaceRiders } from '../Race.types';
import { useRaceRefs } from '../components/ThreeRace/useRaceRefs';

export const useRaceRiders = () => {
  const {
    observingUserId,
    participants,
    participantsState: state,
    participantsMeasures: measures,
    details,
    ridersFinishedSummary,
    raceStartTime,
    onObserveRider,
  } = useRaceState();
  const { clientTimeOffsetRef } = useRaceRefs();

  const useDistanceScale = details?.useDistanceScale ?? true;
  const distance = details?.distance || 0;

  const observerDistanceScale = useMemo(() => {
    if (observingUserId && useDistanceScale) {
      return participants[observingUserId]?.distanceScale ?? 1;
    }
    return 1;
  }, [participants, useDistanceScale, observingUserId]);

  const { riders } = useMemo(() => {
    const raceRiders: { [key: string]: RaceRider } = {};
    const participantsArr = Object.values(participants || {});

    const ranks = _sortBy(
      Object.entries(state || {}).map(([id, { percentageComplete }]) => {
        return {
          id,
          percentageComplete,
        };
      }),
      ['percentageComplete'],
    ).reverse();

    participantsArr.forEach((p) => {
      const participantState = state ? state[p.id] : undefined;
      const participantMeasures = measures ? measures[p.id] : undefined;

      const percentageComplete = participantState?.percentageComplete || 0;
      const velocity = participantState ? participantState.velocity : 0;
      const acceleration = participantState ? participantState.acceleration : 0;
      const scaledAcceleration = useDistanceScale
        ? getScaledAcceleration({
            acceleration,
            theirDistanceScale: p.distanceScale,
            myDistanceScale: observerDistanceScale,
          })
        : acceleration;
      const scaledVelocity = useDistanceScale
        ? getScaledVelocity({
            velocity,
            theirDistanceScale: p.distanceScale,
            myDistanceScale: observerDistanceScale,
          })
        : velocity;
      const distanceCovered = getDistanceCovered(percentageComplete, distance);
      const scaledDistance = getScaledDistance(distance, observerDistanceScale);
      const scaledDistanceCovered = getScaledDistance(distanceCovered, observerDistanceScale);
      const powerErf = getPowerMeasure(scaledVelocity, scaledAcceleration);
      const positionIndex = _findIndex(ranks, ['id', p.id]);
      const draftEfficiency = participantState?.draftInfo.efficiency ?? 0;

      const rider: RaceRider = {
        ...p,
        drafting: draftEfficiency > 0,
        draftEfficiency,
        percentageComplete,
        positionIndex,
        positionNumber: positionIndex >= 0 ? positionIndex + 1 : participantsArr.length,
        wattage: participantMeasures?.wattage || 0,
        heartRate: participantMeasures?.heartRate || 0,
        scaledVelocity,
        scaledDistance,
        scaledDistanceCovered,
        scaledAcceleration,
        powerErf,
        lastUpdated: participantState?.lastUpdated || Number(new Date()),
        hasFinished: ridersFinishedSummary && ridersFinishedSummary[p.id] ? true : false,
        finishTimeMs:
          ridersFinishedSummary && ridersFinishedSummary[p.id]
            ? ridersFinishedSummary[p.id].finishTime - clientTimeOffsetRef.current - raceStartTime
            : 0,
        assignedColour: p.riderColour,
      };

      raceRiders[p.id] = rider;
    });

    return { riders: raceRiders };
  }, [
    participants,
    state,
    measures,
    observerDistanceScale,
    distance,
    ridersFinishedSummary,
    raceStartTime,
    clientTimeOffsetRef,
    useDistanceScale,
  ]);

  const observer = useMemo(() => {
    if (observingUserId && riders[observingUserId]) {
      return riders[observingUserId];
    }
    return null;
  }, [riders, observingUserId]);

  const otherRiders = useMemo(() => {
    if (observingUserId && riders[observingUserId]) {
      const others: RaceRiders = { ...riders };
      delete others[observingUserId];
      return others;
    }
    return riders;
  }, [riders, observingUserId]);

  const ridersInOrder = useMemo(() => {
    return _sortBy(Object.values(riders), ['positionNumber']);
  }, [riders]);

  const observerIndex = useMemo(() => {
    const index = _findIndex(ridersInOrder, ['id', observingUserId]);
    return index;
  }, [ridersInOrder, observingUserId]);

  const { aheadOfRider, behindRider } = useMemo(() => {
    return {
      aheadOfRider: ridersInOrder[observerIndex + 1] || null,
      behindRider: ridersInOrder[observerIndex - 1] || null,
    };
  }, [ridersInOrder, observerIndex]);

  return {
    numRiders: Object.keys(riders).length,
    observer,
    otherRiders,
    ridersInOrder,
    aheadOfRider,
    behindRider,
    onObserveRider,
  };
};
