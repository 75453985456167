import { useCallback } from 'react';
import { doc, updateDoc } from 'firebase/firestore';

import { firestore } from '../InitFirebase';
import { useUser } from '~/store/hooks';

type UserDocStravaProps = {
  ['strava.authCode']?: string;
  ['strava.uploadRace']?: boolean;
};

export const useFirestoreStravaIntegration = () => {
  const { userId } = useUser();

  const onCreateIntegration = useCallback(
    async (authCode: string) => {
      if (userId) {
        const userDoc = doc(firestore, `/users/${userId}`);
        const update: Partial<UserDocStravaProps> = {
          'strava.authCode': authCode,
        };
        await updateDoc(userDoc, update);
      } else {
        throw new Error('User not found');
      }
    },
    [userId],
  );

  const onChangeSettings = useCallback(
    async ({ uploadRace }: Partial<{ uploadRace: boolean }>) => {
      if (userId) {
        const userDoc = doc(firestore, `/users/${userId}`);
        const update: Partial<UserDocStravaProps> = {};
        if (typeof uploadRace === 'boolean') {
          update['strava.uploadRace'] = uploadRace;
        }
        await updateDoc(userDoc, update);
      } else {
        throw new Error('User not found');
      }
    },
    [userId],
  );

  return {
    onCreateIntegration,
    onChangeSettings,
  };
};
