import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FeatureSwitches } from '~/module/config';

import { UIDisplayMode } from '~/theme';
import { RootState } from '../rootReducer';

export type AppState = {
  uiDisplayModeAutomatic: boolean;
  uiDisplayMode: UIDisplayMode;
  enableBob: boolean;
  enableThreePostProcessing: boolean;
  enableThreePerf: boolean;
  enableAv: boolean;
};

const initialAppState: AppState = {
  uiDisplayModeAutomatic: true,
  uiDisplayMode: 'dark',
  enableBob: false,
  enableThreePostProcessing: true,
  enableThreePerf: false,
  enableAv: FeatureSwitches.enableAv,
};

export const appSlice = createSlice({
  name: 'app',
  initialState: initialAppState,
  reducers: {
    onSetUiDisplayModeAutomatic(state, action: PayloadAction<boolean>) {
      state.uiDisplayModeAutomatic = action.payload;
    },
    onSetUiDisplayMode(state, action: PayloadAction<UIDisplayMode>) {
      state.uiDisplayMode = action.payload;
    },
    onEnableBob(state, action: PayloadAction<boolean>) {
      state.enableBob = action.payload;
    },
    onEnableThreePostProcessing(state, action: PayloadAction<boolean>) {
      state.enableThreePostProcessing = action.payload;
    },
    onEnableThreePerf(state, action: PayloadAction<boolean>) {
      state.enableThreePerf = action.payload;
    },
    onEnableAv(state, action: PayloadAction<boolean>) {
      state.enableAv = action.payload;
    },
  },
});

export const appReducer = appSlice.reducer;
export const appActions = appSlice.actions;
export const appSelectors = {
  getDisplayMode: (state: RootState) => state.app.uiDisplayMode,
  getIsBobEnabled: (state: RootState) => state.app.enableBob,
  getIsThreePostProcessingEnabled: (state: RootState) => state.app.enableThreePostProcessing,
  getIsThreePerfEnabled: (state: RootState) => state.app.enableThreePerf,
  getIsAvEnabled: (state: RootState) => state.app.enableAv,
};
