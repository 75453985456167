import { CheckBox } from 'grommet';
import { Box, Button, Pill, Text } from '~/components/common';

import { usePromptStravaAuth } from '~/features/Strava';
import { useFirestoreStravaIntegration } from '~/module/firebase';
import { useStravaIntegration } from '~/store/hooks';
import { CritzStravaConnection } from './CritzStravaConnection';

export const StravaSettings = () => {
  const { isConnected, hasAuthToken, shouldAutoUploadRaces } = useStravaIntegration();
  const { onPromptAuth } = usePromptStravaAuth();
  const { onChangeSettings } = useFirestoreStravaIntegration();
  return (
    <Box align="center">
      <Box align="center" margin={{ bottom: 'large' }}>
        <CritzStravaConnection />
      </Box>
      <Box margin={{ bottom: 'medium' }}>
        <Text textAlign="center">Connect your Strava account and auto-upload your race data.</Text>
      </Box>
      {hasAuthToken && (
        <Pill
          label={isConnected ? 'Connected' : hasAuthToken ? 'Pending' : 'Not Connected'}
          color={
            isConnected ? 'status-ok' : hasAuthToken ? 'status-warning' : 'background-contrast'
          }
        />
      )}
      <Box width="medium" align="center" margin={{ top: 'medium' }}>
        <Box>
          {!isConnected && hasAuthToken && (
            <>
              <Text textAlign="center">
                The connection may take a minute to verify, we are just checking a few things on our
                side.
              </Text>
              <Text textAlign="center" margin={{ top: 'small' }}>
                If this doesn't update within a few minutes, try reconnecting.
              </Text>
            </>
          )}
        </Box>
        <Box align="center" elevation="small" round="small" pad="medium" margin="medium">
          {/* <Text size="large" margin={{ bottom: 'medium' }}>
            Configuration
          </Text> */}
          <Box>
            <CheckBox
              checked={shouldAutoUploadRaces}
              label="Auto upload races to Strava?"
              onChange={(event) => {
                onChangeSettings({ uploadRace: event.target.checked });
              }}
            />
          </Box>
        </Box>
        {!isConnected && (
          <Box margin={{ top: 'medium' }}>
            <Button label={hasAuthToken ? 'Reconnect' : 'Connect Strava'} onClick={onPromptAuth} />
          </Box>
        )}
      </Box>
    </Box>
  );
};
