import { FC } from 'react';

import { Box, Button, Icon, IconName, Text } from '~/components/common';

type NotConnectedWarningProps = {
  deviceIcon: IconName;
  deviceLabel: string;
  onConnect: () => void;
};

export const NotConnectedWarning: FC<NotConnectedWarningProps> = ({
  deviceIcon,
  deviceLabel,
  onConnect,
}) => {
  return (
    <Box background="status-warning" pad="medium" direction="row" justify="between" align="center">
      <Box direction="row" gap="small" align="center">
        <Icon iconName={deviceIcon} />
        <Text size="large" weight="bold">
          {deviceLabel} not detected
        </Text>
      </Box>
      <Box width="small">
        <Button type="button" primary fill onClick={onConnect} label="Connect Trainer" />
      </Box>
    </Box>
  );
};
