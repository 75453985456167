import { UserHandle } from '~/components/UserHandle';
import { Box, Text } from '~/components/common';
import { TournamentRaceRiderRank } from '~/store/slices';
import { getOrdinal } from '~/module/utils';

type TournamentRiderRanksProps = { riderRanks: TournamentRaceRiderRank[]; userId?: string };

export const TournamentRiderRanks = ({ riderRanks, userId }: TournamentRiderRanksProps) => {
  return (
    <Box>
      {riderRanks.map((riderRank) => {
        const isMe = riderRank.id === userId;
        return (
          <Box
            key={riderRank.rank}
            elevation={riderRank.rank === 1 ? 'small' : ''}
            margin={{ vertical: 'xsmall' }}
            pad="xsmall"
            justify="between"
            direction="row"
            align="center"
            border="bottom"
          >
            <Box direction="row" align="center" gap="xsmall">
              <Box margin={{ right: 'small' }} width="xxsmall">
                <Text size="large" weight="lighter">
                  {riderRank.rank}
                  {getOrdinal(riderRank.rank)}
                </Text>
              </Box>
              <UserHandle {...riderRank} accent={isMe} />
            </Box>
            <Text size="small">{riderRank.notes}</Text>
          </Box>
        );
      })}
    </Box>
  );
};
